import * as React from "react";
import CollapsibleSection from "../../components/stations/section/CollapsibleSection";
import { Container } from "reactstrap";

export interface Properties { children?: React.ReactNode, expandInactive?: boolean, onExpanded?: (which: number, expanded: boolean)=>void }
export default abstract class HighlightsSectionGeneric<HighlightModel, Props extends Properties> extends React.Component<Props,{}> {

    abstract getActiveHighlights(): HighlightModel[];
    abstract getInactiveHighlights(): HighlightModel[];
    abstract renderHighlightsItem(highlight: HighlightModel, key: string, active: boolean): JSX.Element;
    abstract getCollapsiblesTitle(): string | undefined;

    render() {
        return (
            <Container fluid>
                {
                    this.props.children
                }
                {
                    this.getActiveHighlights() && this.getActiveHighlights().length>0 &&
                    <CollapsibleSection title={this.getCollapsiblesTitle() || "Highlights"} startExpanded onExpanded={(expanded: boolean)=>this.props.onExpanded && this.props.onExpanded(0, expanded)}>
                        {this.getActiveHighlights().map(highlight => {
                            return (
                                this.renderHighlightsItem(highlight, (highlight as any).id, true)
                            )
                        })}
                    </CollapsibleSection>
                }
                {
                    this.getInactiveHighlights() && this.getInactiveHighlights().length>0 &&
                    <CollapsibleSection title={this.getCollapsiblesTitle()+" (inactive)" || "Highlights (inactive)"} startExpanded={this.props.expandInactive} onExpanded={(expanded: boolean)=>this.props.onExpanded && this.props.onExpanded(1, expanded)}>
                        {this.getInactiveHighlights().map(highlight => {
                            return (
                                this.renderHighlightsItem(highlight, (highlight as any).id, false)
                            )
                        })}
                    </CollapsibleSection>
                }
            </Container>
        )
    }

}