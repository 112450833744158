const queryString = require('query-string');

export function getParamsFromUrl(props): Object | undefined {
    if(props && props.location && props.location.search) {
        return queryString.parse(props.location.search);
    }
    return undefined;
}

export function addUrlParamBase(base: string | undefined): string {
    if(base && base.length>0) {
        return "&";
    }
    return "?";
}
export function addUrlParam(base: string | undefined, key, value): string {
    return addUrlParamBase(base) + key+"="+value;;
}

export function getUrlParamsFromSearchOptions(searchOptions) {
    //get search options
    var urlParams = "";
    if(searchOptions) {
        Object.keys(searchOptions).filter(key=>key!=="ref").forEach(key => {
            urlParams += addUrlParam(urlParams, key, searchOptions![key])
        });
    }
    return urlParams;
}

export function getRefFromUrl(props): string | undefined {
    if(props && props.location && props.location.search) {
        let queryParams = queryString.parse(props.location.search);
        if(queryParams && queryParams.ref) {
            return queryParams.ref;
        }
    }
    return undefined;
}
export function parseStationRef(ref: string | undefined): string | undefined {
    if(ref && ref.startsWith('station')) {
        return ref.substr('station'.length);
    }
    return undefined;
}

export function getScrollHintFromUrl(props): string | undefined {
    let params = getParamsFromUrl(props);
    if(params && params["scroll"]) {
        return params["scroll"];
    }
    return undefined;
}