import * as React from "react";
import { Container, Row, Col } from "reactstrap";
import notify from "../../ui/notify";
import Loader from "../../components/loader/Loader";
import './AdminSection.css';
import Button from "reactstrap/lib/Button";
import UsersGrid from "./UsersGrid";
import { API as UserApi } from "../../api/endpoints/user";
import { API as LoginApi } from "../../api/endpoints/login";
import { User } from "../../api/models";
import DevExpress from "devextreme/bundles/dx.all";
import { isAdmin } from "../../api/models/user";
import { API } from "../../api/endpoints/stations";
import {RefObject} from "react";
import {TextBox} from "devextreme-react";

const topSpacing: React.CSSProperties = {
    paddingTop: '50px'
}
const padding: React.CSSProperties = {
    paddingLeft: '65px',
    paddingRight: '65px',
    paddingBottom: '65px'
}

export default class AdminSection extends React.Component<
    {
        //properties
    },
    {
        //state vars
        currentUser?: User,
        loading: boolean
    }> {

    private readonly usersGrid: RefObject<UsersGrid> = React.createRef();
    private readonly inputNewPassword: RefObject<TextBox>;
    private readonly inputOldPassword: RefObject<TextBox>;

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    public componentDidMount() {
        UserApi.getMe().then(user => {
            this.setState({
                currentUser: user
            })
        })
            .catch(err => {
                //
            })
    }

    public render() {
        if (!this.state.currentUser) {
            return <Loader />
        }
        return (
            <Container fluid style={{ ...topSpacing, ...padding }} className="dash-row">
                {
                    this.state.currentUser && isAdmin(this.state.currentUser) &&

                    <Row className="translucent">
                        <Col><h1>User</h1></Col>
                        <Col xs="auto" className="title-container">
                            <Button className="buttoncreate" onClick={() => {
                                this.usersGrid.current?.addUser();
                            }}>Create Admin</Button>
                        </Col>
                    </Row>
                }
                {
                    this.state.currentUser && isAdmin(this.state.currentUser) &&

                    <Row>
                        <UsersGrid
                            ref={this.usersGrid}
                            currentUser={this.state.currentUser} />
                    </Row>
                }
                {
                    this.state.currentUser && isAdmin(this.state.currentUser) &&
                    <div style={{ height: 50 }} />
                }

                <h1>Edit Password</h1>
                <Row style={{ padding: 5 }}>
                    <Container fluid>
                        <Row className="translucent" style={{ padding: 5 }}>
                            <TextBox
                                ref={this.inputOldPassword}
                                mode={'password'}
                                placeholder="Old password" />
                        </Row>
                        <Row className="translucent" style={{ padding: 5 }}>
                            <TextBox
                                ref={this.inputNewPassword}
                                mode={'password'}
                                placeholder="New password" />
                        </Row>
                    </Container>
                </Row>
                <Row className="translucent">
                    <Col className="title-container" style={{ paddingLeft: 0 }}>
                        <Button className="buttoncreate" onClick={() => {
                            try {
                                this.inputNewPassword.current?.instance.option().value
                                let inputOld = this.inputOldPassword.current?.instance;
                                let inputNew = this.inputNewPassword.current?.instance;

                                let oldPassword = inputOld?.option("value");
                                let newPassword = inputNew?.option("value");

                                if (oldPassword && newPassword) {
                                    if (oldPassword === newPassword) {
                                        notify("The old and the new password are the same", 'error');
                                    } else if (newPassword.length < 6 || newPassword.length > 100) {
                                        notify("Please choose a password that is at least 6 characters long", 'error');
                                    } else {
                                        //make api call
                                        this.setState({ loading: true });
                                        LoginApi.changePassword(oldPassword, newPassword).then((data) => {
                                            this.setState({ loading: false });
                                            //console.log("api response: "+JSON.stringify(data));

                                            inputOld!.option("value", "");
                                            inputNew!.option("value", "");

                                            notify("Successfully changed password!", 'success');
                                        })
                                            .catch(err => {
                                                this.setState({ loading: false });
                                                //console.log("api error: "+JSON.stringify(err));
                                                notify("Couldn't change password. Is Your old password correct?", 'error');
                                            })
                                    }
                                }
                                else notify("Please enter old and new password", 'error');
                            }
                            catch (err) {
                                //
                            }
                        }}>Save</Button>
                    </Col>
                </Row>
                {
                    this.state.currentUser && isAdmin(this.state.currentUser) &&
                    <div>
                        <Row className="translucent" style={{ paddingTop: 20 }}>
                            <Col><h1>Global Backup</h1></Col>
                        </Row>
                        <Row className="translucent">
                            <Col style={{ paddingLeft: 0 }} className="title-container">
                                <Button className="buttoncreate" onClick={() => {
                                    this.setState({ loading: true });
                                    API.setAllStationsRecovery(true).then(() => {
                                        this.setState({ loading: false });
                                        notify('Successfully updated all Stations!')
                                    }).catch(err => {
                                        notify('Error setting all stations to recovery', 'error')
                                        this.setState({ loading: false });
                                    })
                                }}>Set all to backup</Button>
                            </Col>
                        </Row>
                        <Row className="translucent">
                            <Col style={{ paddingTop: 10, paddingLeft: 0 }} className="title-container">
                                <Button className="buttoncreate" onClick={() => {
                                    if (confirm('Are you sure?')) {
                                        this.setState({ loading: true });
                                        API.setAllStationsRecovery(false).then(() => {
                                            this.setState({ loading: false });
                                            notify('Successfully updated all Stations!')
                                        }).catch(err => {
                                            notify('Error setting all stations to not-recovery', 'error')
                                            this.setState({ loading: false });
                                        })
                                    }
                                }}>Set all to LIVE</Button>
                            </Col>
                        </Row>
                    </div>
                }
                {
                    this.state.loading && <Loader />
                }
            </Container>
        )
    }
}
