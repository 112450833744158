import ParentItem from '../Highlights/parents/HighlightDetailItem'
import { createNewHighlightModel, HighlightBanner, propertyList, ImageType } from "../../api/models/highlight_banners";
import { API } from "../../api/endpoints/highlight_banners";
import { getUrlParamsFromSearchOptions, addUrlParam } from 'src/utils/urlUtils';
import {withRouter} from "../../utils/withRouter";

class HighlightDetailItem extends ParentItem<HighlightBanner, ImageType> {

    createNewHighlightModel(): any {
        return createNewHighlightModel(this.parseStationRef(this.getRefFromUrl()));
    }
    apiUpdateImage(id, file): Promise<HighlightBanner> {
        return API.updateImage(id, file);
    }
    apiUpdateHighlight(highlight: HighlightBanner): Promise<HighlightBanner> {
        return API.updateHighlightBanner(highlight);
    }
    apiCreateHighlight(highlight: HighlightBanner): Promise<HighlightBanner> {
        return API.createHighlightBanner(highlight);
    }

    getPropertyList() {
        return propertyList();
    }

    protected getImageInfos() {
        return {
            name: 'image_url',
            size: '520 x 320',
            description: 'Highlight Logo'
        }
    }

    getEditPagePath(highlight: HighlightBanner | undefined) {
        return highlight && '/highlight-banners/'+highlight.id
    }

    getGoBackFallbackPath() {
        let urlParams = getUrlParamsFromSearchOptions(this.getSearchOptionsFromUrl());
        urlParams += addUrlParam(urlParams, "scroll", this.getScrollId(this.state && this.state.highlight));
        return '/highlight-banners'+urlParams;
    }

    protected getScrollId(highlight?: HighlightBanner) {
        if(highlight && highlight.id) {
            return "banner"+highlight.id;
        }
        return "highlight-banners";
    }
}
export default withRouter(HighlightDetailItem);