import * as React from 'react';
import "../../../ui/styles.css";
import './CoverArtScheduler.css'
import { API } from "../../../api/endpoints/stations";
import { CoverArtSchedule } from "../../../api/models/cover_art_schedule";
import ShowsScheduler from "./ShowsScheduler";
import { Station } from 'src/api/models/stations';

export class CoverArtScheduler extends ShowsScheduler<CoverArtSchedule> {

    constructor(props) {
        super(props);
    }

    protected apiGetSchedule(station) {
        return API.getCoverArtSchedule(station);
    }

    protected apiCreateSchedule(station, data, file) {
        return API.createCoverArtSchedule(station, data, file);
    }

    protected apiUpdateSchedule(station, data, file) {
        return API.updateCoverArtSchedule(station, data, file);
    }

    protected apiDeleteSchedule(station, id) {
        return API.deleteCoverArtSchedule(station, id);
    }
}

export default class extends React.Component<{
    renderPopover?: (url, target) => JSX.Element | undefined,
    station: Station,
}> {
    render() {
        let { station, renderPopover } = this.props;
        return (
            <CoverArtScheduler
                title="Cover Art Scheduler"
                description="cover art"
                station={station}
                renderPopover={renderPopover}/>
        )
    }
}
