import * as React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import upload from '../../../img/upload_icon.svg'
import { formatTimeOnly } from "../../../utils/dates";
import "../../../ui/styles.css";
import * as $ from 'jquery'
import './CoverArtScheduler.css'
import notify from "../../../ui/notify";
import Loader from "../../../components/loader/Loader";
import { Station } from "../../../api/models/stations";
import {RefObject} from "react";
import dxScheduler, {Appointment} from "devextreme/ui/scheduler";
import dxContextMenu from "devextreme/ui/context_menu";
import dxForm, {Item} from "devextreme/ui/form";
import {Scheduler} from "devextreme-react/scheduler";
import {ContextMenu} from "devextreme-react/context-menu";
import {CoverArtScheduler} from "./CoverArtScheduler";
import {CoverArtSchedule} from "../../../api/models/cover_art_schedule";

export interface ScheduleData extends Appointment {
    cover_url: string
    id: number
    temp_id?: number
}
export interface ScheduleEntity {
    id: number;
    station_id: string;
    cover_url: string;

    startDate: Date;
    endDate: Date;
    text?: string;
    recurrenceException?: string;
    recurrenceRule?: string;
}

export default abstract class ShowsScheduler<EntityData extends ScheduleEntity> extends React.Component<
    {   //props
        renderPopover?: (url, target) => JSX.Element | undefined,
        station: Station,
        title: string,          //'Cover Art Scheduler'
        description: string,    //Upload 'Cover Art'
        applyFormData?: (form: dxForm, applyItem: (key: string, data: any, index?: number)=>void)=>void,
        applyEditPopup?: (item: ScheduleData)=>React.ReactNode,
        applyItemTitle?: (item: ScheduleData)=>React.ReactNode
    },
    {   //state
        dataSource?: ScheduleData[]
        loading: boolean,
        currentView: 'agenda' | 'day' | 'month' | 'timelineDay' | 'timelineMonth' | 'timelineWeek' | 'timelineWorkWeek' | 'week' | 'workWeek'
    }>
{
    private mPopoverIdCounter: number = 0;
    private readonly scheduler: RefObject<Scheduler>;
    private readonly appointmentContextMenu: RefObject<ContextMenu>;
    private readonly cellContextMenu: RefObject<ContextMenu>;

    constructor(props) {
        super(props);
        this.scheduler = React.createRef();
        this.appointmentContextMenu = React.createRef();
        this.cellContextMenu = React.createRef();
        this.state = {
            loading: false,
            currentView: 'week'
        }
    }

    protected abstract apiGetSchedule(station: Station): Promise<EntityData[]>
    protected abstract apiCreateSchedule(station: Station, data: Appointment, file): Promise<EntityData>
    protected abstract apiUpdateSchedule(station: Station, data: Appointment, file): Promise<EntityData>
    protected abstract apiDeleteSchedule(station: Station, id: number): Promise<void>

    componentDidMount() {
        this.apiGetSchedule(this.props.station).then(data => {
            this.setState({
                dataSource: data,
            });
        }).catch(err => {
            if (err && err.message) {
                notify(err.message, 'error', 5000)
            }
            this.setState({
                dataSource: []
            })
        })
    }

    private mTargetedAppointmentData: any = undefined;

    render() {
        if (this.state.dataSource == undefined) {
            return <Loader />
        };
        return (
            <Container md={4}>
                <Row>
                    <Col style={{ flex: 1, textAlign: 'center' }}><h3>{this.props.title}</h3></Col>
                </Row>
                <Row >
                    <Scheduler
                        ref={this.scheduler}
                        currentView={this.state.currentView}
                        views={["day", "week", "workWeek", "month"]}
                        onOptionChanged={(e) => {

                            if (e.name == 'currentView') {
                                this.setState({
                                    currentView: e.value
                                })
                            }
                        }}
                        showCurrentTimeIndicator={true}
                        dataSource={this.state.dataSource}
                        recurrenceEditMode={'series'}
                        appointmentTooltipRender={(props) => {
                            //console.log("props: " + JSON.stringify(props));
                            let item: ScheduleData = props.appointmentData as ScheduleData;
                            let imageSize = 100;
                            return (
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <img style={{ width: imageSize, height: imageSize, objectFit: 'cover', objectPosition: 'top', float: 'left' }} src={item.cover_url || upload} />
                                    <div style={{flex: 1, marginLeft: 15}}>
                                        <div style={{ textAlign: 'left' }}>
                                            {this.props.applyEditPopup ? this.props.applyEditPopup(item) : <p style={{paddingBottom: 5}}><b>{item.text}</b></p>}
                                            <p>start: {formatTimeOnly(item.startDate)}</p>
                                            <p>end: {formatTimeOnly(item.endDate)}</p>
                                        </div>
                                        <Button className='btn-blue' style={{ float: 'left', marginTop: 10 }} onClick={() => {
                                            let scheduler: dxScheduler = this.scheduler.current!.instance;
                                            scheduler.showAppointmentPopup(item, false);
                                        }}>Edit</Button>
                                        <Button className='btn-blue' style={{ float: 'right', marginTop: 10 }} onClick={() => {
                                            let scheduler: dxScheduler = this.scheduler.current!.instance;
                                            this.deleteAppointment(item);
                                            scheduler.hideAppointmentTooltip();
                                        }}>Delete</Button>
                                    </div>
                                </div>
                            )
                        }}
                        appointmentRender={(item) => {
                            let key = "a" + (++this.mPopoverIdCounter);
                            let imageSize = 50;
                            const appointment: ScheduleData = item.appointmentData;
                            return (
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <img id={key} style={{ width: imageSize, height: imageSize, objectFit: 'cover' }} src={appointment.cover_url || upload} />
                                    <div style={{ flex: 1, alignSelf: 'stretch', textAlign: 'left', verticalAlign: 'middle', color: 'white', marginLeft: 10, whiteSpace: 'normal', wordWrap: 'break-word'}}>{this.props.applyItemTitle ? this.props.applyItemTitle(appointment) : appointment.text}</div>
                                    {
                                        appointment.cover_url && this.props.renderPopover && this.props.renderPopover(appointment.cover_url, '#' + key)
                                    }
                                </div>
                            )
                        }}
                        onAppointmentFormOpening={e => {
                            let item = e.appointmentData!;
                            //change label for title, hide stuff
                            e.form!.itemOption("text", { label: { text: "Title" }, visible: false })
                            e.form!.itemOption("description", { label: { text: "Description" }, visible: false })
                            e.form!.itemOption("allDay", { visible: false })

                            let updateItem = (key: string, data: any, index?: number)=>{
                                if (e.form!.itemOption(key)) {
                                    e.form!.itemOption(key, data)
                                } else {
                                    var items = e.form!.option("items") || [];
                                    let newItem = {
                                        dataField: key,
                                        editorType: 'dxTextBox',
                                        ...data
                                    }
                                    if(index!==undefined && index>=0) {
                                        items.splice(index, 0, newItem);
                                    }
                                    else items.push(newItem);
                                    e.form!.option("items", items);
                                }
                            }

                            if(this.props.applyFormData) {
                                this.props.applyFormData(e.form!, updateItem);
                            }
                            else {
                                e.form!.itemOption("text", { visible: true })
                            }

                            e.form!.updateData("temp_id", Math.random())

                            let setImageForSchedule = function (fileProxy, callback) {
                                fileProxy.preventDefault();
                                let file = fileProxy.currentTarget.files || fileProxy.originalEvent.dataTransfer.files;
                                if (file.length > 0) {
                                    file = file[0];
                                }
                                if (file) {
                                    var reader = new FileReader();
                                    reader.onload = function (e: any) {
                                        callback({ data: e.target.result, file: file })
                                    }
                                    reader.readAsDataURL(file)
                                }
                            }

                            e.form!.option('onContentReady', function (e) {
                                if (!input.parent()) {
                                    $(document.body).append(input)
                                }
                            })

                            let rnd = Math.random();
                            let input = $('<input \
                                        accept="image/*" \
                                        id="input_s_ + ' + rnd + '"  \
                                    type="file" style={{ display: "none" }}/>')
                            input.on('change', function (event) {
                                setImageForSchedule(event, (data) => {
                                    e.form!.itemOption("upload_btn", { buttonOptions: Object.assign({}, e.form!.itemOption("upload_btn").buttonOptions, { icon: data.data, text: undefined }) })
                                    e.form!.updateData("cover_url", data.data)
                                    e.form!.updateData("coverArtFile", data.file)
                                })
                            })
                            let form = e.form!
                            let button: Item = {
                                itemType: "button",
                                name: 'upload_btn',
                                horizontalAlignment: "left",
                                buttonOptions: {
                                    text: item.cover_url ? undefined : `Upload ${this.props.description || "image"}`,
                                    icon: item.cover_url,
                                    height: '200px',
                                    width: '200px',
                                    onClick: function (e) {
                                        e.event?.preventDefault();
                                        input.click();
                                    },
                                    onContentReady: function (e) {
                                        $(e.element).on('dragover', (e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                        })
                                        $(e.element).on('dragleave', (e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                        })
                                        $(e.element).on('drop', (event) => {
                                            setImageForSchedule(event, (data) => {
                                                form!.itemOption("upload_btn", { buttonOptions: Object.assign({}, form!.itemOption("upload_btn").buttonOptions, { icon: data.data, text: undefined }) })
                                                form!.updateData("cover_url", data.data)
                                                form!.updateData("coverArtFile", data.file)
                                            })
                                        })
                                    },
                                    elementAttr: {
                                        class: 'cover_upload_btn'
                                    }
                                }
                            }

                            if (e.form!.itemOption("upload_btn")) {
                                e.form!.itemOption('upload_btn', button)
                            } else {
                                const items: Item[] = e.form?.option("items") || [];
                                items.push(button)
                                e.form!.option("items", items);
                            }
                        }}
                        onAppointmentContextMenu={(e)=>{
                            if(e.appointmentData && e.targetedAppointmentData) {
                                e.event!.preventDefault();

                                //cache targeted appointment data
                                this.mTargetedAppointmentData = e.targetedAppointmentData;

                                //show appointment context menu
                                this.showContextMenu(this.appointmentContextMenu, e.event!)
                            }
                        }}
                        onCellContextMenu={(e)=>{
                            if(e.cellData) {
                                e.event!.preventDefault();

                                //show cell context menu
                                this.showContextMenu(this.cellContextMenu, e.event!)
                            }
                        }}
                        onAppointmentAdding={(e) => {
                            e.cancel = (!e.appointmentData || !e.appointmentData.coverArtFile)
                            if (e.cancel) {
                                notify(`Please specify a ${this.props.description || "preview"} image`, 'error')
                            }
                        }}
                        onAppointmentAdded={(e) => {
                            this.setState({
                                loading: true
                            })
                            if(!e.appointmentData.startDate || !e.appointmentData.endDate)
                                return;
                            this.apiCreateSchedule(this.props.station, e.appointmentData, e.appointmentData.coverArtFile).then(instance => {
                                let source = this.state.dataSource!
                                let index = source.findIndex((t) => t.temp_id == e.appointmentData!.temp_id)
                                source[index] = instance
                                this.setState({
                                    loading: false,
                                    dataSource: source
                                })
                            }).catch(err => {
                                if (err && err.message) {
                                    notify(err.message, 'error', 5000)
                                }
                                this.setState({
                                    loading: false,
                                    dataSource: this.state.dataSource!.filter(t => t.temp_id != e.appointmentData.temp_id)
                                })
                            })
                        }}
                        onAppointmentDeleted={(e) => {
                            if (e.appointmentData.id) {
                                this.setState({
                                    loading: true
                                })
                                this.apiDeleteSchedule(this.props.station, e.appointmentData.id).then(() => {
                                    this.setState({
                                        loading: false,
                                        dataSource: this.state.dataSource!.filter(t => t.id != e.appointmentData.id)
                                    })
                                }).catch(err => {
                                    if (err && err.message) {
                                        notify(err.message, 'error', 5000)
                                    }
                                    this.setState({
                                        loading: false
                                    })
                                })
                            }
                        }}
                        onAppointmentUpdated={(e) => {
                            this.setState({
                                loading: true
                            })
                            this.apiUpdateSchedule(this.props.station, e.appointmentData, e.appointmentData.coverArtFile).then(instance => {
                                let source = this.state.dataSource!
                                let index = source.findIndex((t) => t.id == e.appointmentData!.id)
                                source[index] = instance
                                this.setState({
                                    loading: false,
                                    dataSource: source
                                })
                            }).catch(err => {
                                if (err && err.message) {
                                    notify(err.message, 'error', 5000)
                                }
                                this.setState({
                                    loading: false
                                })
                            })
                        }}
                        height={500} />
                </Row>
                {this.state.loading && <Loader title="Updating..." />}
                <ContextMenu
                    ref={this.cellContextMenu}
                    dataSource={[
                        {
                            text: `Schedule ${this.props.description || "cover art"}`
                        }
                    ]}
                    showEvent=""
                    closeOnOutsideClick={true}
                    onItemClick={(e)=>{
                        if(e.itemIndex===0 && this.scheduler.current) {
                            let scheduler: dxScheduler = this.scheduler.current.instance;
                            //console.log(JSON.stringify(scheduler.option("selectedCellData")));

                            var startDate = "";
                            var endDate = "";
                            try {

                                let selection = scheduler.option("selectedCellData");
                                startDate = selection![0].startDate;
                                endDate = selection![selection!.length-1].endDate;
                            }
                            catch(err) {
                                //
                            }

                            //show appointment dialog
                            scheduler.showAppointmentPopup({
                                startDate: startDate,
                                endDate: endDate
                            }, true);
                        }
                    }} />
                <ContextMenu
                    ref={this.appointmentContextMenu}
                    dataSource={[
                        {
                            text: 'Edit'
                        },
                        {
                            text: 'Delete'
                        }
                    ]}
                    closeOnOutsideClick={true}
                    showEvent=""
                    onItemClick={(e)=>{
                        if(e.itemIndex===0) {
                            let scheduler: dxScheduler = this.scheduler.current!.instance;
                            scheduler.showAppointmentPopup(this.mTargetedAppointmentData || {startDate: new Date()}, false);
                        }
                        else if(e.itemIndex===1) {
                            this.deleteAppointment(this.mTargetedAppointmentData);
                        }
                    }} />
            </Container>
        )
    }

    private showContextMenu(ref: RefObject<ContextMenu>, event) {
        let contextMenu: dxContextMenu = ref.current!.instance;
        contextMenu.option("position", {offset: {
            x: (event as any).pageX,
            y: (event as any).pageY
        }});
        contextMenu.show();
    }

    private deleteAppointment(item) {
        if(item && this.scheduler.current) {
            let scheduler: dxScheduler = this.scheduler.current.instance;
            if (confirm('Do you really want to delete this series/appointment?')) {
                scheduler.deleteAppointment(item);
            }
        }
    }
}
