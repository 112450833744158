import * as React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import DragDropSection from "./DragDropSection";
import Collapse from "reactstrap/lib/Collapse";

export default class CollapsibleSection extends React.Component<{
    startExpanded?: boolean,
    children?: React.ReactNode,
    title: string,
    bigTitle?: boolean,
    onExpanded?: (expanded: boolean)=>void
}, {
    expanded: boolean
}> {
    constructor(props) {
        super(props);
        this.state = {
            expanded: props.startExpanded===true
        }
    }

    render() {
        return(
            <Container fluid>
                <Row className="title-container">
                    <Col>
                    {   this.props.bigTitle ?
                        <h1>{this.props.title}</h1> :
                        <h3>{this.props.title}</h3>
                    }
                    </Col>
                    <Col xs={{order: 'right'}} style={{padding: 20}}>
                        <Button onClick={(e) => this.setState({ expanded: !this.state.expanded }, ()=>this.props.onExpanded && this.props.onExpanded(this.state.expanded))}>{this.state.expanded ? 'Hide' : 'Show'}</Button>
                    </Col>
                </Row>
                <Row>
                    <Collapse style={{flex: 1}} isOpen={this.state.expanded}>
                        <DragDropSection title="" hideTitle={true}>
                            {this.props.children}
                        </DragDropSection>
                    </Collapse>
                </Row>
            </Container>
        )
    }

}