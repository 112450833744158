import * as React from "react";
import { Row, Col } from "reactstrap";
import './StationItem.css'
import { addUrlParamBase } from "src/utils/urlUtils";
import {Link} from "react-router-dom";

export const imgStyle: React.CSSProperties = {
    float: "left",
    width: 140,
    height: 140,
    objectFit: 'cover', 
    objectPosition: 'top'
}

export interface GenericItemProps {
    children?: React.ReactNode,
    inactive?: boolean,
    refKey?: string,
    refDragDropView,
    scrollKey?: string,
    detailPath: string,
    searchOptions?: ()=>string,
    title: string,
    subtitle?: string,
    description?: string,
    imageUrl?: string
}

export default class GenericItem extends React.Component<GenericItemProps, {}> {

    constructor(props) {
        super(props);
        this.state = {
            //
        }
    }

    render() {
        let refLink =  this.props.refKey && "?ref="+this.props.refKey || "";
        let searchOptions = this.props.searchOptions && this.props.searchOptions() || "";
        var urlParams = refLink+"";
        if(searchOptions && searchOptions.length>0) {
            urlParams += addUrlParamBase(urlParams) + searchOptions.substr(1);
        }
        let detailLink = this.props.detailPath + urlParams;
        return (
            <Col lg="4" md="6" id={this.props.scrollKey} className={(this.props.inactive===true ? 'station-inactive' : '') + ' station-item'}>
                <div ref={this.props.refDragDropView} style={{ cursor: 'move', display: 'flex', flexWrap: 'wrap', marginLeft: -15, marginRight: -15 }}>
                    <Col xs="auto" style={{paddingRight: 0}}>
                        <img style={imgStyle} alt="" src={this.props.imageUrl} />
                    </Col>
                    <Col style={{paddingLeft: 0}}>
                        <div className="stations-x">
                            <h2><Link to={detailLink}>{this.props.title}</Link></h2>
                            <p>{this.props.subtitle}</p>
                            <p>{this.props.description}</p>
                            <Link to={detailLink} className="buttonstations page-scroll">Detail</Link>
                            {this.props.children}
                        </div>
                    </Col>
                </div>
            </Col>
        )
    }
}