import * as React from "react";
import { Container, Row, Col } from "reactstrap";
import Button from "reactstrap/lib/Button";
import {HTML5Backend} from 'react-dnd-html5-backend'
import {DndProvider} from "react-dnd";

export default class DragDropSection extends React.Component<{title: string, hasChanged?: boolean, hideTitle?: boolean, onSaveClick?: ()=>void, children?: React.ReactNode}, {}> {
    render() {
        return (
            <DndProvider backend={HTML5Backend}>
                <section className="container-section station-section dash-row" style={{flex: 1}}>
                    <Container fluid>
                        {this.props.hideTitle!==true &&
                            <Row>
                                <Col>
                                    <h1>{this.props.title}{this.props.hasChanged===true && "*"}</h1>
                                </Col>
                                <Col xs={{order: 'right'}} className="title-container">
                                    {
                                        this.props.hasChanged===true &&
                                        <Button onClick={()=>{this.props.onSaveClick && this.props.onSaveClick()}} className="button">Save</Button>
                                    }
                                </Col>
                            </Row>
                        }
                        <Row>
                            {this.props.children}
                        </Row>
                    </Container>
                </section>
            </DndProvider>
        )
    }
}