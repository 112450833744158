import * as React from 'react';

import { PLACES_API_URL, PLACES_API_URL_ASYNC, PLACES_API_READY_CALLBACK_NAME, GOOGLE_API_KEY } from '../../../const'
//<script async defer type="text/javascript" src={PLACES_API_URL_ASYNC}></script>
//<script src={PLACES_API_URL} />
import './LocationSearchInput.css'

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
    
} from 'react-places-autocomplete';

import Geocode from "react-geocode";

/**
 * from https://github.com/hibiken/react-places-autocomplete
 */


export default class LocationSearchInput extends React.Component<{initValue, onAddressChanged: (address, latLng)=>void}, {address: string}> {
  constructor(props) {
    super(props);
    this.state = { address: '' };
    
    try {
        if(props.initValue) {
            Geocode.setApiKey(GOOGLE_API_KEY);
            Geocode.fromLatLng(props.initValue.lat, props.initValue.lng).then(
                response => {
                  const address = response.results[0].formatted_address;
                  //console.log(address);
                  this.setAddress(address);
                },
                error => {
                  //console.error(error);
                }
              );
        }
    }
    catch(err) {
        //
    }
  }

  public setAddress(address: string) {
      this.setState({address: address})
  }

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
          console.log('Success', latLng)
          this.setState({address: address}, ()=>{
            if(this.props.onAddressChanged) {
                this.props.onAddressChanged(this.state.address, latLng)
            }
          });
        })
      .catch(error => console.error('Error', error));
  };

  render() {
    return (
      <PlacesAutocomplete
        googleCallbackName={PLACES_API_READY_CALLBACK_NAME}
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: 'location-search-input',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}