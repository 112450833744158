import { AuthenticatedRequest, HTTPMethod, APIVersion, AuthenticatedFileRequest, APIResponse } from "../request/request";
import { Highlight, NewHighlight } from "../models/highlights";

export interface IHighlightsListResponse {
    highlights: Highlight[]
}
export interface IHighlightResponse {
    highlight: Highlight
}

export class API {

    public static listHighlights(): Promise<IHighlightsListResponse> {
        let apiRequest = new AuthenticatedRequest<IHighlightsListResponse>('/highlights', HTTPMethod.GET, APIVersion.v1_0);
        return apiRequest.send().then((data) => {
            return data.data
        })
    }

    public static getHighlightById(id: number): Promise<IHighlightResponse> {
        let apiRequest = new AuthenticatedRequest<IHighlightResponse>('/highlights/'+id, HTTPMethod.GET, APIVersion.v1_0);
        return apiRequest.send().then((data) => {
            return data.data
        })
    }

    public static getHighlightsFromStation(id: number): Promise<IHighlightsListResponse> {
        let apiRequest = new AuthenticatedRequest<IHighlightsListResponse>('/stations/'+id+'/highlights/', HTTPMethod.GET, APIVersion.v1_0);
        return apiRequest.send().then((data) => {
            return data.data
        })
    }

    public static updateImage(id: number, image: any): Promise<Highlight> {
        let apiRequest = new AuthenticatedFileRequest<Highlight>('/highlights/'+id+'/image', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            image: image
        });
        return apiRequest.send().then((response) => {
            return <Highlight>response.data;
        });
    }

    public static updateHighlight(highlight: Highlight): Promise<Highlight> {
        let apiRequest = new AuthenticatedRequest<Highlight>('/highlights/' + highlight.id, HTTPMethod.PUT, APIVersion.v1_0, undefined, {
            highlight: highlight
        });
        return apiRequest.send().then((response) => {
            return <Highlight>response.data;
        });
    }

    public static createHighlight(highlight: NewHighlight): Promise<Highlight> {
        let apiRequest = new AuthenticatedRequest<NewHighlight>('/highlights', HTTPMethod.POST, APIVersion.v1_0, undefined, highlight);
        return apiRequest.send().then((response) => {
            return <Highlight>response.data;
        });
    }

}