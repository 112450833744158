import { IPropertyList } from "./property";

export interface NewGenreItem {
    priority: number;
    genre_name: string;
    genre_image_url: string;
}
export interface Genre extends NewGenreItem {
    id: number;     //from api

    temp_sort: number;  //local
}

export function createNewGenreModel(): NewGenreItem {
    return {
        priority: 0,
        genre_name: "",
        genre_image_url: ""
    }
}

export type ImageType = 'genre_image_url';

export function GenreSortPriority(item1: Genre, item2: Genre) {
    return item2.priority - item1.priority
}
export function GenreTempSort(item1: Genre, item2: Genre) {
    return item2.temp_sort - item1.temp_sort
}

export function propertyList(): IPropertyList {
    return {
        properties: [
            { name: 'id', readOnly: true, type: 'Number' },
            { name: 'priority', type: 'Number' },
            { name: 'genre_name', type: 'String' },
            { name: 'genre_image_url', type: 'URL', hide: true }
        ]
    }
}