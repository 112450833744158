import * as React from "react";
import { Session } from "../../api/request/session";
import {Navigate} from "react-router-dom";

export class Authenticated extends React.Component<{children?: React.ReactNode}, { redirectToLogin: boolean }> {

    constructor(props) {
        super(props);
        this.state = {
            redirectToLogin: Session.getSession() == undefined
        }
        Session.unauthorizedHandler = (error) => {
            this.setState({
                redirectToLogin: true
            })
        }
    }

    public render() {
        const { redirectToLogin } = this.state
        if (redirectToLogin) {
            return <Navigate to='/login' />
        }
        return this.props.children;
    }
}