import * as React from "react";
import { Row, Col, Container } from "reactstrap";
import './StationSection.css'
import { Station, StationTempSort, StationSort } from "../../../api/models/stations";
import Loader from "../../loader/Loader";
import GenreSection from './GenreSection';
import { User } from "src/api/models";

interface StationSectionProps {
    dataSource: Station[],
    title: string,
    isSearchData: boolean,
    currentUser?: User
}

interface StationSectionStates {
    dataSource: Station[],
    isSearchData: boolean,
    isLoading: boolean
}

export default class StationSection extends React.Component<StationSectionProps, StationSectionStates> {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: this.props.dataSource,
            isSearchData: this.props.isSearchData,
            isLoading: false
        }
    }

    componentDidUpdate(prevProps: Readonly<StationSectionProps>, prevState: Readonly<StationSectionStates>, snapshot?: any) {
        this.setState({
            dataSource: this.props.dataSource,
            isSearchData: this.props.isSearchData
        })
    }

    private getGenres() {
        return this.state.dataSource.map(station => {
            return station.genre
        }).unique()
    }

    private stationsInGenre(genre: string) {
        return this.state.dataSource.filter(station => {
            return station.genre == genre;
        }).sort(StationTempSort)
    }

    private featuredStations(): Station[] {
        return this.state.dataSource
            .filter(station => station.featured_priority != undefined)
            .map(station => ({...station, temp_genre_sort: 500 - (station.featured_priority || 50)}))
            .sort(StationTempSort);
    }

    public render() {
        return (
                <section className="container-section station-section dash-row" style={{flex: 1}}>
                    {this.state.isLoading && <Loader title="Updating..." />}
                    <Container fluid>
                        <Row>
                            <Col>
                                <h1>{this.props.title}</h1>
                            </Col>
                        </Row>
                       <GenreSection
                           currentUser={this.props.currentUser}
                           dataSource={this.featuredStations()}
                           genre={"Featured"}
                           isSearchData={false}/>
                        {this.getGenres().map(genre => {
                            return (
                                <GenreSection genre={genre} currentUser={this.props.currentUser}
                                    key={genre}
                                    dataSource={this.stationsInGenre(genre)}
                                    isSearchData={this.state.isSearchData} />
                            )
                        })}
                    </Container>
                </section>
        )
    }
}
