import * as React from "react";
import { Container, Row, Col } from "reactstrap";
import { TextBox } from "devextreme-react";
import notify from "../../../ui/notify";
import Loader from "../../../components/loader/Loader";
import HighlightItem from "../../../components/stations/section/HighlightItem";
import DragDropSection from "../../../components/stations/section/DragDropSection";
import CollapsibleSection from "../../../components/stations/section/CollapsibleSection";
import { History } from 'history'
import { User } from "src/api/models";
import { API as UserApi } from "src/api/endpoints/user";
import { isAdmin } from "src/api/models/user";
import { getParamsFromUrl, getScrollHintFromUrl, addUrlParam } from "src/utils/urlUtils";

const topSpacing: React.CSSProperties = {
    paddingTop: '50px'
}

export default abstract class HighlightsGeneric<Highlight> extends React.Component<{ history: History },
    {   //state
        activeHighlights?: Highlight[],
        highlights?: Highlight[],
        filteredHighlights?: Highlight[],
        changed: boolean,
        showInactive: boolean,
        searchValue?: string,
        isLoading: boolean,
        currentUser?: User
    }> {

    constructor(props) {
        super(props);
        let showInactiveState: number = this.getStateParamFromUrl("showInactive");
        this.state = {
            changed: false,
            isLoading: false,
            showInactive: showInactiveState > 0 ? true : false,
            searchValue: ''//localStorage['dash_board_last_search']
        }
    }

    private getStateParamFromUrl(param): number {
        let params = getParamsFromUrl(this.props);
        return params && params[param] || 0;
    }

    abstract getAllHighlights(callback);
    abstract isHighlightActive(highlight: Highlight);
    abstract getActiveHighlights(highlights: Highlight[]);
    abstract getInactiveHighlights(highlights: Highlight[]);
    abstract getHighlightTempSort();
    abstract filterHighlights(highlights: Highlight[], query);
    abstract apiUpdateHighlight(highlight: Highlight);
    abstract renderHighlightItem(highlight: Highlight, active: boolean, searchOptions: () => string, moveItem, canMove, didCommitDrop): JSX.Element;
    abstract getCurrentUrlPath(): string;

    protected renderCreateHighlightButton(): JSX.Element | undefined {
        return undefined;
    }

    protected getPlaceholderText() {
        return "No highlights found."
    }
    protected getSectionTitleText() {
        return "Highlights"
    }

    private mScrollToId?: string = undefined;
    public componentDidMount() {
        this.mScrollToId = getScrollHintFromUrl(this.props);
        this.loadData();
    }

    componentDidUpdate() {
        if (this.mScrollToId) {
            window.requestAnimationFrame(() => {
                var element = document.getElementById(this.mScrollToId!);
                if (element) {
                    element.scrollIntoView({ block: 'center', behavior: ('instant' as any) });
                    this.refreshUrlParams();
                    //window.history.replaceState(this.state, window.document.title, this.getCurrentUrlPath());
                }
            })
        }
    }

    private buildUrlSearchOptions(): string {
        var search = "";
        if (this.state) {
            if (this.state.showInactive === true) {
                search += addUrlParam(search, "showInactive", (this.state.showInactive ? 1 : 0));
            }
        }
        return search;
    }
    private refreshUrlParams() {
        var url = this.getCurrentUrlPath();
        var search = this.buildUrlSearchOptions();
        window.history.replaceState(this.state, window.document.title, url + search);
    }

    private loadData() {
        UserApi.getMe().then(user => {
            this.setState({ currentUser: user })
        }).catch(err => { });

        this.getAllHighlights((error, response) => {
            if (error) {
                notify(error, 'error');
            }

            let highlights = response && response.highlights || [];
            this.setState({
                activeHighlights: this.getActiveHighlights(highlights).sort(this.getHighlightTempSort()),
                highlights: this.getInactiveHighlights(highlights),
                isLoading: false
            }, () => {
                if (localStorage['dash_board_last_search']) {
                    this.setState({
                        filteredHighlights: this.filterHighlights(response, localStorage['dash_board_last_search'])
                    })
                }
            })
        });
    }

    public render() {
        if (!this.state.highlights) {
            return <Loader />
        }
        return (
            <Container style={topSpacing} className="dash-row">
                {
                    this.renderCreateHighlightButton()
                }
                <Row>
                    <Col>
                        <TextBox placeholder="Search by Name, Station, ..." value={this.state.searchValue} onChange={(e) => {
                            this.setState({
                                searchValue: e.component!.instance().option('value')
                            })
                        }} onEnterKey={(e) => {
                            this.setState({
                                filteredHighlights: this.filterHighlights((this.state.activeHighlights || []).concat(this.state.highlights || []), e.component!.instance().option('value')),
                                searchValue: e.component!.instance().option('value')
                            })
                        }} />
                    </Col>
                </Row>
                <Row hidden>
                    <Col>
                        Filter<a href="#" className="button page-scroll">Featured</a>
                        Sort by<a href="#" className="button page-scroll">Genre</a>
                        <a href="#" className="button page-scroll">ABC</a>
                    </Col>
                </Row>

                {!this.getDataActive() && !this.getDataInactive() &&    //placeholder
                    <Row>
                        <Col>
                            <p>{this.getPlaceholderText()}</p>
                        </Col>
                    </Row>
                }

                {this.getDataActive() && this.getDataActive().length > 0 &&   //active highlights and search results   
                    <Row>
                        <DragDropSection title={this.getSectionTitleText()} hasChanged={this.state.changed} onSaveClick={() => { this.saveChangedHighlights() }}>
                            {
                                this.getDataActive().map(highlight => {
                                    return (
                                        this.renderHighlightItem(highlight, this.isHighlightActive(highlight), () => this.buildUrlSearchOptions(), this.moveItem, this.canMoveItem, this.commitDrop)
                                    )
                                })
                            }
                        </DragDropSection>
                    </Row>
                }

                {this.getDataInactive() && this.getDataInactive().length > 0 &&      //inactive highlights
                    <Row>
                        <CollapsibleSection title={this.getSectionTitleText() + " (inactive)"} bigTitle startExpanded={this.state.showInactive} onExpanded={(expanded) => this.setState({ showInactive: expanded }, this.refreshUrlParams)}>
                            {this.getDataInactive().map(highlight => {
                                return (
                                    this.renderHighlightItem(highlight, this.isHighlightActive(highlight), () => this.buildUrlSearchOptions(), this.moveItem, this.canMoveItem, this.commitDrop)
                                )
                            })}
                        </CollapsibleSection>
                    </Row>
                }

                {this.state.isLoading === true && <Loader />}
            </Container>
        )
    }

    private getDataActive() {
        if (this.isSearching() && this.state.filteredHighlights) {
            return this.state.filteredHighlights || [];
        }
        return this.state.activeHighlights || [];
    }
    private getDataInactive() {
        if (this.isSearching()) {
            return [];
        }
        return this.state.highlights && this.state.highlights.slice(0, 50) || [];        //limit results to 50
    }

    private commitDrop(source: Highlight) {
        //
    }

    private saveChangedHighlights() {
        if (this.state.activeHighlights) {
            this.setState({
                isLoading: true
            })
            Promise.all(this.state.activeHighlights.map(s => {
                (s as any).priority = (s as any).temp_sort;
                return this.apiUpdateHighlight(s);
            })).then(() => {
                this.setState({
                    isLoading: false,
                    changed: false
                })
            }).catch(err => {
                if (err && err.message) {
                    notify(err.message, 'error', 5000)
                }
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    private isSearching(): boolean {
        if (this.state.searchValue && this.state.searchValue.length > 0) {
            return true;
        }
        return false;
    }

    private canMoveItem(source: Highlight, target: Highlight) {
        return isAdmin(this.state.currentUser) &&
            !this.isSearching() &&
            this.isHighlightActive(source) &&
            this.isHighlightActive(target);   //not searching and highlights are both active
    }

    private moveItem(source: Highlight, target: Highlight, direction) {
        this.resortHighlights(source, target, direction)

        //set changed something
        this.state.changed !== true && this.setState({
            changed: true
        })
    }

    private resortHighlights(source: Highlight, target: Highlight, direction) {
        let highlights = this.state.activeHighlights!.splice(0).sort(this.getHighlightTempSort())
        let destinationIndex = highlights.findIndex(s => (s as any).id == (target as any).id);
        highlights = highlights.filter(s => (s as any).id != (source as any).id);
        highlights.splice(destinationIndex, 0, source)
        highlights.reverse().forEach((station, index) => {
            (station as any).temp_sort = (index + 1) * 10
        })
        highlights.sort(this.getHighlightTempSort());
        this.setState({
            activeHighlights: highlights
        })
    }
}