import * as React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import notify from "../../../ui/notify";
import Loader from "../../../components/loader/Loader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DataGrid } from "devextreme-react";
import { API } from '../../../api/endpoints/contest_entries';
import { ContestEntry } from "src/api/models/contest_entries";
import { getUrlParamsFromSearchOptions, getParamsFromUrl, getRefFromUrl, addUrlParamBase } from '../../../utils/urlUtils'
import { withRouter } from "src/utils/withRouter";

class ContestEntries extends React.Component<
{   //props
    router:{params:{stationId:number, contestId: number}, navigate: Function},
},
{   //state vars
    isLoading: boolean,
    entries: ContestEntry[]
}> {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            entries: []
        }
    }

    private getCurrentStationIdFromUrl() {
        return this.props.router.params.stationId;
    }
    private getCurrentContestIdFromUrl() {
        return this.props.router.params.contestId;
    }

    componentDidMount() {
        API.listContestEntries(this.getCurrentStationIdFromUrl(), this.getCurrentContestIdFromUrl()).then(entries=>{
            // console.log("first: "+JSON.stringify(entries.entries[0]))
            this.setState({entries: entries.entries || [], isLoading: false})
        })
        .catch(err=>{
            if(err && err.message) {
                notify(err.message, 'error')
            }
            this.setState({isLoading: false})
        })
    }

    private goBack() {
        let navigate = this.props.router.navigate;

        //get search options
        let urlParams = getUrlParamsFromSearchOptions(getParamsFromUrl(this.props));

        //get reference
        let ref = getRefFromUrl(this.props);
        if(ref && ref.startsWith('contest')) {
            //add station id as ref
            urlParams += addUrlParamBase(urlParams) + "ref=station"+this.getCurrentStationIdFromUrl();

            //go back to contest edit
            navigate('/stations/' + this.getCurrentStationIdFromUrl() + "/contest/" + this.getCurrentContestIdFromUrl() + urlParams);
            return;
        }
        else {
            //add contest scroll id
            urlParams += addUrlParamBase(urlParams) + "scroll=contest"+this.getCurrentContestIdFromUrl();

            //go back to station
            navigate('/stations/' + this.getCurrentStationIdFromUrl() + urlParams);
        }
    }

    render() {
        return (
            <Container className="station-detail dash-row">
                <Container>
                    <Row style={{ paddingTop: '18px', paddingBottom: '0px', backgroundColor: 'transparent' }}>
                        <Col>
                            <div className="back-btn" onClick={()=>{this.goBack()}}>
                                <FontAwesomeIcon icon="chevron-left" />
                                Back
                            </div>
                        </Col>
                    </Row>
                </Container>
                {this.state.isLoading && <Loader title="Updating..." />}
                <Row>
                    <DataGrid ref="dataGrid"
                        dataSource={this.state.entries}
                        editing={{allowAdding: false, allowDeleting: false, allowUpdating: false, mode: 'form'}}
                        //searchPanel={{visible: true}}  //{placeholder: 'Search...', highlightSearchText: true, visible: true}
                        sorting={{mode: 'none'}}
                        columns={[
                            /*/
                            {
                                caption: "User Id",
                                dataField: 'user_id',
                                dataType: 'string'
                            },
                            //*/
                            {
                                caption: "First Name",
                                dataField: 'user.first_name',
                                dataType: 'string',
                                allowSorting: false
                            },
                            {
                                caption: "Last Name",
                                dataField: 'user.first_name',
                                dataType: 'string',
                                allowSorting: false
                            },
                            {
                                caption: "Email",
                                dataField: 'user.email',
                                dataType: 'string',
                                allowSorting: false
                            },
                            {
                                caption: "Entry Date",
                                dataField: 'entry_date',
                                dataType: 'datetime',
                                allowSorting: true
                            }
                        ]}
                        height={600}
                        />
                </Row>
            </Container>
        )
    }
}
export default withRouter(ContestEntries);