import { AuthenticatedRequest, HTTPMethod, APIVersion, AuthenticatedFileRequest, APIResponse } from "../request/request";
import { Station, ImageType, NewStation } from "../models/stations";
import { EventEmitter } from "events";
import { CoverArtSchedule } from "../models/cover_art_schedule";

export interface IStationListResponse {
    active: Station[],
    inactive: Station[],
    all: Station[]
}

export interface IPlaylist {
    station_id: number;
    artist: string;
    title: string;
    cover: string;
}

export interface IHistoryInfo {
    station_id: number;
    description: string;
    title: string;
    cover?: string;
}

export type IHistoryInfoList = Array<IHistoryInfo>

export abstract class DashObserver<T> extends EventEmitter {

    timer: NodeJS.Timeout;
    lastInfo: T;

    constructor(protected station: Station) {
        super();
        if (station.enabled)
            this.start();
    }

    abstract loadInfo();

    start() {
        this.timer = setInterval(() => {
            this.loadInfo();
        }, 15000);
        this.loadInfo();
    }

    onLoaded(callback: (info: T) => void) {
        this.on('loaded', callback);
        if (this.lastInfo) {
            callback(this.lastInfo);
        }
    }

    stop() {
        clearInterval(this.timer)
    }
}

export class StationObersver extends DashObserver<IPlaylist> {

    loadInfo() {
        API.loadSongInfo(this.station).then(info => {
            this.lastInfo = info;
            this.station.current_title = info.title;
            this.station.current_artist = info.artist;
            this.emit('loaded', info);
        }).catch(err => {
        })
    }

}

export class StationHistoryObersver extends DashObserver<IHistoryInfoList> {

    loadInfo() {
        API.loadHistoryInfo(this.station).then(info => {
            this.lastInfo = info;
            this.emit('loaded', info);
        }).catch(err => {

        })
    }
}

export class API {

    private static stationObersavations: { [station_id: number]: StationObersver } = {}
    private static stationHistoryObersavations: { [station_id: number]: StationHistoryObersver } = {}

    public static listStations(): Promise<IStationListResponse> {
        let apiRequest = new AuthenticatedRequest<IStationListResponse>('/stations', HTTPMethod.GET, APIVersion.v1_0);
        return apiRequest.send().then((data: APIResponse<IStationListResponse>) => {
            return {
                inactive: (data.data! as IStationListResponse).inactive,
                active: (data.data! as IStationListResponse).active,
                all: (data.data! as IStationListResponse).active.concat((data.data! as IStationListResponse).inactive)
            }
        })
    }

    public static getStation(id: number): Promise<Station> {
        let apiRequest = new AuthenticatedRequest<IStationListResponse>('/stations/' + id, HTTPMethod.GET, APIVersion.v1_0);
        return apiRequest.send().then((data) => {
            return data.data
        })
    }

    public static createStation(station: NewStation): Promise<Station> {
        let apiRequest = new AuthenticatedRequest<NewStation>('/stations', HTTPMethod.POST, APIVersion.v1_0, undefined, station);
        return apiRequest.send().then((response) => {
            return <Station>response.data;
        });
    }

    public static updateImage(id: number, type: ImageType, image: any): Promise<Station> {
        let apiRequest = new AuthenticatedFileRequest<Station>('/stations/' + id + '/image/' + type, HTTPMethod.POST, APIVersion.v1_0, undefined, {
            image: image
        });
        return apiRequest.send().then((response) => {
            return <Station>response.data;
        });
    }

    public static updateStation(station: Station): Promise<Station> {
        let apiRequest = new AuthenticatedRequest<Station>('/stations/' + station.id, HTTPMethod.PUT, APIVersion.v1_0, undefined, {
            station: station
        });
        return apiRequest.send().then((response) => {
            return <Station>response.data;
        });
    }

    public static updateDashlyticsStream(stream: string, station_id: number, since: Date): Promise<void> {
        let apiRequest = new AuthenticatedRequest<Station>('/stations/' + station_id + '/dashlytics', HTTPMethod.PUT, APIVersion.v1_0, undefined, {
            stream, since
        });
        return apiRequest.send().then((response) => {
            return void 0;
        });
    }

    public static createCoverArtSchedule(station: Station, data: Partial<CoverArtSchedule>, file): Promise<CoverArtSchedule> {
        let apiRequest = new AuthenticatedFileRequest<Station>('/stations/' + station.id + '/cover_art_schedule', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            schedule: data,
            image: file
        });
        return apiRequest.send().then((response) => {
            return <CoverArtSchedule>response.data;
        });
    }

    public static deleteCoverArtSchedule(station: Station, id: number): Promise<void> {
        let apiRequest = new AuthenticatedRequest<Station>('/stations/' + station.id + '/cover_art_schedule/' + id, HTTPMethod.DELETE, APIVersion.v1_0);
        return apiRequest.send().then((response) => {
            return void 0
        });
    }

    public static getCoverArtSchedule(station: Station): Promise<CoverArtSchedule[]> {
        let apiRequest = new AuthenticatedRequest<Station>('/stations/' + station.id + '/cover_art_schedule', HTTPMethod.GET, APIVersion.v1_0);
        return apiRequest.send().then((response) => {
            return <CoverArtSchedule[]>response.data;
        });
    }

    public static setAllStationsRecovery(recovery: boolean): Promise<void> {
        let apiRequest = new AuthenticatedRequest<void>('/stations/all/recovery', HTTPMethod.PUT, APIVersion.v1_0, {}, {
            recovery: recovery
        });
        return apiRequest.send().then((response) => {
            return void 0;
        });
    }

    public static updateCoverArtSchedule(station: Station, data: CoverArtSchedule, file?): Promise<CoverArtSchedule> {
        let apiRequest = new AuthenticatedFileRequest<Station>('/stations/' + station.id + '/cover_art_schedule/' + data.id, HTTPMethod.PUT, APIVersion.v1_0, undefined, {
            schedule: data,
            image: file
        });
        return apiRequest.send().then((response) => {
            return <CoverArtSchedule>response.data;
        });
    }

    public static loadSongInfo(station: Station): Promise<IPlaylist> {
        let request = new AuthenticatedRequest('/proxy/' + btoa(station.current_song_url), HTTPMethod.GET, APIVersion.v1_0)
        request.middleware = (data) => {
            return {
                data: data
            }
        }
        return request.send().then((data) => {
            let parser = new DOMParser();
            let xmlDoc = parser.parseFromString(data.data, "text/xml");
            let playlist = xmlDoc.getElementsByTagName('playlist')[0]
            if (!playlist) {
                throw new Error('No Playlist found')
            }
            return {
                artist: playlist.getElementsByTagName('artist')[0].textContent!,
                title: playlist.getElementsByTagName('title')[0].textContent!,
                cover: playlist.getElementsByTagName('cover')[0].textContent!,
                station_id: station.id
            }
        })
    }

    public static loadHistoryInfo(station: Station): Promise<IHistoryInfoList> {
        let request = new AuthenticatedRequest('/proxy/' + btoa(station.history_feed_url), HTTPMethod.GET, APIVersion.v1_0)
        request.middleware = (data) => {
            return {
                data: data
            }
        }
        return request.send().then((data) => {
            let parser = new DOMParser();
            let xmlDoc = parser.parseFromString(data.data, "text/xml");
            const items = new Array<IHistoryInfo>();
            const parent = xmlDoc.getElementsByTagName("playHistory")[0];
            for(let i = 0; i < parent.childElementCount; i++){
                const xml = parent.children[i];
                const title = xml.getElementsByTagName('title')[0]?.textContent!;
                const artist = xml.getElementsByTagName('artist')[0]?.textContent!;
                const cover = xml.getElementsByTagName('cover')[0]?.textContent!;
                items.push({
                    station_id: station.id,
                    description: artist,
                    cover: cover == "" ? undefined : cover,
                    title: title
                })
            }
            return items;
        })
    }

    public static observeSongInfo(station: Station): StationObersver {
        if (!this.stationObersavations[station.id]) {
            this.stationObersavations[station.id] = new StationObersver(station);
        }
        return this.stationObersavations[station.id];
    }

    public static observeHistory(station: Station): StationHistoryObersver {
        if (!this.stationHistoryObersavations[station.id]) {
            this.stationHistoryObersavations[station.id] = new StationHistoryObersver(station);
        }
        return this.stationHistoryObersavations[station.id];
    }

}
