import { IPropertyList } from "./property";

export interface NewHighlight {
    linked_station_id: string;
    linked_url: string;
    title: string;
    title_es: string;
    subtitle: string;
    subtitle_es: string;
    header_text: string;
    header_text_es: string;
    description: string;
    description_es: string;
    image_url: string;
    background_color: string;
    button_title: string;
    button_title_es: string;
    share_text: string;
    share_text_es: string;
    priority: number;
    position: number;
    start_date: Date;
    end_date: Date;
}
export interface Highlight extends NewHighlight {
    id: number;     //from api

    temp_sort: number;  //local
}

export function createNewHighlightModel(stationId?): NewHighlight {
    return {
        linked_station_id: stationId || "",
        linked_url: "",
        title: "",
        title_es: "",
        subtitle: "",
        subtitle_es: "",
        header_text: "",
        header_text_es: "",
        description: "",
        description_es: "",
        image_url: "",
        background_color: "",
        button_title: "",
        button_title_es: "",
        share_text: "",
        share_text_es: "",
        priority: -1,
        position: -1,
        start_date: new Date(),
        end_date: new Date()
    }
}

export type ImageType = 'image_url';

export function HighlightSort(item1: Highlight, item2: Highlight) {
    return item2.priority - item1.priority
}

export function HighlightTempSort(item1: Highlight, item2: Highlight) {
    return item2.temp_sort - item1.temp_sort
}

export function HighlightSortDate(item1: Highlight, item2: Highlight) {
    return new Date(item2.start_date).valueOf() - new Date(item1.start_date).valueOf()
}

export function propertyList(): IPropertyList {
    return {
        properties: [
            { name: 'id', readOnly: true, type: 'Number' },
            { name: 'linked_station_id', type: 'StationId' },
            { name: 'linked_url', type: 'URL' },
            { name: 'title', type: 'String' },
            { name: 'title_es', type: 'String' },
            { name: 'subtitle', type: 'String' },
            { name: 'subtitle_es', type: 'String' },
            { name: 'header_text', type: 'String' },
            { name: 'header_text_es', type: 'String' },
            { name: 'description', type: 'Text' },
            { name: 'description_es', type: 'Text' },
            { name: 'image_url', type: 'URL', hide: true },
            { name: 'background_color', type: 'String' },
            { name: 'button_title', type: 'String' },
            { name: 'button_title_es', type: 'String' },
            { name: 'share_text', type: 'Text' },
            { name: 'share_text_es', type: 'Text' },
            { name: 'priority', type: 'Number' },
            { name: 'position', type: 'Number' },
            { name: 'start_date', type: 'Date' },
            { name: 'end_date', type: 'Date' }
            //{ name: 'enabled', type: 'Boolean' }
        ]
    }
}