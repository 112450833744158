import * as React from "react";
import { Highlight } from "../../../api/models/highlights";
import './StationItem.css'
import {
    DragDropItemProps, DragDropItemBase, 
    dragSourceSpec, dragSourceCollector, dragTargetSpec, dragTargetCollector
} from './DragDropItem';
import { DropTarget, DragSource } from 'react-dnd';
import { formatDate } from "../../../utils/dates";
import GenericItem from "./GenericItem";

export interface HighlightItemProps extends DragDropItemProps<Highlight> {
    inactive?: boolean,
    refKey?: string
}

@DropTarget('HighlightItem', dragTargetSpec, dragTargetCollector)
@DragSource('HighlightItem', dragSourceSpec, dragSourceCollector)
export default class HighlightItem extends DragDropItemBase<Highlight, HighlightItemProps, {}> {

    constructor(props) {
        super(props);
        this.state = {
            //
        }
    }

    protected renderDragDropView(): JSX.Element {
        const highlight: Highlight = this.props.item;
        let detailLink = "/highlights/"+highlight.id;
        return (
            <GenericItem refDragDropView={this.refDragDropView}
                scrollKey={"highlight"+highlight.id}
                imageUrl={highlight.image_url}
                detailPath={detailLink}
                title={highlight.title}
                subtitle={highlight.subtitle+", "+formatDate(highlight.start_date)}
                description={highlight.description}
                refKey={this.props.refKey}
                searchOptions={this.props.searchOptions}
                inactive={this.props.inactive} />
        )
    }

}