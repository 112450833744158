import ParentItem from '../Highlights/parents/HighlightDetailItem'
import { createNewGenreModel, Genre, propertyList, ImageType } from "../../api/models/genres";
import { API } from "../../api/endpoints/genres";
import { getUrlParamsFromSearchOptions, addUrlParam } from 'src/utils/urlUtils';
import {withRouter} from "../../utils/withRouter";

class GenreDetailItem extends ParentItem<Genre, ImageType> {

    createNewHighlightModel(): any {
        return createNewGenreModel();
    }
    apiUpdateImage(id, file): Promise<Genre> {
        return API.updateImage(id, file);
    }
    apiUpdateHighlight(genre: Genre): Promise<Genre> {
        return API.updateGenre(genre);
    }
    apiCreateHighlight(genre: Genre): Promise<Genre> {
        return API.createGenre(genre);
    }

    getPropertyList() {
        return propertyList();
    }

    protected getImageInfos() {
        return {
            name: 'genre_image_url',
            size: '200 x 200',
            description: 'Genre Logo'
        }
    }

    getEditPagePath(genre: Genre | undefined) {
        return genre && '/genres/'+genre.id
    }

    getGoBackFallbackPath() {
        let urlParams = getUrlParamsFromSearchOptions(this.getSearchOptionsFromUrl());
        urlParams += addUrlParam(urlParams, "scroll", this.getScrollId(this.state && this.state.highlight));
        return '/genres'+urlParams;
    }

    protected getScrollId(genre?: Genre) {
        if(genre && genre.id) {
            return "genre"+genre.id;
        }
        return "genres";
    }

}
export default withRouter(GenreDetailItem);