import * as React from "react";
import { Container, Row, Col, Label } from "reactstrap";
import { IPropertyList, IPropertyType, IProperty } from "../../api/models/property";
import './PropertyContainer.css'
import icon_stop from '../../img/stop_icon.svg'
import icon_play from '../../img/play_icon.svg'
import ReactPlayer from 'react-player'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//var moment = require('moment');
import { DateBox, TextBox, DropDownBox, List } from 'devextreme-react'
import '../../css/dx.light.css'
import DevExpress from "devextreme/bundles/dx.all";
import { Station } from "../../api/models/stations";
import * as $ from 'jquery';
import { User } from "src/api/models";
import { isAdmin } from "src/api/models/user";
import LocationSearchInput from '../property_container/places_picker/LocationSearchInput'
import { PLACES_API_URL_ASYNC, PLACES_API_URL } from "src/const";
import Script from 'react-load-script'

const commonRowStyle = {
    'marginBottom': 0
}
const commonRowMiddleStyle = {
    'marginBottom': 0,
    'paddingTop': 0
}

export default class PropertyContainer extends React.Component<
    {
        edit: IPropertyList, object: any,
        stations?: Station[],
        currentUser?: User,
        onChange: (value: string | boolean | number, prop: IProperty) => void
    }, { currentPlayingUrl?: string, openDateEditors: String[] }> {

    constructor(props) {
        super(props);
        this.state = {
            openDateEditors: []
        }
    }

    private editorChanged(e, property: IProperty) {
        switch (property.type) {
            case 'StationId':
                this.props.onChange(e.id, property);
                break;
            case 'Location':
                //console.log("location: "+JSON.stringify(e.value));
                this.props.onChange(e.lat, {...property, name: 'location_latitude'});
                this.props.onChange(e.lng, {...property, name: 'location_longitude'});
                break;
            case 'Boolean':
                this.props.onChange(e.target.checked, property);
                break;
            case 'Date':
                //console.log("date: "+JSON.stringify(e.value));
                this.props.onChange(e.value, property);
                break;
            default:
                this.props.onChange(e.target.value, property);
        }

    }

    private sortIndexForEditor(property: IProperty) {
        switch (property.type) {
            case 'StationId':
            case 'Location':
            case 'Text':
                return 2
            case 'Boolean':
                return 1
            case 'Date':
                return 1;
            case 'String':
            default:
                return 1
        }
    }

    private editorNeedsRow(property: IProperty) {
        return property.type == 'Text' || property.type == 'URL' || property.type == 'SongURL';
    }

    private isStreamPlaying(property: IProperty) {
        return this.state.currentPlayingUrl == (this.props.object[property.name] + property.name)
    }
    private getStreamUrl(property: IProperty) {
        var url: string = this.props.object[property.name];
        if(url && url.startsWith('http://')) {
            url = url.replace('http://', 'https://')
        }
        let suffix = ".m3u";
        if(url && url.endsWith(suffix)) {
            url = url.substr(0, url.length-suffix.length)
        }
        return url
    }

    private toggleStream(property: IProperty) {
        this.setState({
            currentPlayingUrl:
                this.isStreamPlaying(property) ? undefined : this.props.object[property.name] + property.name
        })
    }

    private editor(property: IProperty): JSX.Element {
        switch (property.type) {
            case 'StationId':
                //console.log("rendered station id dropdown with id: "+this.props.object[property.name]);
                let selected = this.props.object[property.name];
                return (
                    <div className="property-dropdown ms-auto">
                        <DropDownBox width={"100%"}
                            readOnly={this.isReadonly(property)}
                            disabled={this.isReadonly(property)}
                            contentRender={e=>{
                                return (
                                    <List
                                        dataSource={this.props.stations || []}
                                        itemRender={(itemData)=>{
                                            return (
                                                <div>{itemData.id} <b>{itemData.name}</b></div>
                                            )
                                        }}
                                        selectionMode={'single'}
                                        selectedItem={e.value}
                                        onSelectionChanged={(args)=>{
                                            try {
                                                let selectedStation: Station = args.addedItems![0];
                                                //console.log("onSelectionChanged: "+JSON.stringify(selectedStation.name))

                                                //close dropdown box
                                                e.component!.option("value", selectedStation);
                                                e.component!.close();

                                                //fire change event
                                                this.editorChanged(selectedStation, property)
                                            }
                                            catch(err) {
                                                //
                                            }
                                        }}
                                    />
                                )
                            }}
                            value={selected}
                            valueExpr={"id"}
                            displayExpr={(item)=>{
                                return item && item.id + " (" + item.name + ")"
                            }}
                            placeholder={"Station Id..."}
                            mode={"text"}
                            dataSource={this.props.stations || []} />
                    </div>
                )
            case 'Location':
                return (
                    <div className="custom">
                    {
                        !this.isReadonly(property) && <Script url={PLACES_API_URL_ASYNC} />
                    }
                    {
                        !this.isReadonly(property) &&
                        <LocationSearchInput ref="loactionInput"
                            initValue={
                                {
                                    lat: this.props.object["location_latitude"],
                                    lng: this.props.object["location_longitude"]
                                }
                            }
                            onAddressChanged={(address, latlng) => {
                                //console.log("address: "+address+" latlng: "+JSON.stringify(latlng))
                                this.editorChanged(latlng, property)
                            }} />
                    }
                    </div>
                )
            case 'Text':
                return (
                    <textarea
                        id={'input-' + property.name}
                        onChange={(e) => { this.editorChanged(e, property) }}
                        readOnly={this.isReadonly(property)}
                        name={property.name} rows={3}
                        value={this.props.object[property.name]}
                        placeholder={this.humanPropertyName(property.name)} />
                )
            case 'Boolean':
                return <label className="switch">
                    <input id={'input-' + property.name}
                        onChange={(e) => { this.editorChanged(e, property) }}
                        disabled={this.isReadonly(property)}
                        readOnly={this.isReadonly(property)}
                        checked={this.props.object[property.name]}
                        type="checkbox" />
                    <span className="slider round"></span>
                </label>
            case 'Number':
                return <input
                    onChange={(e) => { this.editorChanged(e, property) }}
                    readOnly={this.isReadonly(property)}
                    id={'input-' + property.name}
                    type="number" step={1} name={property.name}
                    value={this.props.object[property.name] || ""}
                    placeholder={this.humanPropertyName(property.name)} />
            case 'SongURL':
                return <div className="input-icon-wrapper row-editor">
                    <input onChange={(e) => { this.editorChanged(e, property) }}
                        readOnly={this.isReadonly(property)}
                        id={'input-' + property.name}
                        name={property.name}
                        value={this.props.object[property.name] || ""}
                        placeholder={this.humanPropertyName(property.name)} />
                    {
                        this.isStreamPlaying(property) && <ReactPlayer url={this.getStreamUrl(property)} width={0} height={0} playing={true} />
                    }
                    {
                        (this.props.object[property.name]) != '' && <a onClick={(e) => {
                            this.toggleStream(property)
                        }} ><img src={this.isStreamPlaying(property) ? icon_stop : icon_play} alt="Stop Icon" /></a>
                    }
                </div>
            case 'Date':
                return (
                    <div className="property-date ms-auto" onClick={()=>{
                            var editor = property.name;
                            if(this.isDateEditorOpen(editor)) {
                                this.closeDateEditor(editor);
                            }
                            else if(!this.isReadonly(property)) {
                                this.openDateEditor(editor);
                            }
                        }}>
                        <DateBox
                            onValueChanged={(e) => { this.editorChanged(e, property) }}
                            value={this.props.object[property.name]}
                            opened={this.isDateEditorOpen(property.name)}
                            disabled={this.isReadonly(property)}
                            readOnly={this.isReadonly(property)}
                            dropDownButtonRender={()=><FontAwesomeIcon icon="calendar" />}
                            onOptionChanged={(e)=>{
                                //editor closed
                                if("opened"===e.name && e.value===false) {
                                    this.closeDateEditor(property.name);
                                }
                            }}
                            pickerType='calendar'
                            type='datetime' />
                    </div>
                )
            case 'URL':
                return (
                    <div className="input-icon-wrapper row-editor">
                        <input
                            onChange={(e) => { this.editorChanged(e, property) }}
                            readOnly={this.isReadonly(property)}
                            id={'input-' + property.name}
                            type="text" name={property.name}
                            value={this.props.object[property.name]}
                            placeholder={this.humanPropertyName(property.name)} />
                    </div>
                )
            case 'String':
            default:
                return <input
                    onChange={(e) => { this.editorChanged(e, property) }}
                    readOnly={this.isReadonly(property)}
                    id={'input-' + property.name}
                    type="text" name={property.name}
                    value={this.props.object[property.name]}
                    placeholder={this.humanPropertyName(property.name)} />
        }
    }

    private isReadonly(property: IProperty) {
        return property.readOnly == true || !isAdmin(this.props.currentUser)
    }

    private isDateEditorOpen(name): boolean {
        return this.state.openDateEditors.indexOf(name)>=0;
    }
    private openDateEditor(name) {
        let openEditors = this.state.openDateEditors || [];
        if(openEditors.indexOf(name)<0) {
            openEditors.push(name);
            this.setState({openDateEditors: openEditors});
        }
    }
    private closeDateEditor(name) {
        this.setState({
            openDateEditors: this.state.openDateEditors
                .filter(editor => editor!==name)
        })
    }

    private humanPropertyName(name: string) {
        let parts = name.split('_')
        parts = parts.map(p => p.charAt(0).toUpperCase() + p.substr(1))
        return parts.join(' ')
    }

    private getStationFromId(id): Station | undefined {
        if(id && this.props.stations && this.props.stations.length>0) {
            return this.props.stations.find(station=>station.id===id);
        }
        return undefined;
    }

    public render() {
        const editors = this.props.edit.properties.filter(p => !p.hide).sort((elem1, elem2) => {
            return this.sortIndexForEditor(elem1) - this.sortIndexForEditor(elem2)
        });
        const columnEditors = editors.filter(e => !this.editorNeedsRow(e)).reduce((resultArray: Array<Array<IProperty>>, item: IProperty, index) => {
            const chunkIndex = Math.floor(index / 3)
            if (!resultArray[chunkIndex] || this.editorNeedsRow(item)) {
                resultArray[chunkIndex] = new Array<IProperty>()
            }
            resultArray[chunkIndex].push(item)
            return resultArray
        }, [])
        const rowEditors = editors.filter(e => this.editorNeedsRow(e))

        return (
            <Container className="property-container">
                <Row style={commonRowStyle}>
                    {
                        columnEditors.map((propertylist, index) => {
                            return (
                                <Col lg="4" md="6" key={index}>
                                    {propertylist.map(property => {
                                        return (
                                            <div className="input-wrapper" key={property.name}>
                                                <Label for={'input-' + property.name}>{this.humanPropertyName(property.name)}</Label>
                                                {
                                                    this.editor(property)
                                                }
                                            </div>
                                        )
                                    })}
                                </Col>
                            )
                        })}
                </Row>
                {
                    rowEditors.map((property, index) => {
                        return (
                            <Row style={commonRowMiddleStyle} key={property.name}>
                                <Col key={'coleditors' + index}>
                                    <div className="input-wrapper" key={property.name}>
                                        <Label for={'input-' + property.name}>{this.humanPropertyName(property.name)}</Label>
                                        {
                                            this.editor(property)
                                        }
                                    </div>
                                </Col>
                            </Row>
                        )
                    })
                }
            </Container>
        )
    }
}
