import * as React from "react";
import * as ReactDOM from "react-dom";
import { Station, imageInfos, propertyList, ImageType, StationTempSort } from "../../api/models/stations";
import { Highlight, HighlightSortDate, HighlightTempSort } from "../../api/models/highlights";
import { Container, Row, Col, Button } from "reactstrap";
import './StationDetail.css'
import { API as UserApi } from "../../api/endpoints/user";
import { API, IPlaylist } from "../../api/endpoints/stations";
import { API as HighlightsApi } from "../../api/endpoints/highlights";
import notify from "../../ui/notify";
import upload from '../../img/upload_icon.svg'
import uploader from '../../img/oval_loader.svg'
import { Popover } from "devextreme-react";
import PropertyContainer from "../../components/property_container/PropertyContainer";
import * as $ from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StationHistory from "../../components/stations/history/StationHistory";
import { getAllStations } from './StationsHelper';
import { getAllHighlightsFromStation } from '../Highlights/HighlightsHelper';
import { HighlightBanner } from "../../api/models/highlight_banners";
import { getAllHighlightBannersFromStation } from '../HighlightBanners/HighlightBannersHelper';
import { History } from 'history'

import HighlightsSection from './HighlightsSection'
import HighlightBannersSection from './HighlightBannersSection'
import Collapse from "reactstrap/lib/Collapse";
import CoverArtScheduler from "./cover_art_scheduler/CoverArtScheduler";
import { User } from "src/api/models";
import { isAdmin } from "src/api/models/user";
import ContestsSection from "./contests/ContestsSection";
import { Contest } from "src/api/models/contest";
import { getAllContests } from './contests/ContestsHelper';
import { getParamsFromUrl, addUrlParam, getScrollHintFromUrl } from '../../utils/urlUtils';
import TagsEditor from './station_tags/TagsEditor';
import HighlightsScheduler from "./cover_art_scheduler/HighlightsScheduler";
import { AssociateStream } from "./dashlytics/AssociateStream";
import Loader from "../../components/loader/Loader";
import {withRouter} from "../../utils/withRouter";
import {Link} from "react-router-dom";

const hideStyle: React.CSSProperties = {
    display: 'none'
}

const PopoverEvent = 'mouseenter'
const PopoverLeaverEvent = 'mouseleave'

const P = {
    '1': ''
}

class StationDetail extends React.Component<
    { router: { params: { stationId: number }, history: History } },
    {
        currentUser?: User,
        station?: Station,
        allStations?: Station[],
        stationPlaylistInfo?: IPlaylist,
        imageLoadingStates: { [key: string]: boolean },
        isLoading: boolean,

        connectedHighlights?: Highlight[],
        connectedHighlightBanners?: HighlightBanner[],
        connectedContests?: Contest[],
        highlightsChanged: boolean,

        showHighlightsSection: boolean,
        showHighlightBannersSection: boolean,
        showContestsSection: boolean,
        showInactiveHighlights: boolean,
        showInactiveBanners: boolean,
        showInactiveContests: boolean
    }> {

    constructor(props) {
        super(props);
        let showHighlightsState: number = this.getStateParamFromUrl("showHighlightsSection");
        let showBannersState: number = this.getStateParamFromUrl("showHighlightBannersSection");
        let showContestsState: number = this.getStateParamFromUrl("showContestsSection");
        this.state = {
            highlightsChanged: false,
            imageLoadingStates: {},
            isLoading: false,
            showHighlightsSection: showHighlightsState > 0, //false,
            showHighlightBannersSection: showBannersState > 0, //false,
            showContestsSection: showContestsState > 0, //false
            showInactiveHighlights: showHighlightsState > 1,
            showInactiveBanners: showBannersState > 1,
            showInactiveContests: showContestsState > 1
        }
    }

    private getStateParamFromUrl(param): number {
        let params = getParamsFromUrl(this.props);
        return params && params[param] || 0;
    }

    private getCurrentStationIdFromUrl() {
        return this.props.router.params.stationId;
    }

    private mScrollToId?: string = undefined;
    componentDidMount() {
        this.mScrollToId = getScrollHintFromUrl(this.props);
        this.loadData();
    }

    componentWillUnmount() {
        //
    }

    private loadData() {
        UserApi.getMe().then(user => {
            this.setState({ currentUser: user })
        }).catch(err => { });

        API.getStation(this.getCurrentStationIdFromUrl()).then(station => {
            this.setState({
                station: station,
            }, () => {
                //load all stations for paging
                getAllStations((error, response) => {
                    if (response) {
                        this.setState({
                            allStations: response.all.filter(station => { return station.genre === this.state.station!.genre }).sort(StationTempSort)
                        })
                    }
                });

                //load highlights connected with this station
                this.loadHighlights();
                //load highlight banners connected with this station
                this.loadHighlightBanners();
                //load contests connected with this station
                this.loadContests();
            })
        }).catch(err => {
            if (err && err.message) {
                notify(err.message, 'error', 5000)
            }
        });
    }

    private loadHighlights() {
        //load highlights connected with this station
        getAllHighlightsFromStation(this.getCurrentStationIdFromUrl(), (error, response) => {
            if (response) {
                this.setState({
                    connectedHighlights: response.highlights
                })
            }
        });
    }
    private loadHighlightBanners() {
        //load highlight banners connected with this station
        getAllHighlightBannersFromStation(this.getCurrentStationIdFromUrl(), (error, response) => {
            if (response) {
                this.setState({
                    connectedHighlightBanners: response.highlights
                })
            }
        });
    }
    private loadContests() {
        //load highlight banners connected with this station
        getAllContests(this.getCurrentStationIdFromUrl(), (error, response) => {
            if (response) {
                this.setState({
                    connectedContests: response.contests
                })
            }
        });
    }

    private uploadFile(to: ImageType, fileProxy) {
        fileProxy.preventDefault();
        if (isAdmin(this.state.currentUser)) {
            let file = fileProxy.currentTarget.files || fileProxy.dataTransfer.files;
            if (file.length > 0) {
                file = file[0];
            }
            if (file) {
                var reader = new FileReader();
                const oldImage = this.state.station![to];
                const self = this;
                reader.onload = function (e: any) {
                    self.state.station![to] = e.target.result;
                    self.state.imageLoadingStates[to] = true
                    self.setState({
                        station: self.state.station!,
                        imageLoadingStates: self.state.imageLoadingStates
                    })
                }
                reader.readAsDataURL(file)

                API.updateImage(this.state.station!.id, to, file).then((station) => {
                    this.state.imageLoadingStates[to] = false
                    this.setState({
                        station: station,
                        imageLoadingStates: self.state.imageLoadingStates
                    })
                }).catch(err => {
                    notify(err.message, 'error', 5000)
                    this.state.station![to] = oldImage;
                    this.state.imageLoadingStates[to] = false
                    this.setState({
                        station: self.state.station!,
                        imageLoadingStates: self.state.imageLoadingStates
                    })
                })
            }
        }
    }

    private updateStation() {
        this.setState({
            isLoading: true
        })
        API.updateStation(this.state.station!).then((station) => {
            this.setState({
                station: station,
                isLoading: false
            })
        }).catch(err => {
            if (err && err.message) {
                notify(err.message, 'error', 5000)
            }
            this.setState({
                isLoading: false
            })
        })
    }

    public render() {
        if (!this.state.station) {
            return <Loader />
        }
        return (
            <Container className="station-detail dash-row">
                <Container>
                    <Row style={{ paddingTop: '18px', paddingBottom: '0px', backgroundColor: 'transparent' }}>
                        <Col>
                            <Link to={"/stations" + (this.state.station && "?scroll=station" + this.state.station.id)} className="back-btn">
                                <FontAwesomeIcon icon="chevron-left" />
                                Stations
                            </Link>
                        </Col>
                        {
                            this.getPagingPosition() && this.getPagingCount() &&        //show paging when ready
                            (
                                <Col xs={{ order: 'right' }} className="title-container">
                                    <Link reloadDocument to={"/stations/" + this.getPreviousPagingStationId()} className="page-btn">
                                        <FontAwesomeIcon icon="chevron-left" />
                                    </Link>
                                    <span>{this.getPagingPosition()}/{this.getPagingCount()}</span>
                                    <Link reloadDocument to={"/stations/" + this.getNextPagingStationId()} className="page-btn">
                                        <FontAwesomeIcon icon="chevron-right" />
                                    </Link>
                                </Col>
                            )
                        }
                    </Row>
                </Container>
                {this.state.isLoading && <Loader title="Updating..." />}
                <Row>
                    <StationHistory station={this.state.station} />
                </Row>
               <Row className="image-row img_tint">
                    {imageInfos.map((info, index) => {
                        const { name, size, description, no_tint } = info
                        return <Col key={index} className="col-upload">
                            <input
                                accept="image/*"
                                id={'in_' + (index + 1)}
                                value=""
                                type="file" style={hideStyle}
                                onChange={(e) => { this.uploadFile(name, e) }} />

                            <div
                                className="dotted-wrapper"
                                style={(!this.state.station![name]) ? { backgroundColor: 'white' } : {}}
                                onDragOver={(e => { e.preventDefault() })}
                                onDrop={(e) => { this.uploadFile(name, e) }}>

                                <a href="#" onClick={(e) => {
                                    if (isAdmin(this.state.currentUser)) {
                                        $('#in_' + (index + 1)).click()
                                    }
                                }}>

                                    <img id={'img_' + (index + 1)}
                                         className={
                                             (this.state.imageLoadingStates[name] ? 'img_opacity ' : ' ') +
                                             (this.state.station![name] && (!no_tint) ? ' img_tint ' : ' ') +
                                             (info.class ? info.class : '')
                                         }
                                         src={this.state.station![name] ? this.state.station![name] : upload}
                                         alt="Upload Icon" />

                                    {this.state.imageLoadingStates[name] && <img src={uploader} className="uploading_img" />}
                                </a>
                            </div>
                            <span>{size} Pixel</span>
                            <p>{description}</p>
                            <Popover target={'#img_' + (index + 1)}
                                     showEvent={PopoverEvent}
                                     hideEvent={PopoverLeaverEvent}
                                     width={600}>
                                <img width="100%"
                                     className="station-detail img_tint"
                                     src={this.state.station![name] ? this.state.station![name] : upload}
                                     alt="Upload Icon" />
                            </Popover>
                        </Col>
                    })}
                </Row>
                <Row>
                    <div style={{ 'float': 'right', width: '100%', marginRight: '15px' }}>
                        <Button
                            disabled={!isAdmin(this.state.currentUser)}
                            onClick={(e) => { this.updateStation() }}
                            className='btn-save'>Save</Button>
                    </div>
                    <PropertyContainer currentUser={this.state.currentUser}
                                       edit={propertyList()} object={this.state.station!} onChange={
                        (value, property) => {
                            this.state.station![property.name] = value;
                            this.setState({
                                station: this.state.station
                            })
                        }
                    } />
                </Row>
                <Row>
                    <AssociateStream station={this.state.station!} />
                </Row>
                    <Row>
                        <TagsEditor
                            station={this.state.station}
                            enabled={isAdmin(this.state.currentUser) || false} />
                    </Row>
                <Row>
                    <CoverArtScheduler
                        station={this.state.station}
                        renderPopover={(url, target) => {
                            return (
                                <Popover target={target}
                                         showEvent={PopoverEvent}
                                         hideEvent={PopoverLeaverEvent}
                                         width={200}>
                                    <img width="100%"
                                         className="station-detail img_tint"
                                         src={url}
                                         alt="Upload Icon" />
                                </Popover>
                            )
                        }} />
            </Row>
                <Row>
                    <HighlightsScheduler
                        station={this.state.station}
                        renderPopover={(url, target) => {
                            return (
                                <Popover target={target}
                                         showEvent={PopoverEvent}
                                         hideEvent={PopoverLeaverEvent}
                                         width={200}>
                                    <img width="100%"
                                         className="station-detail img_tint"
                                         src={url}
                                         alt="Upload Icon" />
                                </Popover>
                            )
                        }} />
                </Row>
                <Row>
                    <Container fluid id="highlights">
                        <Row>
                            <Col className="title-container station-item">
                                {
                                    isAdmin(this.state.currentUser) &&
                                    <a href={"/highlights/create" + this.getSearchOptionsWithStationRef()} style={{ padding: 10, marginBottom: 20 }} className="buttonstations page-scroll">Add Highlight (App)</a>
                                }
                            </Col>
                            <Col xs={{ order: 'right' }} style={{ padding: 20 }}>
                                <Button onClick={(e) => this.setState({ showHighlightsSection: !this.state.showHighlightsSection }, this.refreshUrlParams)}>{this.state.showHighlightsSection ? 'Hide Highlights' : 'Show Highlights'}</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Collapse style={{ flex: 1 }} isOpen={this.state.showHighlightsSection}>
                                <HighlightsSection
                                    onExpanded={(which, expanded) => { if (which === 1) { this.setState({ showInactiveHighlights: expanded }, this.refreshUrlParams) } }}
                                    expandInactive={this.state.showInactiveHighlights}
                                    highlights={this.state.connectedHighlights || []}
                                    refKey={"station" + this.getCurrentStationIdFromUrl()}
                                    searchOptions={() => this.buildUrlSearchOptions()} />
                            </Collapse>
                        </Row>
                    </Container>
                </Row>
                <Row>
                    <Container fluid id="highlight-banners">
                        <Row>
                            <Col className="title-container station-item">
                                {
                                    isAdmin(this.state.currentUser) &&
                                    <a href={"/highlight-banners/create" + this.getSearchOptionsWithStationRef()} style={{ padding: 10, marginBottom: 20 }} className="buttonstations page-scroll">Add Banner (Web)</a>
                                }
                            </Col>
                            <Col xs={{ order: 'right' }} style={{ padding: 20 }}>
                                <Button onClick={(e) => this.setState({ showHighlightBannersSection: !this.state.showHighlightBannersSection }, this.refreshUrlParams)}>{this.state.showHighlightBannersSection ? 'Hide Banners' : 'Show Banners'}</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Collapse style={{ flex: 1 }} isOpen={this.state.showHighlightBannersSection}>
                                <HighlightBannersSection
                                    onExpanded={(which, expanded) => { if (which === 1) { this.setState({ showInactiveBanners: expanded }, this.refreshUrlParams) } }}
                                    expandInactive={this.state.showInactiveBanners}
                                    highlights={this.state.connectedHighlightBanners || []}
                                    refKey={"station" + this.getCurrentStationIdFromUrl()}
                                    searchOptions={() => this.buildUrlSearchOptions()} />
                            </Collapse>
                        </Row>
                    </Container>
                </Row>
                <Row>
                    <Container fluid id="contests">
                        <Row>
                            <Col className="title-container station-item">
                                {
                                    isAdmin(this.state.currentUser) &&
                                    <a href={"/stations/" + this.getCurrentStationIdFromUrl() + "/contest/create" + this.buildUrlSearchOptions()} style={{ padding: 10, marginBottom: 20 }} className="buttonstations page-scroll">Add Contest (App)</a>
                                }
                            </Col>
                            <Col xs={{ order: 'right' }} style={{ padding: 20 }}>
                                <Button onClick={(e) => { this.setState({ showContestsSection: !this.state.showContestsSection }, this.refreshUrlParams) }}>{this.state.showContestsSection ? 'Hide Contests' : 'Show Contests'}</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Collapse style={{ flex: 1 }} isOpen={this.state.showContestsSection}>
                                <ContestsSection
                                    onExpanded={(which, expanded) => { if (which === 1) { this.setState({ showInactiveContests: expanded }, this.refreshUrlParams) } }}
                                    expandInactive={this.state.showInactiveContests}
                                    contests={this.state.connectedContests || []}
                                    searchOptions={() => this.buildUrlSearchOptions()} />
                            </Collapse>
                        </Row>
                    </Container>
                </Row>
            </Container>
        )
    }

    componentDidUpdate() {
        if (this.mScrollToId) {
            window.requestAnimationFrame(() => {
                var element = document.getElementById(this.mScrollToId!);
                if (element) {
                    element.scrollIntoView({ block: 'center', behavior: ('instant' as any) });   //scroll to element
                    this.refreshUrlParams();    //refresh url (removes scroll parameter)
                }
            })
        }
    }

    private buildUrlSearchOptions(): string {
        var search = "";
        if (this.state) {
            if (this.state.showHighlightsSection === true) {
                search += addUrlParam(search, "showHighlightsSection", (this.state.showHighlightsSection ? (this.state.showInactiveHighlights === true ? 2 : 1) : 0));
            }
            if (this.state.showHighlightBannersSection === true) {
                search += addUrlParam(search, "showHighlightBannersSection", (this.state.showHighlightBannersSection ? (this.state.showInactiveBanners === true ? 2 : 1) : 0));
            }
            if (this.state.showContestsSection === true) {
                search += addUrlParam(search, "showContestsSection", (this.state.showContestsSection ? (this.state.showInactiveContests === true ? 2 : 1) : 0));
            }
        }
        return search;
    }
    private refreshUrlParams() {
        var url = "/stations/" + this.getCurrentStationIdFromUrl();
        var search = this.buildUrlSearchOptions();
        window.history.replaceState(this.state, window.document.title, url + search);
    }
    private getSearchOptionsWithStationRef() {
        var urlParams = this.buildUrlSearchOptions();
        urlParams += addUrlParam(urlParams, "ref", "station" + this.getCurrentStationIdFromUrl());
        return urlParams;
    }

    private getPagingPosition() {
        if (this.state.allStations) {
            return 1 + this.state.allStations!.findIndex(station => {
                if (this.state.station) {
                    return station.id === this.state.station!.id;
                }
                return false;
            });
        }
        return undefined;
    }
    private getPagingCount() {
        if (this.state.allStations) {
            return this.state.allStations!.length;
        }
        return undefined;
    }

    private getNextPagingStationId() {
        if (this.getPagingPosition() && this.getPagingCount()) {
            var nextPos = this.getPagingPosition()! % this.getPagingCount()!;   //paging position == index +1
            if (this.state.allStations && this.state.allStations.length > nextPos) {
                return this.state.allStations[nextPos].id;
            }
        }
        return this.getCurrentStationIdFromUrl();
    }
    private getPreviousPagingStationId() {
        if (this.getPagingPosition() && this.getPagingCount()) {
            var previousPos = this.getPagingPosition()! - 2;   //paging position == index +1
            if (previousPos < 0) {
                previousPos = previousPos + this.getPagingCount()!;
            }
            if (previousPos >= 0 && this.state.allStations && this.state.allStations.length > previousPos) {
                return this.state.allStations[previousPos].id;
            }
        }
        return this.getCurrentStationIdFromUrl();
    }
}
export default withRouter(StationDetail);
