import { AuthenticatedRequest, HTTPMethod, APIVersion, AuthenticatedFileRequest, APIResponse } from "../request/request";
import { Genre, NewGenreItem } from "../models/genres";

export interface IGenresListResponse {
    genres: Genre[]
}
export interface IGenreResponse {
    genre: Genre
}

export class API {

    public static listGenres(): Promise<IGenresListResponse> {
        let apiRequest = new AuthenticatedRequest<IGenresListResponse>('/genres', HTTPMethod.GET, APIVersion.v1_0);
        return apiRequest.send().then((data) => {
            return data.data
        })
    }

    public static getGenreById(genreId: number): Promise<IGenreResponse> {
        let apiRequest = new AuthenticatedRequest<IGenreResponse>('/genres/'+genreId, HTTPMethod.GET, APIVersion.v1_0);
        return apiRequest.send().then((data) => {
            return data.data
        })
    }

    public static updateImage(genreId: number, image: any): Promise<Genre> {
        let apiRequest = new AuthenticatedFileRequest<Genre>('/genres/'+genreId+'/image', HTTPMethod.PUT, APIVersion.v1_0, undefined, {
            image: image
        });
        return apiRequest.send().then((response) => {
            return <Genre>response.data;
        });
    }

    public static updateGenre(genre: Genre): Promise<Genre> {
        let apiRequest = new AuthenticatedRequest<Genre>('/genres/'+genre.id, HTTPMethod.PUT, APIVersion.v1_0, undefined, {
            genre: genre
        });
        return apiRequest.send().then((response) => {
            return <Genre>response.data;
        });
    }

    public static createGenre(genre: NewGenreItem): Promise<Genre> {
        let apiRequest = new AuthenticatedRequest<NewGenreItem>('/genres', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            genre: genre
        });
        return apiRequest.send().then((response) => {
            return <Genre>response.data;
        });
    }

    public static deleteGenreById(genreId: number): Promise<void> {
        let apiRequest = new AuthenticatedRequest<IGenreResponse>('/genres/'+genreId, HTTPMethod.DELETE, APIVersion.v1_0);
        return apiRequest.send().then((data) => {
            return data.data
        })
    }
}