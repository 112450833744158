import { AuthenticatedRequest, HTTPMethod, APIVersion, AuthenticatedFileRequest, APIResponse } from "../request/request";
import { Contest, NewContestItem } from "../models/contest";

export interface IContestsListResponse {
    contests: Contest[]
}
export interface IContestResponse {
    contest: Contest
}

export class API {

    public static listContests(stationId: number): Promise<IContestsListResponse> {
        let apiRequest = new AuthenticatedRequest<IContestsListResponse>('/stations/'+stationId+'/contests/all', HTTPMethod.GET, APIVersion.v1_0);
        return apiRequest.send().then((data) => {
            return data.data
        })
    }

    public static getContestById(stationId: number, contestId: number): Promise<IContestResponse> {
        let apiRequest = new AuthenticatedRequest<IContestResponse>('/stations/'+stationId+'/contest/'+contestId, HTTPMethod.GET, APIVersion.v1_0);
        return apiRequest.send().then((data) => {
            return data.data
        })
    }

    public static updateImage(stationId: number, contestId: number, image: any): Promise<Contest> {
        let apiRequest = new AuthenticatedFileRequest<Contest>('/stations/'+stationId+'/contest/'+contestId+'/image', HTTPMethod.PUT, APIVersion.v1_0, undefined, {
            image: image
        });
        return apiRequest.send().then((response) => {
            return <Contest>response.data;
        });
    }

    public static updateContest(contest: Contest): Promise<Contest> {
        let apiRequest = new AuthenticatedRequest<Contest>('/stations/'+contest.station_id+'/contest/'+contest.id, HTTPMethod.PUT, APIVersion.v1_0, undefined, {
            contest: contest
        });
        return apiRequest.send().then((response) => {
            return <Contest>response.data;
        });
    }

    public static createContest(contest: NewContestItem): Promise<Contest> {
        let apiRequest = new AuthenticatedRequest<NewContestItem>('/stations/'+contest.station_id+'/contest', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            contest: contest
        });
        return apiRequest.send().then((response) => {
            return <Contest>response.data;
        });
    }

    public static deleteContestById(stationId: number, contestId: number): Promise<void> {
        let apiRequest = new AuthenticatedRequest<IContestResponse>('/stations/'+stationId+'/contest/'+contestId, HTTPMethod.DELETE, APIVersion.v1_0);
        return apiRequest.send().then((data) => {
            return data.data
        })
    }
}