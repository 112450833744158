import * as React from "react";
import { Genre } from "../../api/models/genres";
import { API as GenresApi } from "../../api/endpoints/genres";
import { getGenreById } from './GenresHelper';
import GenreDetailItem from "./GenreDetailItem";
import ParentItem from '../Highlights/parents/HighlightsDetail';
import { isAdmin } from "src/api/models/user";
import { Button } from 'reactstrap';
import notify from "src/ui/notify";
import {withRouter} from "../../utils/withRouter";

class GenreDetail extends ParentItem<Genre> {

    getCurrentHighlightIdFromUrl() {
        return (this.props.router.params as any).genreId;
    }

    getHighlightById(id): Promise<Genre> {
        return new Promise<Genre>((resolve, reject) =>  {
            getGenreById(id, (error, response) => {
                if (!error && response) {
                    resolve(response);
                }
                else if (reject) reject(error);
            });
        });
    }

    renderHighlightDetailItem(history, genre: Genre) {
        return (
            <GenreDetailItem history={history} highlight={genre}>
            {
                isAdmin(this.state.currentUser) &&
                <Button
                    style={{marginRight: 10}}
                    onClick={(e) => { 
                        if(confirm("Delete this item - Are You sure?")){
                            GenresApi.deleteGenreById(this.getCurrentHighlightIdFromUrl()).then(()=>{
                                notify("Successfully deleted genre", "success", 1000);
                                this.props.history.push("/genres");
                            })
                            .catch(err=>{
                                console.log("err: "+JSON.stringify(err));
                                if(err && err.message) {
                                    notify(err.message, "error");
                                }
                            })
                        } 
                    }}
                    disabled={!isAdmin(this.state.currentUser)}
                    className='btn-save'>Delete</Button>
            }
            </GenreDetailItem>
        )
    }

}
export default withRouter(GenreDetail);