import * as React from "react";
import { Contest } from "../../../api/models/contest";
import { getContestById as getById } from './ContestsHelper';
import ContestDetailItem from "./ContestDetailItem";
import ParentItem from './ContestDetailParent';
import { isAdmin } from "src/api/models/user";
import { API as ContestApi } from "src/api/endpoints/contests";
import { Button } from 'reactstrap';
import notify from "src/ui/notify";
import { getUrlParamsFromSearchOptions, getParamsFromUrl, addUrlParamBase } from "src/utils/urlUtils";
import {withRouter} from "../../../utils/withRouter";

class ContestDetail extends ParentItem<Contest> {

    getContestById(stationId, contestId): Promise<Contest> {
        return new Promise<Contest>((resolve, reject) => {
            getById(stationId, contestId, (error, response) => {
                if (!error && response) {
                    resolve(response);
                }
                else if (reject) reject(error);
            });
        });
    }

    renderContestDetailItem(navigate, contest: Contest) {
        return (
            <ContestDetailItem highlight={contest}>
                {
                    isAdmin(this.state.currentUser) &&
                    <Button
                        style={{marginRight: 10}}
                        onClick={(e) => { 
                            if(confirm("Delete this item - Are You sure?")){
                                ContestApi.deleteContestById(this.getCurrentStationIdFromUrl(), this.getCurrentContestIdFromUrl()).then(()=>{
                                    notify("Successfully deleted contest", "success", 1000);
                                    navigate("/stations/"+this.getCurrentStationIdFromUrl()+"?showContestsSection=1");
                                })
                                .catch(err=>{
                                    //console.log("err: "+JSON.stringify(err));
                                    if(err && err.message) {
                                        notify(err.message, "error");
                                    }
                                })
                            } 
                        }}
                        disabled={!isAdmin(this.state.currentUser)}
                        className='btn-save'>Delete</Button>
                }
                <Button
                    style={{marginRight: 10}}
                    onClick={(e) => {
                        //get search options
                        let urlParams = getUrlParamsFromSearchOptions(getParamsFromUrl(this.props));
                        urlParams += addUrlParamBase(urlParams) + "ref=contest"+this.getCurrentContestIdFromUrl();
                        navigate("/stations/"+this.getCurrentStationIdFromUrl()+"/contest/"+this.getCurrentContestIdFromUrl()+"/entries" + urlParams);
                    }}
                    className='btn-save'>Entries</Button>
            </ContestDetailItem>
        );
    }

}
export default withRouter(ContestDetail);