import * as React from "react";
import notify from "../../../ui/notify";
import Loader from "../../../components/loader/Loader";
import { History } from 'history'
import { API as UserApi } from "src/api/endpoints/user";
import { User } from "src/api/models";
import {Navigate} from "react-router-dom";

export default abstract class HighlightsDetailGeneric<Highlight> extends React.Component<
    { 
        router: { params: { highlightId: number } },
        history: History
    },
    {
        highlight?: Highlight,
        currentUser?: User,
        isLoading: boolean
    }> {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        }
    }

    protected getCurrentHighlightIdFromUrl() {
        return this.props.router.params.highlightId;
    }

    abstract getHighlightById(id): Promise<Highlight>;
    abstract renderHighlightDetailItem(history: History, highlight: Highlight): JSX.Element;

    componentDidMount() { 
        UserApi.getMe().then(user=>{
            this.setState({currentUser: user})
        }).catch(err=>{});

        this.getHighlightById(this.getCurrentHighlightIdFromUrl()).then(highlight => {
            //console.log("highlight: "+JSON.stringify(highlight));
            this.setState({
                highlight: highlight,
                isLoading: false
            })
        }).catch(err => {
            notify(err.message, 'error', 5000)
            this.setState({
                isLoading: false
            })
        });
    }

    public render() {
        if (this.state.isLoading) {
            return <Loader />
        }
        else if(this.state.highlight) {
            return this.renderHighlightDetailItem(this.props.history, this.state.highlight);
        }
        else return (
            <Navigate to="/stations" />
        )
    }
}