import { Environment } from "../../env";

/**
 * The currenly used api url
 * @type {string}
 */
let apiURL: string = Environment.API

/**
 * This encapsulates the current session information
 */
export class Session {
    public static unauthorizedHandler?: (error: any) => void;

    private static _store: {
        removeItem: (key: string) => void
    };
    private static cachedSession: { session_token: string };

    /**
     * This sets the api url to use
     * @param {string} url
     */
    static setAPIURL(url: string) {
        apiURL = url;
    }

    /**
     * This returns the currently used api url
     * @returns {string}
     */
    static getAPIURL(): string {
        return apiURL;
    }

    /**
     * This retreives the current session data
     * @returns {string | undefined}
     */
    static getSession(): string | undefined {
        return this.cachedSession ? this.cachedSession.session_token : undefined;
    }

    /**
     * This sets the current session data
     * @param {any | string | undefined} session
     */
    static setSession(session: any | string | undefined) {
        if (session == undefined) {
            if (this._store) this._store.removeItem('dash_user')
            return;
        }
        this.cachedSession = typeof session === 'object' ? session : { 'session_token': session }
        this.store()
    }

    static store() {
        if (this._store) this._store['dash_user'] = this.cachedSession.session_token;
    }

    static load() {
        if (this._store && this._store['dash_user']) {
            this.setSession(this._store['dash_user']);
        }
    }

    static setStore(store: any) {
        this._store = store;
    }
}