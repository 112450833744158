import * as React from "react";
import { IHighlightBannersListResponse, API as HighlightsApi } from "../../api/endpoints/highlight_banners";
import { HighlightBanner, HighlightBannerTempSort } from "../../api/models/highlight_banners";
import { getAllHighlightBanners, filterHighlightBanners, getActiveHighlightBanners, getInactiveHighlightBanners, isHighlightBannerActive } from './HighlightBannersHelper';
import HighlightBannerItem from "../../components/stations/section/HighlightBannerItem";
import ParentItem from '../Highlights/parents/Highlights'
import {withRouter} from "../../utils/withRouter";

class Highlights extends ParentItem<HighlightBanner> {

    getAllHighlights(callback) {
        return getAllHighlightBanners(callback);
    }

    isHighlightActive(highlight: HighlightBanner) {
        return isHighlightBannerActive(highlight);
    }

    getActiveHighlights(highlights: HighlightBanner[]) {
        return getActiveHighlightBanners(highlights)
    }
    getInactiveHighlights(highlights: HighlightBanner[]) {
        return getInactiveHighlightBanners(highlights);
    }

    getHighlightTempSort() {
        return HighlightBannerTempSort;
    }

    filterHighlights(highlights: HighlightBanner[], query) {
        return filterHighlightBanners({highlights: highlights || []}, query);
    }
    apiUpdateHighlight(highlight: HighlightBanner) {
        return HighlightsApi.updateHighlightBanner(highlight);
    }

    getCurrentUrlPath() {
        return "/highlight-banners"
    }
    
    renderHighlightItem(highlight: HighlightBanner, active: boolean, searchOptions: ()=>string, moveItem, canMove, didCommitDrop): JSX.Element {
        return (
            <HighlightBannerItem
                searchOptions={searchOptions}
                item={highlight}
                key={highlight.id}
                inactive={!active}
                isSameItem={(highlight1, highlight2)=>{
                    return highlight1.id===highlight2.id;
                }}
                moveItem={moveItem.bind(this)}
                canDrop={canMove.bind(this)}
                didCommitDrop={didCommitDrop.bind(this)} />
        )
    }

}
export default withRouter(Highlights);