import { User } from "../models";
import { APIError, APIVersion, AuthenticatedRequest, BasicRequest, HTTPMethod } from "../request/request";
import { APIErrorType } from "../errors";

export class LoginUserError extends Error {
    public type: LoginUserErrorType;
    public underlyingError: APIError;

    constructor(type: LoginUserErrorType, underlyingError: APIError) {
        super('User Login Error');
        this.type = type;
        this.underlyingError = underlyingError;
    }
}

export enum LoginUserErrorType {
    invalidLogin,
    userNotFound,
    unknownError
}

export class API {

    /**
     * Login the user
     * @param data
     * @param {string} password
     * @returns {Promise<User>} The Promise contains either a user or a LoginUserError if rejected
     */
    public static login(email: string, password: string): Promise<User> {
        let apiRequest = new BasicRequest<User>('/login', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            email: email,
            password: password
        });

        return apiRequest.send().then((response) => {
            return <User>response.data
        }).catch((error: APIError) => {
            switch (error.type) {
                case APIErrorType.InvalidParameters:
                    throw new LoginUserError(LoginUserErrorType.invalidLogin, error);
                case APIErrorType.InvalidLogin:
                    throw new LoginUserError(LoginUserErrorType.invalidLogin, error);
                case APIErrorType.ResourceNotFound:
                    throw new LoginUserError(LoginUserErrorType.userNotFound, error);
                default:
                    throw new LoginUserError(LoginUserErrorType.unknownError, error);
            }
        })
    }

    /**
     * Triggers sending a password reset email to the customer
     * @returns {Promise<void>} The Promise contains an APIError if rejected
     * @param data
     */
    public static forgotPassword(data: { email: string } | { phone_number: string }): Promise<void> {
        let apiRequest = new BasicRequest<User>('/forgotpassword', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            data: data
        });

        return apiRequest.send().then((response) => {
            return void 0
        })
    }

    /**
     * Resets the users password to the new password
     * @param {string} code
     * @param {string} password
     * @returns {Promise<User>} The Promise contains an APIError if rejected
     */
    public static resetPassword(code: string, password: string): Promise<User> {
        let apiRequest = new BasicRequest<User>('/resetpassword', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            code: code,
            password: password
        });

        return apiRequest.send().then((response) => {
            return <User>response.data
        })
    }

    /**
     * Changes the users password to new_password
     * @param {string} old_password
     * @param {string} new_password
     * @returns {Promise<void>} The Promise contains an APIError if rejected
     */
    public static changePassword(old_password: string, new_password: string): Promise<void> {
        let apiRequest = new AuthenticatedRequest<User>('/changepassword', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            old_password: old_password,
            password: new_password
        });

        return apiRequest.send().then(() => {
            return void 0
        })
    }
}
