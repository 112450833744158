import * as React from "react";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from 'react-dnd-html5-backend';
import { Row, Col, Container } from "reactstrap";
import './StationSection.css'
import { Station, StationTempSort, StationSort } from "../../../api/models/stations";
import StationItem from "./StationItem";
import { API } from "../../../api/endpoints/stations";
import notify from "../../../ui/notify";
import Loader from "../../loader/Loader";
import Button from "reactstrap/lib/Button";
import { isAdmin, User } from "src/api/models/user";

interface GenreSectionProps {
    dataSource: Station[],
    genre: string,
    isSearchData: boolean,
    currentUser?: User
}
interface GenreSectionStates {
    dataSource: Station[],
    isSearchData: boolean,
    isLoading: boolean,
    genresChanged: string[]
    featuredChanged: boolean,
}

export default class GenreSection extends React.Component<GenreSectionProps, GenreSectionStates> {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: this.props.dataSource,
            isSearchData: this.props.isSearchData,
            isLoading: false,
            featuredChanged: false,
            genresChanged: []
        }
    }

    componentDidUpdate(prevProps: Readonly<GenreSectionProps>, prevState: Readonly<GenreSectionStates>, snapshot?: any) {
        this.setState({
            dataSource: this.props.dataSource,
            isSearchData: this.props.isSearchData
        })
    }


    private resortStationsInGenre(source: Station, target: Station, direction) {
        let stations = this.state.dataSource.splice(0).sort(StationTempSort)
        let destinationIndex = stations.findIndex(s => s.id == target.id);
        stations = stations.filter(s => s.id != source.id);
        stations.splice(destinationIndex, 0, source)
        stations.reverse().forEach((station, index) => {
            station.temp_genre_sort = (index + 1) * 10
        })
        stations.sort(StationTempSort);
        this.setState({
            dataSource: stations
        })
    }

    public render() {
        return (
            <DndProvider backend={HTML5Backend}>
                <Container fluid>
                    <div key={this.props.genre}>
                        <Row>
                            <Col>
                                <h3>{this.props.genre}{this.state.genresChanged.indexOf(this.props.genre)>=0 && "*"}</h3>
                            </Col>
                            <Col xs={{order: 'right'}} className="title-container">
                                {
                                    (this.state.genresChanged.indexOf(this.props.genre)>=0 || this.state.featuredChanged) &&
                                    <Button onClick={()=>this.saveChangedSection(this.props.genre)} className="button">Save</Button>
                                }
                            </Col>
                        </Row>
                        <Row style={{position: 'relative'}}>
                            {
                                this.state.isLoading && <Loader title="Updating..." />
                            }
                            {
                                this.state.isLoading &&
                                (
                                    <div className="overlay-fluid"></div>
                                )
                            }
                            {this.state.dataSource.map(station => {
                                return (
                                    <StationItem
                                        item={station}
                                        key={station.genre + ":" + station.id}
                                        isSameItem={(station1, station2)=>{
                                            return station1.id===station2.id;
                                        }}
                                        moveItem={this.moveStation.bind(this)}
                                        canDrop={this.canMoveStation.bind(this)}
                                        didCommitDrop={this.commitDrop.bind(this)} />
                                )
                            })}
                        </Row>
                    </div>
                </Container>
            </DndProvider>
        )
    }

    private commitDrop(sourceStation: Station) {
        //
    }

    private saveChangedSection(sectionGenre: string) {
        this.setState({
            isLoading: true
        })
        Promise.all(this.state.dataSource.filter(s => {
            return s.genre == sectionGenre || sectionGenre === "Featured";
        }).map(s => {
            if(this.props.genre === "Featured"){
                s.featured_priority = 500 - s.temp_genre_sort;
            } else
                s.genre_priority = s.temp_genre_sort;
            return API.updateStation(s)     //TODO
        })).then(() => {
            this.setState({
                isLoading: false,
                genresChanged: this.state.genresChanged.filter(genre => {
                    return genre!==sectionGenre;
                })
            })
        }).catch(err => {
            notify(err.message, 'error', 5000)
            this.setState({
                isLoading: false
            })
        })
    }

    private canMoveStation(source: Station, target: Station) {
        return isAdmin(this.props.currentUser) && !this.state.isSearchData && (source.genre === target.genre || this.props.genre === "Featured");
    }

    private moveStation(source: Station, target: Station, direction) {
        this.resortStationsInGenre(source, target, direction)

        if(this.props.genre === "Featured") {
            this.setState({featuredChanged: true});
        } else {
            //set changed
            const genre = source.genre;
            const changed: string[] = this.state.genresChanged;
            if(changed.indexOf(genre)<0) {
                changed.push(genre);
                this.setState({
                    genresChanged: changed
                })
            }
        }
    }
}
