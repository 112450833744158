export enum APIErrorType {
    InvalidParameters = "InvalidParametersError",
    AlreadyExists = "ResourceAlreadyExists",
    InvalidGoogleToken = "InvalidGoogleToken",
    InvalidFacebookToken = "InvalidFacebookToken",
    InvalidLogin = "InvalidLoginError",
    ResourceNotFound = "ResourceNotFound",
    NotAccessibleError = "NotAccessibleError",
    MissingAuthorizationError = 'MissingAuthorizationError',
    SessionNotFoundError = 'SessionNotFoundError',
    NotVerifiedError = "NotVerifiedError",
    UnknownError = "UnknownError",
    NetworkError = "NetworkError",
    PaymentProviderError = "PaymentProviderError",
    PaymentInfoMissingError = "PaymentInfoMissingError"
}
