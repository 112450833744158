import * as React from "react";
import { IPlaylist, API, StationObersver, StationHistoryObersver, IHistoryInfo, IHistoryInfoList } from "../../../api/endpoints/stations";
import { Station } from "../../../api/models/stations";

export default class StationObserver extends React.Component<{
    station: Station,
    onLoaded: (info: IPlaylist) => void,
    includeHistory?: boolean,
    onHistoryLoaded?: (info: IHistoryInfoList) => void,
    children?: React.ReactNode,
}> {

    observer: StationObersver;
    historyObserver?: StationHistoryObersver;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.observer = API.observeSongInfo(this.props.station)
        this.observer.on('loaded', this.props.onLoaded);
        if (this.props.includeHistory == true || this.props.onHistoryLoaded) {
            this.historyObserver = API.observeHistory(this.props.station);
            this.props.onHistoryLoaded && this.historyObserver.on('loaded', this.props.onHistoryLoaded)
        }
    }

    componentWillUnmount() {
        this.observer.removeListener('loaded', this.props.onLoaded);
        this.historyObserver && this.props.onHistoryLoaded && this.historyObserver.removeListener('loaded', this.props.onHistoryLoaded);
    }

    public render() {
        return (this.props.children)
    }
}