import * as React from "react";
import Loader from "../../loader/Loader";
import { Station } from "../../../api/models/stations";
import { Container, Row, Col, Collapse, Button } from "reactstrap";
import StationObserver from "../observer/StationObserver";
import { IPlaylist, IHistoryInfo, IHistoryInfoList } from "../../../api/endpoints/stations";
import './StationHistory.css'

const imgStyle: React.CSSProperties = {
    float: "left"
}

export default class StationHistory extends React.Component<{ station?: Station },
    { stationPlaylistInfo?: IPlaylist, historyInfo?: IHistoryInfoList, historyCollapsed: boolean }> {

    constructor(props) {
        super(props);
        this.state = {
            historyCollapsed: true
        }
    }

    public render() {
        if (!this.props.station) {
            return <Loader />
        } else {
            return (
                <StationObserver station={this.props.station} onLoaded={(info) => this.setState({ stationPlaylistInfo: info })} onHistoryLoaded={(info) => { this.setState({ historyInfo: info }) }}>
                    <Container className="station-history">
                        <Row>
                            <Col xs="auto">
                                <img src={(this.state.stationPlaylistInfo && this.state.stationPlaylistInfo.cover) ? this.state.stationPlaylistInfo.cover : this.props.station.default_cover_url} />
                            </Col>
                            <Col className="station" style={{ margin: 'auto' }}>
                                <h2>{this.props.station.name}</h2>
                                <p>
                                    <b>Current Song: </b> {this.state.stationPlaylistInfo ? this.state.stationPlaylistInfo.title : ' ...'}
                                </p>
                                <p>
                                    <b>By: </b> {this.state.stationPlaylistInfo ? this.state.stationPlaylistInfo.artist : ' ...'}
                                </p>
                            </Col>
                            <Col xs="auto">
                                <Button onClick={(e) => this.setState({ historyCollapsed: !this.state.historyCollapsed })} >{this.state.historyCollapsed ? 'Show History' : 'Hide History'}</Button>
                            </Col>
                        </Row>
                        <Collapse isOpen={!this.state.historyCollapsed}>
                            <Row>
                                {this.state.historyInfo && this.state.historyInfo.map((info, index) => {
                                    return (
                                        <Col lg="4" md="6" key={info.title + index}>
                                            <img style={imgStyle} src={info.cover || this.props.station!.default_cover_url} />
                                            <div className="stations">
                                                <p><b>{info.title}</b></p>
                                                <p>{info.description}</p>
                                            </div>
                                        </Col>
                                    )
                                })}

                            </Row>
                        </Collapse>
                    </Container>
                </StationObserver>

            );
        }
    }
}