import { Session } from './api/request/session';
import * as React from 'react';
import 'devextreme/dist/css/dx.light.compact.css';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import Login from './pages/Login/Login';
import registerServiceWorker from './registerServiceWorker';
import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom'
import Dashboard from './pages/Dashboard/Dashboard';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import {createRoot} from "react-dom/client";

library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faCalendar);

// Session.setAPIURL('http://localhost:8082')
Session.setStore(localStorage);
Session.load();

const Logout: React.FC = () => {
	Session.setSession(undefined);
	return (
		<Login/>
	)
}

const RenderDashboard: React.FC = () => {
	return Session.getSession() == undefined ? <Navigate replace to="/login" /> : <Dashboard/>;
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
	<BrowserRouter>
		<Routes>
			<Route path='/login' element={<Login/>} />
			<Route path='/logout' element={<Logout/>}/>
			<Route path='/*' element={<RenderDashboard/>} />
		</Routes>
	</BrowserRouter>
)

registerServiceWorker();

declare global {
	interface Array<T> {
		unique(property?: string): Array<T>;
	}
}

Array.prototype.unique = function <T>(this: Array<T>, property?: string) {
	let s = new Set();
	return this.filter((value, index, self) => {
		if (property && s.has(value[property])) {
			return false;
		} else if (property) {
			s.add(value[property])
			return true;
		}
		if (s.has(value)) {
			return false;
		}
		s.add(value);
		return true;
	})
}
