import * as React from "react";
import { Highlight } from "../../api/models/highlights";
import { getHighlightById } from './HighlightsHelper';
import HighlightDetailItem from "./HighlightDetailItem";
import ParentItem from './parents/HighlightsDetail';
import {withRouter} from "../../utils/withRouter";

class HighlightsDetail extends ParentItem<Highlight> {

    getHighlightById(id): Promise<Highlight> {
        return new Promise<Highlight>((resolve, reject) => {
            getHighlightById(id, (error, response) => {
                if (!error && response) {
                    resolve(response);
                }
                else if (reject) reject(error);
            });
        });
    }

    renderHighlightDetailItem(history, highlight: Highlight) {
        return <HighlightDetailItem history={history} highlight={highlight} />;
    }

}
export default withRouter(HighlightsDetail);