const local = {
    API: 'http://localhost:8082'
};
const dev = {
    API: 'https://dashboard-api-develop.dash-api.com'
};
const prod = {
    API: 'https://dashboard-api.dash-api.com'
};
const config = process.env.REACT_APP_STAGE === 'local' ? local :
    process.env.REACT_APP_STAGE === 'production'
        ? prod
        : dev;
export let Environment = config