import * as React from "react";
import { IHighlightsListResponse, API as HighlightsApi } from "../../api/endpoints/highlights";
import { Highlight, HighlightSort, HighlightTempSort } from "../../api/models/highlights";
import { getAllHighlights, filterHighlights, getActiveHighlights, getInactiveHighlights, isHighlightActive } from './HighlightsHelper';
import HighlightItem from "../../components/stations/section/HighlightItem";
import ParentItem from './parents/Highlights'
import {withRouter} from "../../utils/withRouter";

class Highlights extends ParentItem<Highlight> {

    getAllHighlights(callback) {
        return getAllHighlights(callback);
    }

    isHighlightActive(highlight: Highlight) {
        return isHighlightActive(highlight);
    }

    getActiveHighlights(highlights: Highlight[]) {
        return getActiveHighlights(highlights)
    }
    getInactiveHighlights(highlights: Highlight[]) {
        return getInactiveHighlights(highlights);
    }

    getHighlightTempSort() {
        return HighlightTempSort;
    }

    filterHighlights(highlights: Highlight[], query) {
        return filterHighlights({highlights: highlights || []}, query);
    }
    apiUpdateHighlight(highlight: Highlight) {
        return HighlightsApi.updateHighlight(highlight);
    }

    getCurrentUrlPath() {
        return "/highlights"
    }
    
    renderHighlightItem(highlight: Highlight, active: boolean, searchOptions: ()=>string, moveItem, canMove, didCommitDrop): JSX.Element {
        return (
            <HighlightItem
                searchOptions={searchOptions}
                item={highlight}
                key={highlight.id}
                inactive={!active}
                isSameItem={(highlight1, highlight2)=>{
                    return highlight1.id===highlight2.id;
                }}
                moveItem={moveItem.bind(this)}
                canDrop={canMove.bind(this)}
                didCommitDrop={didCommitDrop.bind(this)} />
        )
    }

}
export default withRouter(Highlights);