import { IGenresListResponse, API } from "../../api/endpoints/genres";
import { Genre, GenreSortPriority, GenreTempSort } from "../../api/models/genres";

export function getAllGenres(callback: (error: any, response?: IGenresListResponse | undefined)=>void, limit?: number) {
    API.listGenres().then(response => {
        if(limit && response && response.genres) {
            response = { genres: response.genres.slice(-limit)};
        }
        prepareGenres(response);

        if(callback) {
            callback(undefined, response);
        }
    }).catch(err => {
        if(callback && err && err.message) {
            callback(err.message)
        }
    })
}

export function getGenreById(genreId: number, callback: (error: any, response?: Genre | undefined)=>void) {
    API.getGenreById(genreId).then(response => {
        if(callback) {
            callback(undefined, response.genre);
        }
    }).catch(err => {
        if(callback && err && err.message) {
            callback(err.message)
        }
    })
}

/*/TODO get all station connected with genre
export function getAllHighlightsFromStation(stationId: number, callback: (error: any, response?: IHighlightsListResponse | undefined)=>void, limit?: number) {
    API.getHighlightsFromStation(stationId).then(response => {
        if(limit && response && response.highlights) {
            response = { highlights: response.highlights.slice(-limit)};
        }
        prepareHighlights(response);

        if(callback) {
            callback(undefined, response);
        }
    }).catch(err => {
        if(callback && err && err.message) {
            callback(err.message)
        }
    })
}
//*/

export function getActiveGenres(genres?: Genre[]) {
    return genres && genres
            .filter(genre => {
                return isGenreActive(genre);
            })
            .sort(GenreSortPriority) || [];
}
export function getInactiveGenres(genres?: Genre[]) {
    return genres && genres
            .filter(genre => {
                return !isGenreActive(genre);
            })
            .sort(GenreSortPriority) || [];
}
export function isGenreActive(genre: Genre): boolean {
    return true;    //TODO if not connected to any stations
}

function prepareGenres(response: IGenresListResponse) {
    if(response && response.genres) {
        response.genres.sort(GenreSortPriority).reverse().forEach((genre, index) => {
            if (genre.temp_sort == undefined) {
                genre.temp_sort = (index + 1) * 10
            }
        })
    }
}

function filterGenre(genre: Genre, text: string): boolean {
    return Object.keys(genre).find(key => {
        return genre[key] && genre[key].toString().toLowerCase().indexOf(text) != -1 || false
    }) != undefined
}

export function filterGenres(genres: IGenresListResponse | undefined, text: string): IGenresListResponse {
    if (text && text.length>0 && genres && genres.genres) {
        text = text.trim();
        if (text.length>0) {
            text = text.toLowerCase()
            return {
                genres: genres!.genres.filter(s => filterGenre(s, text)).sort(GenreSortPriority)
            }
        }
    }
    return genres!;
}