import ParentItem from '../../Highlights/parents/HighlightDetailItem'
import { createNewContestModel, Contest, propertyList, ImageType } from "../../../api/models/contest";
import { API } from "../../../api/endpoints/contests";
import { getUrlParamsFromSearchOptions, addUrlParam } from 'src/utils/urlUtils';
import {withRouter} from "../../../utils/withRouter";

class ContestDetailItem extends ParentItem<Contest, ImageType> {

    private getStationId() {
        var stationId = 0;
        try {
            let props = this.getProps();
            stationId = props.match.params.stationId;
        }
        catch(err){
            //
        }
        return stationId;
    }

    createNewHighlightModel(): any {
        return createNewContestModel(this.getStationId());
    }
    apiUpdateImage(id, file): Promise<Contest> {
        return API.updateImage(this.getStationId(), id, file);
    }
    apiUpdateHighlight(contest: Contest): Promise<Contest> {
        return API.updateContest(contest);
    }
    apiCreateHighlight(contest: Contest): Promise<Contest> {
        return API.createContest(contest);
    }

    getPropertyList() {
        return propertyList();
    }

    protected getImageInfos() {
        return {
            name: 'image_url',
            size: '400 x 400',
            description: 'Contest Logo'
        }
    }

    getEditPagePath(contest: Contest | undefined) {
        return contest && '/stations/'+contest.station_id+'/contest/'+contest.id
    }

    getGoBackFallbackPath() {
        let urlParams = getUrlParamsFromSearchOptions(this.getSearchOptionsFromUrl());
        urlParams += addUrlParam(urlParams, "scroll", this.getScrollId());
        return '/stations/'+this.getStationId()+urlParams;
    }

    protected getScrollId(contest?: Contest) {
        if(contest && contest.id) {
            return "contest"+contest.id;
        }
        return "contests";
    }

}
export default withRouter(ContestDetailItem);