import * as React from "react";
import './Navigation.css'
import logo from '../../img/dashboard_logo.svg'
import { Nav, Navbar, NavItem, NavbarBrand, NavbarToggler, Collapse, Container, NavLink } from "reactstrap";
import {Link} from "react-router-dom";
import {Location} from "react-router";
import {withRouter} from "../../utils/withRouter";

class Navigation extends React.Component<{router: {location: Location}}, { isOpen: boolean }> {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    private toggle = (() => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }).bind(this)

    public render() {
        const path = this.props.router.location.pathname;
        return (
            <Navbar dark container="md" expand="md">
                    <NavbarBrand>
                        <img alt="dashboard logo" src={logo} />
                    </NavbarBrand>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav navbar className="me-auto">
                            <NavItem active={path.startsWith('/stations')}>
                                <NavLink tag={Link} to="/stations">Stations</NavLink>
                            </NavItem>
                            <NavItem active={path.startsWith('/genres')}>
                                <NavLink tag={Link} to="/genres">Genres</NavLink>
                            </NavItem>
                            <NavItem active={path.startsWith('/highlights')}>
                                <NavLink tag={Link} to="/highlights">Highlights App</NavLink>
                            </NavItem>
                            <NavItem active={path.startsWith('/highlight-banners')}>
                                <NavLink tag={Link} to="/highlight-banners">Highlight Banners</NavLink>
                            </NavItem>
                            <NavItem active={path.startsWith('/admin')}>
                                <NavLink tag={Link} to="/admin" >Admin</NavLink>
                            </NavItem>
                        </Nav>
                        <Nav navbar className="ms-auto">
                            <NavItem active={path.startsWith('/logout')}>
                                <NavLink tag={Link} to="/logout">Logout</NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
            </Navbar>
        )
    }

}
export default withRouter(Navigation);
