import { AuthenticatedRequest, HTTPMethod, APIVersion, AuthenticatedFileRequest, APIResponse } from "../request/request";
import { Station } from "../models/stations";
import { HighlightSchedule } from "../models/highlight_schedule";

export class API {

    public static createHighlightSchedule(station: Station, data: Partial<HighlightSchedule>, file): Promise<HighlightSchedule> {
        let apiRequest = new AuthenticatedFileRequest<Station>('/highlight-schedules/' + station.id, HTTPMethod.POST, APIVersion.v1_0, undefined, {
            schedule: data,
            image: file
        });
        return apiRequest.send().then((response) => {
            return <HighlightSchedule>response.data;
        });
    }

    public static updateHighlightSchedule(station: Station, data: HighlightSchedule, file?): Promise<HighlightSchedule> {
        let apiRequest = new AuthenticatedFileRequest<Station>('/highlight-schedules/' + station.id + '/' + data.id, HTTPMethod.PUT, APIVersion.v1_0, undefined, {
            schedule: data,
            image: file
        });
        return apiRequest.send().then((response) => {
            return <HighlightSchedule>response.data;
        });
    }

    public static deleteHighlightSchedule(station: Station, id: number): Promise<void> {
        let apiRequest = new AuthenticatedRequest<Station>('/highlight-schedules/' + station.id + '/' + id, HTTPMethod.DELETE, APIVersion.v1_0);
        return apiRequest.send().then((response) => {
            return void 0
        });
    }

    public static getHighlightSchedules(station: Station): Promise<HighlightSchedule[]> {
        let apiRequest = new AuthenticatedRequest<Station>('/highlight-schedules/' + station.id, HTTPMethod.GET, APIVersion.v1_0);
        return apiRequest.send().then((response) => {
            return <HighlightSchedule[]>response.data;
        });
    }

}