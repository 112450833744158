import { IPropertyList } from "./property";

export interface NewContestItem {
    station_id: number;
    image_url: string;
    description: string;
    share_text: string;
    location_longitude: string;
    location_latitude: string;
    max_mile_distance: string;
    start_date: Date;
    end_date: Date;
}
export interface Contest extends NewContestItem {
    id: number;     //from api
}

export function createNewContestModel(stationId: number): NewContestItem {
    return {
        station_id: stationId,
        image_url: "",
        description: "",
        share_text: "",
        location_latitude: "0",
        location_longitude: "0",
        max_mile_distance: "0",
        start_date: new Date(),
        end_date: new Date()
    }
}

export type ImageType = 'image_url';

export function ContestSortDate(item1: Contest, item2: Contest) {
    return new Date(item2.start_date).valueOf() - new Date(item1.start_date).valueOf()
}

export function propertyList(): IPropertyList {
    return {
        properties: [
            { name: 'id', readOnly: true, type: 'Number' },
            { name: 'station_id', readOnly: true, type: 'String' },
            { name: 'image_url', type: 'URL', hide: true },
            { name: 'description', type: 'Text' },
            { name: 'share_text', type: 'Text' },
            { name: 'location_longitude', type: 'String', hide: true },
            { name: 'location_latitude', type: 'String', hide: true },
            { name: 'location', type: 'Location'},
            { name: 'max_mile_distance', type: 'String' },
            { name: 'start_date', type: 'Date' },
            { name: 'end_date', type: 'Date' }
            //{ name: 'enabled', type: 'Boolean' }
        ]
    }
}