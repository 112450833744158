import * as React from "react";
import { Authenticated } from "../../components/authenticated/Authenticated";
import { } from "devextreme-react";
import { API } from "../../api/endpoints/user";
import { User } from "../../api/models";
import Navigation from "../../components/navigation/Navigation";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Stations from "../Stations/Stations";
import StationsCreate from "../Stations/StationCreateDetailItem";
import StationDetail from "../Stations/StationDetail";
import Highlights from "../Highlights/Highlights";
import HighlightsDetailCreate from "../Highlights/HighlightDetailItem";
import HighlightsDetail from "../Highlights/HighlightsDetail";
import HighlightBanners from "../HighlightBanners/HighlightBanners";
import HighlightBannersDetailCreate from "../HighlightBanners/HighlightBannerDetailItem";
import HighlightBannersDetail from "../HighlightBanners/HighlightBannersDetail";
import ContestEntries from "../Stations/contests/ContestEntries";
import ContestEdit from "../Stations/contests/ContestEdit";
import ContestCreate from "../Stations/contests/ContestDetailItem";
import AdminSection from "../Admin/AdminSection";
import GenreOverview from "../Genres/Genres";
import GenreCreate from "../Genres/GenreDetailItem";
import GenreEdit from "../Genres/GenreDetail"


export default class Dashboard extends React.Component<{}, { user?: User }> {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    public componentDidMount() {
        API.getMe().then(user => {
            this.setState({
                user: user
            })
        })
    }


    public render() {
        return (
            <Authenticated>
                    <Navigation/>
                    <Routes>
                        <Route path='/stations' element={<Stations/>}/>
                        <Route path='/stations/create' element={<StationsCreate/>} />
                        <Route path='/stations/:stationId' element={<StationDetail/>} />
                        <Route path='/stations/:stationId/contest/create' element={<ContestCreate/>} />
                        <Route path='/stations/:stationId/contest/:contestId/entries' element={<ContestEntries/>} />
                        <Route path='/stations/:stationId/contest/:contestId' element={<ContestEdit/>} />
                        <Route path='/genres' element={<GenreOverview/>} />
                        <Route path='/genres/create' element={<GenreCreate/>} />
                        <Route path='/genres/:genreId' element={<GenreEdit/>} />
                        <Route path='/highlights' element={<Highlights/>} />
                        <Route path='/highlights/create' element={<HighlightsDetailCreate/>} />
                        <Route path='/highlights/:highlightId' element={<HighlightsDetail/>} />
                        <Route path='/highlight-banners' element={<HighlightBanners/>} />
                        <Route path='/highlight-banners/create' element={<HighlightBannersDetailCreate/>} />
                        <Route path='/highlight-banners/:highlightId' element={<HighlightBannersDetail/>} />
                        <Route path='/admin' element={<AdminSection/>} />
                        <Route path='/*' element={<Stations/>}/>
                    </Routes>
            </Authenticated>
        )
    }
}

