import * as React from "react";
import { HighlightBanner } from "../../../api/models/highlight_banners";
import './StationItem.css'
import {
    DragDropItemProps, DragDropItemBase,
    dragSourceSpec, dragSourceCollector, dragTargetSpec, dragTargetCollector
} from './DragDropItem';
import { DropTarget, DragSource } from 'react-dnd';
import { API } from '../../../api/endpoints/stations'
import { formatDate } from "../../../utils/dates";
import GenericItem from "./GenericItem";

export interface HighlightItemProps extends DragDropItemProps<HighlightBanner> {
    inactive?: boolean,
    refKey?: string
}

@DropTarget('HighlightBannerItem', dragTargetSpec, dragTargetCollector)
@DragSource('HighlightBannerItem', dragSourceSpec, dragSourceCollector)
export default class HighlightBannerItem extends DragDropItemBase<HighlightBanner, HighlightItemProps, {title: string}> {

    constructor(props) {
        super(props);
        this.state = {
            title: ""
        }
    }

    componentDidMount() {
        if(this.props.item && this.props.item.station_id) {
            API.getStation(this.props.item.station_id as any).then(station=>{
                if(station && station.name) {
                    this.setState({
                        title: station.name
                    })
                }
            })
            .catch(e=>{
                //not necessary to show an error message
                //notify("Couldn't load station for highlight banner.");
            })
        }
    }

    protected renderDragDropView(): JSX.Element {
        const highlight: HighlightBanner = this.props.item;
        let detailLink = "/highlight-banners/"+highlight.id;
        return (
            <GenericItem refDragDropView={this.refDragDropView}
                scrollKey={"banner"+highlight.id}
                imageUrl={highlight.image_url}
                detailPath={detailLink}
                title={this.state.title}
                subtitle={"start: "+formatDate(highlight.start_date)}
                description={"end: "+formatDate(highlight.end_date)}
                refKey={this.props.refKey}
                searchOptions={this.props.searchOptions}
                inactive={this.props.inactive} />
        )
    }
}