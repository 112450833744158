import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateBox } from "devextreme-react";
import * as React from "react";
import { Container, Label, Row, Col, Button } from "reactstrap";
import { API } from "src/api/endpoints/stations";
import { Station } from "src/api/models/stations";
import notify from "src/ui/notify";

export class AssociateStream extends React.Component<{ station: Station }, { value: Date, stream?: string }> {
    constructor(p) {
        super(p);
        this.state = {
            value: new Date(),
            stream: this.props.station.stream_url ? this.props.station.stream_url!.split('/').pop()!.split('.')[0] : ''
        }
    }
    render() {
        return <div style={{ width: '100%', backgroundColor: 'white' }} className='property-container'>
            <Col>
                <h3>Dashlytics Stream</h3>
            </Col>
            <Col>
                <div className="input-wrapper" style={{ display: 'flex', alignItems: 'center' }}>
                    <Label for={'input-date'}>Sine</Label>
                    <div style={{ marginLeft: 20 }}>
                        <DateBox
                            onValueChanged={(e) => {
                                this.setState({ value: e.value })
                            }}
                            value={this.state.value}
                            dropDownButtonRender={() => <FontAwesomeIcon icon="calendar" />}
                            pickerType='calendar'
                            type='datetime' />
                    </div>
                    <div style={{ marginLeft: 20 }}>
                        <Label for={'input-date'}>Stream</Label>
                    </div>
                    <div style={{ marginLeft: 20 }}>
                        <input
                            onChange={(e) => { this.setState({ stream: e.target.value }) }}
                            type="text"
                            value={this.state.stream} />
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                        <Button
                            onClick={(e) => {
                                API.updateDashlyticsStream(this.state.stream!, this.props.station.id, this.state.value).then(() => {
                                    notify('Set!')
                                })
                            }}
                            className='btn-save'>Set</Button>
                    </div>
                </div>
            </Col>
        </div>
    }
}