import * as React from "react";
import { HighlightBanner } from "../../api/models/highlight_banners";
import { getHighlightBannerById } from './HighlightBannersHelper';
import HighlightBannerDetailItem from "./HighlightBannerDetailItem";
import ParentItem from '../Highlights/parents/HighlightsDetail';
import {withRouter} from "../../utils/withRouter";

class HighlightsDetail extends ParentItem<HighlightBanner> {

    getHighlightById(id): Promise<HighlightBanner> {
        return new Promise<HighlightBanner>((resolve, reject)=> {
            getHighlightBannerById(id, (error, response)=>{
                if(!error && response) {
                    resolve(response);
                }
                else if(reject) reject(error);
            });
        });
    }
    
    renderHighlightDetailItem(history, highlight: HighlightBanner) {
        return <HighlightBannerDetailItem history={history} highlight={highlight} />;
    }

}
export default withRouter(HighlightsDetail);