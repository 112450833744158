import * as React from "react";
import StationSection from "../../components/stations/section/StationSection";
import { IStationListResponse, API } from "../../api/endpoints/stations";
import { Container, Row, Col } from "reactstrap";
import { TextBox } from "devextreme-react";
import notify from "../../ui/notify";
import Loader from "../../components/loader/Loader";
import { Station, StationSort } from "../../api/models/stations";
import { getAllStations, filterStations } from './StationsHelper';
import './Stations.css'
import { User } from "src/api/models";
import { API as UserApi } from "src/api/endpoints/user";
import { getScrollHintFromUrl } from "src/utils/urlUtils";
import { isAdmin } from "src/api/models/user";

const topSpacing: React.CSSProperties = {
    paddingTop: '50px'
}

export default class Stations extends React.Component<{},
    {   //state vars
        stations?: IStationListResponse,
        filteredStations?: IStationListResponse,
        searchValue?: string,
        currentUser?: User
    }> {

    constructor(props) {
        super(props);
        this.state = {
            searchValue: ''//localStorage['dash_board_last_search']
        }
    }

    private mScrollToId?: string = undefined;
    public componentDidMount() {
        this.mScrollToId = getScrollHintFromUrl(this.props);

        UserApi.getMe().then(user => {
            this.setState({ currentUser: user })
        }).catch(err => { });

        getAllStations((error, response) => {
            if (error) {
                notify(error, 'error');
            }

            this.setState({
                stations: response || { all: [], inactive: [], active: [] }
            }, () => {
                if (localStorage['dash_board_last_search']) {
                    this.setState({
                        filteredStations: filterStations(this.state.stations, localStorage['dash_board_last_search'])
                    })
                }
            })
        });
    }

    componentDidUpdate() {
        if (this.mScrollToId) {
            window.requestAnimationFrame(() => {
                var element = document.getElementById(this.mScrollToId!);
                if (element) {
                    element.scrollIntoView({ block: 'center', behavior: ('instant' as any) });
                    window.history.replaceState(this.state, window.document.title, "/stations");
                }
            })
        }
    }

    public render() {
        if (!this.state.stations) {
            return <Loader />
        }
        return (
            <Container style={topSpacing}  className="dash-row">
                {
                    this.renderCreateStationButton()
                }
                <Row>
                    <Col>
                        <TextBox placeholder="Search by Genre, Name, Song, ..." value={this.state.searchValue} onChange={(e) => {
                            this.setState({
                                searchValue: e.component!.instance().option('value')
                            })
                        }} onEnterKey={(e) => {
                            this.setState({
                                filteredStations: filterStations(this.state.stations, e.component!.instance().option('value')!),
                                searchValue: e.component!.instance().option('value')
                            })
                        }} />
                    </Col>
                </Row>
                <Row hidden>
                    <Col>
                        Filter<a href="#" className="button page-scroll">Featured</a>
                        Sort by<a href="#" className="button page-scroll">Genre</a>
                        <a href="#" className="button page-scroll">ABC</a>
                    </Col>
                </Row>
                <Row>
                    {
                        !this.getDataSource() &&
                        <Col>
                            <p>No stations found.</p>
                        </Col>
                    }
                    {
                        this.getDataSource() &&
                        <StationSection title="Stations" currentUser={this.state.currentUser}
                            dataSource={this.getDataSource()}
                            isSearchData={this.isSearching()} />
                    }
                </Row>
            </Container>
        )
    }

    renderCreateStationButton() {
        return (
            <Row className="translucent" id="stations-create">
                <Col className="title-container station-item" style={{ paddingLeft: 0 }}>
                    {
                        isAdmin(this.state.currentUser) &&
                        <a href={"/stations/create"} style={{ padding: 10, marginBottom: 20 }} className="buttonstations page-scroll">Add Station</a>
                    }
                </Col>
            </Row>
        )
    }

    private isSearching(): boolean {
        return this.state.searchValue && this.state.searchValue.length > 0 || false;
    }

    private getDataSource() {
        return this.state.filteredStations ? this.state.filteredStations.all : this.state.stations!.all;
    }
}