import * as React from "react";
import { Contest } from "../../../api/models/contest";
import '../../../components/stations/section/StationItem.css'
import {
    DragDropItemProps, DragDropItemBase,
    dragSourceSpec, dragSourceCollector, dragTargetSpec, dragTargetCollector
} from '../../../components/stations/section/DragDropItem';
import { formatDate } from "../../../utils/dates";
import { addUrlParamBase } from "src/utils/urlUtils";
import GenericItem from "src/components/stations/section/GenericItem";
import {DragSource, DropTarget} from "react-dnd";

export interface ContestItemProps extends DragDropItemProps<Contest> {
    inactive?: boolean
}

@DropTarget('ContestItem', dragTargetSpec, dragTargetCollector)
@DragSource('ContestItem', dragSourceSpec, dragSourceCollector)
export default class ContestItem extends DragDropItemBase<Contest, ContestItemProps, {title: String}> {

    constructor(props) {
        super(props);
        this.state = {
            title: ""
        }
    }

    protected renderDragDropView(): JSX.Element {
        const contest: Contest = this.props.item;
        let detailPath = "/stations/"+contest.station_id+"/contest/"+contest.id;

        //entries button
        let searchOptions = this.getSearchOptions();
        var urlParams = "?ref=station"+contest.station_id;
        if(searchOptions && searchOptions.length>0) {
            urlParams += addUrlParamBase(urlParams) + searchOptions.substr(1);
        }
        let entriesPath = "/stations/"+contest.station_id+"/contest/"+contest.id+"/entries"+urlParams;

        return (
            <GenericItem refDragDropView={this.refDragDropView}
                scrollKey={"contest"+contest.id}
                imageUrl={contest.image_url}
                detailPath={detailPath}
                title={contest.description && contest.description.substr(0, 25) || "Contest "+contest.id}
                subtitle={"start: "+formatDate(contest.start_date)}
                description={"end: "+formatDate(contest.end_date)}
                refKey={"station"+contest.station_id}
                searchOptions={this.props.searchOptions}
                inactive={this.props.inactive}>
                <a href={entriesPath} className="buttonstations page-scroll">Entries</a>
            </GenericItem>
        )
    }
}