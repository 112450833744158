import { IPropertyList } from "./property";

export interface NewStationTagItem {
    station_id: number;
    tag: string;
}
export interface StationTag extends NewStationTagItem {
    id: number;     //from api
}

export function createNewStationTagModel(station_id: number, tag: string): NewStationTagItem {
    return {
        station_id: station_id,
        tag: tag
    }
}

export function propertyList(): IPropertyList {
    return {
        properties: [
            { name: 'id', readOnly: true, type: 'Number' },
            { name: 'station_id', readOnly: true, type: 'Number' },
            { name: 'tag', type: 'String' }
        ]
    }
}