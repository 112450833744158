import * as React from "react";
import { IGenresListResponse, API as GenresApi } from "../../api/endpoints/genres";
import { Genre, GenreSortPriority, GenreTempSort } from "../../api/models/genres";
import { getAllGenres, filterGenres, getActiveGenres, getInactiveGenres, isGenreActive } from './GenresHelper';
import GenreItem from "./GenreItem";
import ParentItem from '../Highlights/parents/Highlights'
import { Row, Col } from "reactstrap";
import { isAdmin } from "src/api/models/user";
import {withRouter} from "../../utils/withRouter";

class Genres extends ParentItem<Genre> {

    getAllHighlights(callback) {
        return getAllGenres((err, response) => {
            callback(err, {highlights: response && response.genres || []})
        });
    }

    getPlaceholderText() {
        return "No genres found."
    }
    getSectionTitleText() {
        return "Genres"
    }

    isHighlightActive(genre: Genre) {
        return isGenreActive(genre);
    }

    getActiveHighlights(genres: Genre[]) {
        return getActiveGenres(genres)
    }
    getInactiveHighlights(genres: Genre[]) {
        return getInactiveGenres(genres);
    }

    getHighlightTempSort() {
        return GenreTempSort;
    }

    filterHighlights(genres: Genre[], query) {
        return filterGenres({genres: genres || []}, query);
    }
    apiUpdateHighlight(genre: Genre) {
        return GenresApi.updateGenre(genre);
    }
    
    getCurrentUrlPath() {
        return "/genres"
    }

    renderHighlightItem(genre: Genre, active: boolean, searchOptions: ()=>string, moveItem, canMove, didCommitDrop): JSX.Element {
        return (
            <GenreItem
                //refKey={"genres"}
                searchOptions={searchOptions}
                item={genre}
                key={genre.id}
                inactive={!active}
                isSameItem={(highlight1, highlight2)=>{
                    return highlight1.id===highlight2.id;
                }}
                moveItem={moveItem.bind(this)}
                canDrop={canMove.bind(this)}
                didCommitDrop={didCommitDrop.bind(this)} />
        )
    }

    renderCreateHighlightButton() {
        return (
            <Row className="translucent">
                <Col className="title-container station-item" style={{paddingLeft: 0}}>
                    {
                        isAdmin(this.state.currentUser) &&
                        <a href={"/genres/create"} style={{ padding: 10, marginBottom: 20 }} className="buttonstations page-scroll">Add Genre</a>
                    }
                </Col>
            </Row>
        )
    }
}
export default withRouter(Genres);