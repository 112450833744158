import { BaseModel } from "./base"
export enum AuthenticationProvider {
    Email = "email",
}
export enum UserRole {
    USER = 'User',
    ADMIN = 'Admin'
}
export interface User extends BaseModel {
    email: string;
    full_name: string;
    profile_image_url?: string;
    session_token: string | undefined;
    profile_image_thumbnail_url?: string;
    role: UserRole;
}

export function isAdmin(user: User | undefined){
    return user && user.role===UserRole.ADMIN;
}