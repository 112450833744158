import ParentItem from './parents/HighlightDetailItem'
import { createNewHighlightModel, Highlight, propertyList, ImageType } from "../../api/models/highlights";
import { API } from "../../api/endpoints/highlights";
import { getUrlParamsFromSearchOptions, addUrlParam } from 'src/utils/urlUtils';
import {withRouter} from "../../utils/withRouter";

class HighlightDetailItem extends ParentItem<Highlight, ImageType> {

    createNewHighlightModel(): any {
        return createNewHighlightModel(this.parseStationRef(this.getRefFromUrl()));
    }
    apiUpdateImage(id, file): Promise<Highlight> {
        return API.updateImage(id, file);
    }
    apiUpdateHighlight(highlight: Highlight): Promise<Highlight> {
        return API.updateHighlight(highlight);
    }
    apiCreateHighlight(highlight: Highlight): Promise<Highlight> {
        return API.createHighlight(highlight);
    }

    getPropertyList() {
        return propertyList();
    }

    protected getImageInfos() {
        return {
            name: 'image_url',
            size: '520 x 320',
            description: 'Highlight Logo'
        }
    }

    getEditPagePath(highlight: Highlight | undefined) {
        return highlight && '/highlights/'+highlight.id
    }

    getGoBackFallbackPath() {
        let urlParams = getUrlParamsFromSearchOptions(this.getSearchOptionsFromUrl());
        urlParams += addUrlParam(urlParams, "scroll", this.getScrollId(this.state && this.state.highlight));
        return '/highlights'+urlParams;
    }

    protected getScrollId(highlight?: Highlight) {
        if(highlight && highlight.id) {
            return "highlight"+highlight.id;
        }
        return "highlights";
    }

}
export default withRouter(HighlightDetailItem);