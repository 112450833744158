import * as React from "react";
import ParentItem, { Properties } from './HighlightsSectionGeneric'
import { Highlight } from "../../api/models/highlights";
import { getActiveHighlights, getInactiveHighlights } from '../Highlights/HighlightsHelper';
import HighlightItem from "../../components/stations/section/HighlightItem";

interface Props extends Properties {
    highlights: Highlight[], 
    refKey?: string, 
    searchOptions?: ()=>string
}
export default class HighlightsSection extends ParentItem<Highlight, Props> {

    getCollapsiblesTitle() {
        return "Highlights"
    }
    getActiveHighlights() {
        return getActiveHighlights(this.props.highlights);
    }
    getInactiveHighlights() {
        return getInactiveHighlights(this.props.highlights);
    }
    renderHighlightsItem(highlight: Highlight, key: string, active: boolean) {
        return (
            <HighlightItem
                searchOptions={this.props.searchOptions}
                inactive={!active}
                item={highlight}
                refKey={this.props.refKey}
                key={key}
                isSameItem={(highlight1, highlight2)=>{
                    return highlight1.id===highlight2.id;
                }}
                moveItem={()=>{}}
                canDrop={()=>false}
                didCommitDrop={()=>{}} />
        )
    }

}