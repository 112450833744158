import * as React from "react";
import { Container, Row, Col } from "reactstrap";
import { LoadPanel, TextBox, DataGrid } from "devextreme-react";
import notify from "../../ui/notify";
import Loader from "../../components/loader/Loader";
import './AdminSection.css';
import Button from "reactstrap/lib/Button";
import DevExpress from "devextreme/bundles/dx.all";
import * as $ from 'jquery';
import { API } from "../../api/endpoints/user";
import { User } from "../../api/models";
import { UserRole } from "../../api/models/user";
import {createRef, RefObject} from "react";

export default class UsersGrid extends React.Component<
{
    //properties
    currentUser: User
},
{
    //state vars
    users?: User[],
    loading: boolean
}> {

    private readonly dataGrid: RefObject<DataGrid> = createRef();

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            loading: false
        }
    }

    public componentDidMount() {
        API.getAll().then(users => {
            this.setState({
                users: users
            })
        })
        .catch(err => {
            //
        })
    }

    public addUser() {
        this.dataGrid.current?.instance.addRow();
    }

    public render() {
        if (this.state.loading) {
            return <Loader />
        }
        return (
            <DataGrid
                ref={this.dataGrid}
                height={350}
                dataSource={this.state.users && this.state.users.slice() || []}
                onEditorPreparing={e=>{
                    //make email field readonly while editing
                    e.editorOptions!.readOnly = ((e.dataField == 'email' || e.dataField == 'role') && e.value);
                }}
                onCellPrepared={e=>{
                    var editable = false;
                    var deletable = true;

                    //check if user is current user
                    if(this.props.currentUser && e.data && e.data.email===this.props.currentUser.email) {
                        editable = true;        //show edit button for current user
                        deletable = false;      //cannot delete current user
                    }

                    //remove edit button
                    if(e.rowType === "data" && (e.column as any).command === "edit") {
                        var $links = $(e.cellElement!).find(".dx-link");
                        if(!editable) {
                            $links.filter(".dx-link-edit").remove();
                        }
                        if(!deletable) {
                            $links.filter(".dx-link-delete").remove();
                        }
                    }
                }}
                editing={{
                    mode: "form",
                    allowUpdating: true,
                    allowDeleting: true,
                    allowAdding: false
                }}
                sorting={{mode: 'none'}}
                keyExpr="id"
                columns={[
                    {
                        caption: "User name",
                        dataField: 'full_name',
                        dataType: 'string',
                        validationRules: [{ type: "required" }]
                    },
                    {
                        caption: "Email address",
                        dataField: 'email',
                        dataType: 'string',
                        validationRules: [{ type: "required" }, { type: "email" }]
                    },
                    {
                        caption: "Role",
                        dataField: 'role',
                        lookup: {
                            allowClearing: false,
                            dataSource: [
                                {type: UserRole.ADMIN, title: "Admin"},
                                {type: UserRole.USER, title: "User"}
                            ],
                            valueExpr: "type",
                            displayExpr: "title"
                        },
                        validationRules: [{ type: "required" }]
                    }
                ]}
                onRowInserting={e=>{
                    if(!this.checkEmail(e.data)) {
                        e.cancel = true;

                        //show error message
                        notify("This email is already linked with another account", "error");
                    }
                }}
                onRowInserted={e=>{
                    //console.log("inserted: "+JSON.stringify(e.data));

                    this.setState({loading: true});
                    API.generateUser(e.data!.full_name, e.data!.email, e.data!.role).then((user)=>{
                        this.setState({loading: false, users: [...this.state.users!, user]});
                        //console.log("api changed: "+JSON.stringify(user));
                    })
                    .catch(err=>{
                        this.setState({loading: false});
                        //console.log("api error: "+JSON.stringify(err));
                    })
                }}
                onRowRemoved={e=>{
                    //console.log("removed: "+JSON.stringify(e.data));

                    this.setState({loading: true});
                    API.deleteUser(e.key).then(()=>{
                        this.setState({loading: false, users: this.state.users!.filter(userInList=>userInList.id!==e.key)});
                    })
                    .catch(err=>{
                        this.setState({loading: false});
                        //console.log("api error: "+JSON.stringify(err));
                    })
                }}
                onRowUpdated={e=>{
                    //console.log("updated: "+JSON.stringify(e.data)+" on id: "+e.key);

                    this.setState({loading: true});
                    API.updateUser(e.key, e.data).then((user)=>{
                        this.setState({loading: false, users: this.state.users!.map(userInList=>{
                            if(userInList.id===user.id) {
                                return user;
                            }
                            return userInList;
                        })});
                        //console.log("api changed: "+JSON.stringify(user));
                    })
                    .catch(err=>{
                        this.setState({loading: false});
                        //console.log("api error: "+JSON.stringify(err));
                    })
                }}
                />
        )
    }

    private checkEmail(data) {
        if(this.state.users && data && data.email) {
            if(this.state.users.filter(user=>user.email===data.email).length == 0) {
                return true;
            }
        }
        return false;
    }
}
