import * as React from "react";
import ParentItem, { Properties } from '../HighlightsSectionGeneric'
import { Contest } from "../../../api/models/contest";
import { getActiveContests, getInactiveContests } from './ContestsHelper';
import ContestItem from "./ContestItem";

interface Props extends Properties {
    contests: Contest[], 
    searchOptions?: ()=>string
}
export default class ContestsSection extends ParentItem<Contest, Props> {

    getCollapsiblesTitle() {
        return "Contests"
    }
    getActiveHighlights() {
        return getActiveContests(this.props.contests);
    }
    getInactiveHighlights() {
        return getInactiveContests(this.props.contests);
    }
    renderHighlightsItem(contest: Contest, key: string, active: boolean) {
        return (
            <ContestItem
                searchOptions={this.props.searchOptions}
                inactive={!active}
                item={contest}
                key={key}
                isSameItem={(highlight1, highlight2)=>{
                    return highlight1.id===highlight2.id;
                }}
                moveItem={()=>{}}
                canDrop={()=>false}
                didCommitDrop={()=>{}} />
        )
    }

}