import { User } from "../models";
import { AuthenticatedRequest, HTTPMethod, APIVersion } from "../request/request";

export class API {
    static _me: User;
    public static getMe(): Promise<User> {
        if (API._me) {
            return Promise.resolve(API._me)
        }
        let apiRequest = new AuthenticatedRequest<User>('/users/me', HTTPMethod.GET, APIVersion.v1_0);
        return apiRequest.send().then((data) => {
            API._me = data.data;
            return <User>data.data
        })
    }


    public static getAll(): Promise<User[]> {
        let apiRequest = new AuthenticatedRequest<User>('/users/all', HTTPMethod.GET, APIVersion.v1_0);
        return apiRequest.send().then((data) => {
            return <User[]>data.data
        })
    }

    public static generateUser(name, email, role): Promise<User> {
        let payload = {
            registration_data: {
                email: email,
                role: role
            },
            registration_fullname: name
        }
        let apiRequest = new AuthenticatedRequest<User>('/users/generate', HTTPMethod.POST, APIVersion.v1_0, undefined, payload);
        return apiRequest.send().then((data) => {
            return <User>data.data
        })
    }
    public static updateUser(id, payload): Promise<User> {
        let apiRequest = new AuthenticatedRequest<User>('/user/'+id, HTTPMethod.POST, APIVersion.v1_0, undefined, payload);
        return apiRequest.send().then((data) => {
            return <User>data.data
        })
    }
    public static deleteUser(id): Promise<Boolean> {
        let apiRequest = new AuthenticatedRequest<any>('/user/'+id, HTTPMethod.DELETE, APIVersion.v1_0);
        return apiRequest.send().then(() => {
            return true;
        })
    }
}
