import * as React from "react"
import notify from "../../../ui/notify"
import Loader from "../../../components/loader/Loader"
import { History } from 'history'
import { API as UserApi } from "src/api/endpoints/user";
import { User } from "src/api/models";
import { Navigate } from "react-router-dom";
import { withRouter } from "src/utils/withRouter";

export default abstract class ContestDetailGeneric<Contest> extends React.Component<
    {
        router: { params: { stationId: number, contestId: number }, navigate: Function },
    },
    {
        contest?: Contest,
        currentUser?: User,
        isLoading: boolean
    }> {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        }
    }

    protected getCurrentStationIdFromUrl() {
        return this.props.router.params.stationId;
    }
    protected getCurrentContestIdFromUrl() {
        return this.props.router.params.contestId;
    }

    abstract getContestById(stationId, contestId): Promise<Contest>;
    abstract renderContestDetailItem(navigate: Function, contest: Contest): JSX.Element;

    componentDidMount() {
        UserApi.getMe().then(user => {
            this.setState({ currentUser: user });
        }).catch(err => { });

        this.getContestById(this.getCurrentStationIdFromUrl(), this.getCurrentContestIdFromUrl()).then(contest => {
            this.setState({
                contest: contest,
                isLoading: false
            });
        }).catch(err => {
            if(err && err.message) {
                notify(err.message, 'error', 5000);
            }
            this.setState({
                isLoading: false
            });
        });
    }

    public render() {
        if (this.state.isLoading) {
            return <Loader />
        }
        else if(this.state.contest) {
            return this.renderContestDetailItem(this.props.router.navigate, this.state.contest);
        } else return (
            <Navigate to={"/stations/"+this.getCurrentStationIdFromUrl()} />
        )
    }
}