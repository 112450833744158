import { AuthenticatedRequest, HTTPMethod, APIVersion, AuthenticatedFileRequest, APIResponse } from "../request/request";
import { HighlightBanner, NewHighlightBanner } from "../models/highlight_banners";

export interface IHighlightBannersListResponse {
    highlights: HighlightBanner[]
}
export interface IHighlightBannerResponse {
    highlight: HighlightBanner
}

export class API {

    public static listHighlightBanners(): Promise<IHighlightBannersListResponse> {
        let apiRequest = new AuthenticatedRequest<IHighlightBannersListResponse>('/highlight-banners', HTTPMethod.GET, APIVersion.v1_0);
        return apiRequest.send().then((data) => {
            return data.data
        })
    }

    public static getHighlightBannerById(id: number): Promise<IHighlightBannerResponse> {
        let apiRequest = new AuthenticatedRequest<IHighlightBannerResponse>('/highlight-banners/'+id, HTTPMethod.GET, APIVersion.v1_0);
        return apiRequest.send().then((data) => {
            return data.data
        })
    }

    public static getHighlightBannersFromStation(id: number): Promise<IHighlightBannersListResponse> {
        let apiRequest = new AuthenticatedRequest<IHighlightBannersListResponse>('/stations/'+id+'/highlight-banners/', HTTPMethod.GET, APIVersion.v1_0);
        return apiRequest.send().then((data) => {
            return data.data
        })
    }

    public static updateImage(id: number, image: any): Promise<HighlightBanner> {
        let apiRequest = new AuthenticatedFileRequest<HighlightBanner>('/highlight-banners/'+id+'/image', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            image: image
        });
        return apiRequest.send().then((response) => {
            return <HighlightBanner>response.data;
        });
    }

    public static updateHighlightBanner(highlight: HighlightBanner): Promise<HighlightBanner> {
        let apiRequest = new AuthenticatedRequest<HighlightBanner>('/highlight-banners/' + highlight.id, HTTPMethod.PUT, APIVersion.v1_0, undefined, {
            highlight: highlight
        });
        return apiRequest.send().then((response) => {
            return <HighlightBanner>response.data;
        });
    }

    public static createHighlightBanner(highlight: NewHighlightBanner): Promise<HighlightBanner> {
        let apiRequest = new AuthenticatedRequest<NewHighlightBanner>('/highlight-banners', HTTPMethod.POST, APIVersion.v1_0, undefined, highlight);
        return apiRequest.send().then((response) => {
            return <HighlightBanner>response.data;
        });
    }

}