import { IHighlightBannersListResponse, API } from "../../api/endpoints/highlight_banners";
import { HighlightBanner, HighlightBannerSort, HighlightBannerSortDate } from "../../api/models/highlight_banners";

export function getAllHighlightBanners(callback: (error: any, response?: IHighlightBannersListResponse | undefined)=>void, limit?: number) {
    API.listHighlightBanners().then(response => {
        if(limit && response && response.highlights) {
            response = { highlights: response.highlights.slice(-limit)};
        }
        prepareHighlightBanners(response);

        if(callback) {
            callback(undefined, response);
        }
    }).catch(err => {
        if(callback && err && err.message) {
            callback(err.message)
        }
    })
}

export function getHighlightBannerById(highlightId: number, callback: (error: any, response?: HighlightBanner | undefined)=>void) {
    API.getHighlightBannerById(highlightId).then(response => {
        if(callback) {
            callback(undefined, response.highlight);
        }
    }).catch(err => {
        if(callback && err && err.message) {
            callback(err.message)
        }
    })
}

export function getAllHighlightBannersFromStation(stationId: number, callback: (error: any, response?: IHighlightBannersListResponse | undefined)=>void, limit?: number) {
    API.getHighlightBannersFromStation(stationId).then(response => {
        if(limit && response && response.highlights) {
            response = { highlights: response.highlights.slice(-limit)};
        }
        prepareHighlightBanners(response);

        if(callback) {
            callback(undefined, response);
        }
    }).catch(err => {
        if(callback && err && err.message) {
            callback(err.message)
        }
    })
}

export function getActiveHighlightBanners(highlights?: HighlightBanner[]) {
    return highlights && highlights
            .filter(highlight => {
                return isHighlightBannerActive(highlight);
            })
            .sort(HighlightBannerSortDate) || [];
}
export function getInactiveHighlightBanners(highlights?: HighlightBanner[]) {
    return highlights && highlights
            .filter(highlight => {
                return !isHighlightBannerActive(highlight);
            })
            .sort(HighlightBannerSortDate) || [];
}
export function isHighlightBannerActive(highlight: HighlightBanner): boolean {
    return highlight && highlight.end_date && new Date(highlight.end_date).valueOf()>=Date.now() || false;
}

function prepareHighlightBanners(response: IHighlightBannersListResponse) {
    if(response && response.highlights) {
        response.highlights.sort(HighlightBannerSort).reverse().forEach((highlight, index) => {
            if (highlight.temp_sort == undefined) {
                highlight.temp_sort = (index + 1) * 10
            }
        })
    }
}

function filterHighlightBanner(highlight: HighlightBanner, text: string): boolean {
    return Object.keys(highlight).find(key => {
        return highlight[key] && highlight[key].toString().toLowerCase().indexOf(text) != -1 || false
    }) != undefined
}

export function filterHighlightBanners(highlights: IHighlightBannersListResponse | undefined, text: string): IHighlightBannersListResponse {
    if (text && text.length>0 && highlights && highlights.highlights) {
        text = text.trim();
        if (text.length>0) {
            text = text.toLowerCase()
            return {
                highlights: highlights!.highlights.filter(s => filterHighlightBanner(s, text)).sort(HighlightBannerSortDate)
            }
        }
    }
    return highlights!;
}