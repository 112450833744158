import { AuthenticatedRequest, HTTPMethod, APIVersion, AuthenticatedFileRequest, APIResponse } from "../request/request";
import { ContestEntry } from "../models/contest_entries";

export interface IContestEntriesListResponse {
    entries: ContestEntry[]
}
export interface IContestEntryResponse {
    contest: ContestEntry
}

export class API {

    public static listContestEntries(stationId: number, contestId: number): Promise<IContestEntriesListResponse> {
        let apiRequest = new AuthenticatedRequest<IContestEntriesListResponse>('/stations/'+stationId+'/contest/'+contestId+'/entries', HTTPMethod.GET, APIVersion.v1_0);
        return apiRequest.send().then((data) => {
            return data.data
        })
    }

}