import * as React from "react";
import { Station } from "../../../api/models/stations";
import StationObserver from "../observer/StationObserver";
import { IPlaylist } from "../../../api/endpoints/stations";
import './StationItem.css'
import {
    DragDropItemProps, DragDropItemBase,
    dragSourceSpec, dragSourceCollector, dragTargetSpec, dragTargetCollector
} from './DragDropItem';
import { DropTarget, DragSource } from 'react-dnd';
import GenericItem from "./GenericItem";

@DropTarget('StationItem', dragTargetSpec, dragTargetCollector)
@DragSource('StationItem', dragSourceSpec, dragSourceCollector)
export default class StationItem extends DragDropItemBase<Station, DragDropItemProps<Station>, {info?: IPlaylist}> {

    constructor(props) {
        super(props);
        this.state = {
            //
        }
    }

    private addToInfo(info: IPlaylist) {
        this.setState({
            info: info
        })
    }

    private getInfo() {
        return this.state.info;
    }

    protected renderDragDropView(): JSX.Element {
        const station: Station = this.props.item;
        return (
            <StationObserver station={station}
                onLoaded={(info) => this.addToInfo(info)} key={station.id}>
                <GenericItem 
                    scrollKey={"station"+station.id}
                    refDragDropView={this.refDragDropView}
                    imageUrl={station.colored_light_logo_url}
                    detailPath={"/stations/" + station.id}
                    title={station.name}
                    subtitle={station.genre}
                    description={this.getInfo() ? this.getInfo()!.title + ' by ' + this.getInfo()!.artist : '...'}
                    inactive={!(station.enabled)} />
            </StationObserver>
        )
    }
}