import ParentItem from '../Highlights/parents/HighlightDetailItem'
import { createNewStationModel, Station, imageInfos, propertyList, ImageType } from "../../api/models/stations";
import { API } from "../../api/endpoints/stations";
import { getUrlParamsFromSearchOptions, addUrlParam } from 'src/utils/urlUtils';
import {withRouter} from "../../utils/withRouter";

class StationCreateDetailItem extends ParentItem<Station, ImageType> {

    createNewHighlightModel(): any {
        return createNewStationModel();
    }
    apiUpdateImage(id, file, to): Promise<Station> {
        return API.updateImage(id, to, file);
    }
    apiUpdateHighlight(station: Station): Promise<Station> {
        return API.updateStation(station);
    }
    apiCreateHighlight(station: Station): Promise<Station> {
        return API.createStation(station);
    }

    getPropertyList() {
        return propertyList();
    }

    protected getImageInfosArray() {
        return imageInfos;
    }

    getEditPagePath(station: Station | undefined) {
        return station && '/stations/'+station.id
    }

    getGoBackFallbackPath() {
        return '/stations'
    }

    protected getScrollId(station?: Station) {
        if(station && station.id) {
            return "station"+station.id;
        }
        return "stations-create";
    }

}
export default withRouter(StationCreateDetailItem);