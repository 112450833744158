import { IPropertyList } from "./property";

export interface NewHighlightBanner {
    station_id: string;
    image_url: string;
    priority: number;
    start_date: Date;
    end_date: Date;
}
export interface HighlightBanner extends NewHighlightBanner {
    id: number;     //from api

    temp_sort: number;  //local
}

export function createNewHighlightModel(stationId?): NewHighlightBanner {
    return {
        station_id: stationId || "",
        image_url: "",
        priority: -1,
        start_date: new Date(),
        end_date: new Date()
    }
}

export type ImageType = 'image_url';

export function HighlightBannerSort(item1: HighlightBanner, item2: HighlightBanner) {
    return item2.priority - item1.priority
}

export function HighlightBannerTempSort(item1: HighlightBanner, item2: HighlightBanner) {
    return item2.temp_sort - item1.temp_sort
}

export function HighlightBannerSortDate(item1: HighlightBanner, item2: HighlightBanner) {
    return new Date(item2.start_date).valueOf() - new Date(item1.start_date).valueOf()
}

export function propertyList(): IPropertyList {
    return {
        properties: [
            { name: 'id', readOnly: true, type: 'Number' },
            { name: 'station_id', type: 'StationId' },
            { name: 'image_url', type: 'URL', hide: true },
            { name: 'priority', type: 'Number' },
            { name: 'start_date', type: 'Date' },
            { name: 'end_date', type: 'Date' }
        ]
    }
}