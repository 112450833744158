import { IPropertyList } from "./property";

export type ImageType = 'square_logo_url' | 'small_logo_url' | 'medium_logo_url' | 'large_logo_url' | 'colored_light_logo_url' | 'default_cover_url' | 'logo_mask_url';

export const imageInfos: Array<{ name: ImageType, size: string; description: string, no_tint?: boolean, class?: string }> = [
    {
        name: 'square_logo_url',
        size: '120 x 120',
        description: 'Square Logo'
    },
    {
        name: 'small_logo_url',
        size: '88 x 66',
        description: 'Small Logo'
    },
    {
        name: 'medium_logo_url',
        size: '176 x 132',
        description: 'Medium Logo'
    },
    {
        name: 'large_logo_url',
        size: '264 x 198',
        description: 'Large Logo'
    },
    {
        name: 'colored_light_logo_url',
        size: '324 x 324',
        description: 'Colored Light Logo',
        no_tint: true
    },
    {
        name: 'default_cover_url',
        size: '600 x 600',
        description: 'Default Cover',
        no_tint: true
    },
    {
        name: 'logo_mask_url',
        size: '',
        description: 'Logo Mask',
        no_tint: true,
        class: 'large'
    },
]

export interface NewStation {
    name: string;
    genre: string;
    genre_priority: number;
    stream_url: string;
    clean_stream_url?: string;
    current_song_url: string;
    history_feed_url: string;
    square_logo_url: string;
    small_logo_url: string;
    medium_logo_url: string;
    large_logo_url: string;
    colored_light_logo_url: string;
    default_cover_url: string;
    logo_mask_url: string;
    description: string;
    description_es: string;
    description_attributed: string;
    prevent_song_cover: boolean;
    slug: string;
    short_name: string;
    phone_number: string;
    enabled: boolean;
    web_url: string;
}

export interface Station extends NewStation {
    id: number;

    //local
    current_title?: string;
    current_artist?: string;
    temp_genre_sort: number;
    featured_priority?: number;
}

export function StationSort(item1: Station, item2: Station) {
    return item2.genre_priority - item1.genre_priority
}

export function StationTempSort(item1: Station, item2: Station) {
    return item2.temp_genre_sort - item1.temp_genre_sort
}
export function propertyList(): IPropertyList {
    return {
        properties: [
            { name: 'id', readOnly: true, type: 'Number' },
            { name: 'name', type: 'String' },
            { name: 'genre', type: 'String' },
            { name: 'genre_priority', type: 'Number' },
            { name: 'stream_url', type: 'SongURL' },
            { name: 'clean_stream_url', type: 'SongURL' },
            { name: 'current_song_url', type: 'URL' },
            { name: 'history_feed_url', type: 'URL' },
            { name: 'square_logo_url', type: 'URL', hide: true },
            { name: 'small_logo_url', type: 'URL', hide: true },
            { name: 'medium_logo_url', type: 'URL', hide: true },
            { name: 'large_logo_url', type: 'URL', hide: true },
            { name: 'colored_light_logo_url', type: 'URL', hide: true },
            { name: 'default_cover_url', type: 'URL', hide: true },
            { name: 'logo_mask_url', type: 'URL', hide: true },
            { name: 'description', type: 'Text' },
            { name: 'description_es', type: 'Text' },
            { name: 'description_attributed', type: 'Text' },
            { name: 'prevent_song_cover', type: 'Boolean' },
            { name: 'slug', type: 'String' },
            { name: 'short_name', type: 'String' },
            { name: 'phone_number', type: 'String' },
            { name: 'enabled', type: 'Boolean' },
            { name: "featured_priority", type: 'Number'},
            { name: 'recovery_enabled', type: 'Boolean' },
            { name: 'recovery_stream_url', type: 'SongURL' },
            { name: 'web_url', type: 'URL' },

        ]
    }
}

export function createNewStationModel(): NewStation {
    return {
        name: "",
        genre: "",
        genre_priority: 0,
        stream_url: "",
        current_song_url: "",
        history_feed_url: "",
        square_logo_url: "",
        small_logo_url: "",
        medium_logo_url: "",
        large_logo_url: "",
        colored_light_logo_url: "",
        default_cover_url: "",
        logo_mask_url: "",
        description: "",
        description_es: "",
        description_attributed: "",
        prevent_song_cover: false,
        slug: "",
        short_name: "",
        phone_number: "",
        web_url: "",
        enabled: false,
    }
}
