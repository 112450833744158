import { AuthenticatedRequest, HTTPMethod, APIVersion, AuthenticatedFileRequest, APIResponse } from "../request/request";
import { StationTag, NewStationTagItem } from "../models/station_tags";

export interface IStationTagListResponse {
    tags: StationTag[]
}
export interface IStationTagResponse {
    tag: StationTag
}

export class API {

    public static listTags(station_id: number): Promise<IStationTagListResponse> {
        let apiRequest = new AuthenticatedRequest<IStationTagListResponse>('/stations/'+station_id+"/tags", HTTPMethod.GET, APIVersion.v1_0);
        return apiRequest.send().then((data) => {
            return data.data
        })
    }

    public static getStationTagById(tag_id: number): Promise<IStationTagResponse> {
        let apiRequest = new AuthenticatedRequest<IStationTagResponse>('/stations/tags/'+tag_id, HTTPMethod.GET, APIVersion.v1_0);
        return apiRequest.send().then((data) => {
            return data.data
        })
    }

    public static updateStationTag(tag: StationTag): Promise<StationTag> {
        let apiRequest = new AuthenticatedRequest<StationTag>('/stations/tags/'+tag.id, HTTPMethod.PUT, APIVersion.v1_0, undefined, {
            tag: tag
        });
        return apiRequest.send().then((response) => {
            return <StationTag>response.data;
        });
    }

    public static createStationTag(tag: NewStationTagItem): Promise<StationTag> {
        let apiRequest = new AuthenticatedRequest<NewStationTagItem>('/stations/'+tag.station_id+"/tags", HTTPMethod.POST, APIVersion.v1_0, undefined, {
            tag: tag
        });
        return apiRequest.send().then((response) => {
            return <StationTag>response.data;
        });
    }

    public static deleteStationTagById(tag_id: number): Promise<void> {
        let apiRequest = new AuthenticatedRequest<IStationTagResponse>('/stations/tags/'+tag_id, HTTPMethod.DELETE, APIVersion.v1_0);
        return apiRequest.send().then((data) => {
            return data.data
        })
    }

    public static updateStationTags(station_id: number, toDelete: StationTag[], toAdd: NewStationTagItem[]): Promise<IStationTagListResponse> {
        let apiRequest = new AuthenticatedRequest<IStationTagListResponse>('/stations/'+station_id+'/tags/update', HTTPMethod.POST, APIVersion.v1_0, undefined, {
            toDelete: toDelete.map(item => item.id),
            toAdd: toAdd
        });
        return apiRequest.send().then((response) => {
            return response.data;
        });
    }
}