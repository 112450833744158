import * as React from "react";
import { Genre } from "../../api/models/genres";
import '../../components/stations/section/StationItem.css'
import {
    DragDropItemProps, DragDropItemBase,
    dragSourceSpec, dragSourceCollector, dragTargetSpec, dragTargetCollector
} from '../../components/stations/section/DragDropItem';
import { DropTarget, DragSource } from 'react-dnd';
import GenericItem from "src/components/stations/section/GenericItem";

export interface GenreItemProps extends DragDropItemProps<Genre> {
    inactive?: boolean,
    refKey?: string
}

@DropTarget('GenreItem', dragTargetSpec, dragTargetCollector)
@DragSource('GenreItem', dragSourceSpec, dragSourceCollector)
export default class GenreItem extends DragDropItemBase<Genre, GenreItemProps, {}> {

    constructor(props) {
        super(props);
        this.state = {
            //
        }
    }

    protected renderDragDropView(): JSX.Element {
        const genre: Genre = this.props.item;
        let detailLink = "/genres/"+genre.id;
        return (
            <GenericItem refDragDropView={this.refDragDropView}
                scrollKey={"genre"+genre.id}
                imageUrl={genre.genre_image_url}
                detailPath={detailLink}
                title={genre.genre_name}
                refKey={this.props.refKey}
                searchOptions={this.props.searchOptions}
                inactive={this.props.inactive} />
        )
    }
}