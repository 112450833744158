import { IStationListResponse, API } from "../../api/endpoints/stations";
import { Station, StationSort } from "../../api/models/stations";

export function getAllStations(callback: (error: any, response?: IStationListResponse | undefined) => void) {
    API.listStations().then(response => {
        prepareStations(response);

        if (callback) {
            callback(undefined, response);
        }
    }).catch(err => {
        if (callback && err && err.message) {
            callback(err.message)
        }
    })
}

function prepareStations(response: IStationListResponse) {
    response.all.map(station => {
        return station.genre
    }).unique().forEach(genre => {
        response.all.filter(station => {
            return station.genre == genre
        }).sort(StationSort).reverse().forEach((station, index) => {
            if (station.temp_genre_sort == undefined) {
                station.temp_genre_sort = (index + 1) * 10
            }
        })
    })
}

function filterStation(station: Station, text: string): boolean {
    return Object.keys(station).find(key => {
        if (station[key] == undefined) {
            return false
        }
        return station[key].toString().toLowerCase().indexOf(text) != -1
    }) != undefined
}

export function filterStations(stations: IStationListResponse | undefined, text: string): IStationListResponse {
    if (text != undefined && stations && stations.all && stations.active && stations.inactive) {
        text = text.trim();
        if (text != '') {
            text = text.toLowerCase()
            return {
                inactive: stations!.inactive.filter(s => filterStation(s, text)),
                active: stations!.active.filter(s => filterStation(s, text)),
                all: stations!.all.filter(s => filterStation(s, text))
            }
        }
    }
    return stations!;
}