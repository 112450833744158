import * as React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import "../../../ui/styles.css";
import notify from "../../../ui/notify";
import Loader from "../../../components/loader/Loader";
import TagInputUI from 'react-tagsinput'        //https://github.com/olahol/react-tagsinput
//import 'react-tagsinput/react-tagsinput.css'
import './TagsEditor.css';

import { API } from "../../../api/endpoints/station_tags";
import { StationTag, NewStationTagItem, createNewStationTagModel } from "../../../api/models/station_tags";
import { Station } from "../../../api/models/stations";

export default class TagsEditor extends React.Component<
    {   //props
        station: Station,
        enabled: boolean
    },
    {   //state
        data?: NewStationTagItem[],
        toDelete: StationTag[],
        loading: boolean,
        changed: boolean
    }>
{

    constructor(props) {
        super(props);
        this.state = {
            toDelete: [],
            loading: false,
            changed: false
        }
    }

    componentDidMount() {
        //api call to set current tag data
        API.listTags(this.props.station.id).then(response => {
            let tags = response && response.tags || [];
            this.setState({
                data: tags
            })
        })
        .catch(err => {
            this.setState({
                data: []
            })
        })
    }

    render() {
        if (this.state.data == undefined) {
            return <Loader />
        }
        return (
            <Container fluid>
                <Row>
                    <Col style={{ flex: 1, textAlign: 'start' }}><h3>Station Tags</h3></Col>
                    <Col xs={{ order: 'right' }} className="title-container">
                        {
                            this.state.changed && 
                            <Button
                                style={{marginRight: 15}}
                                disabled={!(this.props.enabled===true)}
                                onClick={(e) => { 
                                    //save changes
                                    let toDelete = this.state.toDelete || [];
                                    let toAdd: NewStationTagItem[] = [];
                                    this.state.data && this.state.data.forEach(item => {
                                        if(item && item.tag && item['id']===undefined) {
                                            toAdd.push(createNewStationTagModel(this.props.station.id, item.tag));
                                        }
                                    })

                                    //console.log("toDelete: "+JSON.stringify(toDelete));
                                    //console.log("toAdd: "+JSON.stringify(toAdd));

                                    this.setState({loading: true});
                                    API.updateStationTags(this.props.station.id, toDelete, toAdd).then(response => {
                                        this.setState({
                                            loading: false, 
                                            toDelete: [],
                                            changed: false,
                                            data: response && response.tags || []
                                        })
                                    })
                                    .catch(err => {
                                        this.setState({loading: false});
                                        if(err && err.message) {
                                            notify(err.message, 'error');
                                        }
                                        else notify("An error occurred. Please try again later.", 'error');
                                        
                                    });
                                }}
                                className='btn-save'>Save</Button>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col className="col-md-auto">
                        <TagInputUI
                            disabled={!this.props.enabled}
                            onlyUnique={true}
                            value={this.state.data}
                            tagDisplayProp={"tag"}
                            onChange={(tags, changedTags, changedIndices) => {
                                //console.log(JSON.stringify(changedTags));

                                //check if user removed a tag already saved in db
                                let toDelete = this.state.toDelete || [];
                                if(changedTags) {
                                    changedTags.forEach(element => {
                                        if(element && element.id) {     //saved in db
                                            toDelete.push(element);
                                        }
                                    });
                                }

                                //set state
                                this.setState({data: tags, toDelete: toDelete, changed: true})
                            }}
                        />
                    </Col>

                </Row>
                {this.state.loading && <Loader title="Updating..." />}
            </Container>
        )
    }

    public getTagList() {
        return this.state.data && this.state.data.slice() || [];
    }
}