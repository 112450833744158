import * as React from "react";
import { DragSource, DragSourceMonitor, ConnectDragSource, DragSourceConnector, DropTarget, DropTargetConnector, DropTargetMonitor, XYCoord, ConnectDropTarget } from 'react-dnd';
import './StationItem.css'
import { findDOMNode } from "react-dom";
import {View} from "devextreme-react/scheduler";

export const imgStyle: React.CSSProperties = {
    float: "left"
}

export interface DragDropItemProps<T> {
    item: T
    isDragging?: boolean
    connectDragSource?: ConnectDragSource
    connectDropTarget?: ConnectDropTarget
    moveItem: (source: T, target: T, direction) => void
    isSameItem: (source: T, target: T) => boolean
    canDrop: (source: T, target: T) => boolean
    didCommitDrop?: (source: T) => void
    searchOptions?: ()=>string
}

export const dragSourceSpec = {
    beginDrag(props: DragDropItemProps<any>, _, component) {
        return {
            item: props.item,
            sourceRect: (findDOMNode(
                component,
            ) as Element).getBoundingClientRect()
        }
    },
    endDrag(props: DragDropItemProps<any>, monitor: DragSourceMonitor) {
        //if(props.canDrop(monitor.getItem().item, props.item)===true) {   
        //}
        const item = monitor.getItem<any>()
        const dropResult = monitor.getDropResult()
        if (dropResult) {
            props.didCommitDrop && props.didCommitDrop(item.item);
        }
    }
}
export const dragSourceCollector = (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
});

export const dragTargetSpec = {
    hover(props: DragDropItemProps<any>, monitor: DropTargetMonitor, component: any | null) {
        if (!component || !this.canDrop(props, monitor)) {

            console.log("first returnoo: " + this.canDrop(props, monitor));
            return null
        }
        const dragItem = (monitor.getItem<any>().item)
        const sourceRect = monitor.getItem<any>().sourceRect;

        const hoverItem = props.item

        // Don't replace items with themselves
        if (props.isSameItem(dragItem, hoverItem)) {
            return null
        }

        // Determine rectangle on screen
        const hoverBoundingRect = (findDOMNode(
            component,
        ) as Element).getBoundingClientRect()

        // Determine mouse position
        const clientOffset = monitor.getClientOffset()

        // Get pixels to the top
        const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left

        let direction = 0;
        if (sourceRect.bottom < hoverBoundingRect.bottom) {
            direction = 1;
        } else if (sourceRect.bottom > hoverBoundingRect.bottom) {
            direction = 0;
        } else {
            if (sourceRect.right > hoverBoundingRect.left) {
                direction = 0
            } else {
                direction = 1;
            }
        }
        const targetWidth = hoverBoundingRect.right - hoverBoundingRect.left;
        if (direction == 1) {
            if (100 / targetWidth * hoverClientX < 50) {
                return null;
            }
        } else {
            if (100 / targetWidth * (targetWidth - hoverClientX) < 50) {
                return null;
            }
        }

        // Time to actually perform the action
        props.moveItem(dragItem, hoverItem, direction)
        return null
    },
    canDrop(props: DragDropItemProps<any>, monitor: DropTargetMonitor) {
        return props.canDrop(monitor.getItem<any>().item, props.item)
    }
}
export const dragTargetCollector = (connect: DropTargetConnector) => ({
    connectDropTarget: connect.dropTarget(),
});


//@DropTarget('DragDropItem', dragTargetSpec, dragTargetCollector)
//@DragSource('DragDropItem', dragSourceSpec, dragSourceCollector)
export abstract class DragDropItemBase<T, P extends DragDropItemProps<T>, S extends {}> extends React.Component<P, S> {

    constructor(props) {
        super(props);
    }

    public render() {
        const { connectDragSource, connectDropTarget, isDragging, item } = this.props
        const opacity = isDragging ? 0.4 : 1

        if(connectDragSource && connectDropTarget) {
            return this.renderDragDropView();
        }

        return <View />;
    }

    protected abstract renderDragDropView(): JSX.Element;

    protected getSearchOptions() {
        return this.props && this.props.searchOptions && this.props.searchOptions();
    }

    protected refDragDropView = (instance) => {
        var domNode = findDOMNode(instance!);
        const { connectDragSource, connectDropTarget } = this.props;
        if(connectDragSource && connectDropTarget) {
            connectDragSource((domNode as any))
            connectDropTarget((domNode as any))
        }
    };
}