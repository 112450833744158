import * as React from "react";
import ParentItem, { Properties } from './HighlightsSectionGeneric'
import { HighlightBanner } from "../../api/models/highlight_banners";
import { getActiveHighlightBanners, getInactiveHighlightBanners } from '../HighlightBanners/HighlightBannersHelper';
import HighlightBannerItem from "../../components/stations/section/HighlightBannerItem";

interface Props extends Properties {
    highlights: HighlightBanner[], 
    refKey?: string, 
    searchOptions?: ()=>string
}
export default class HighlightBannersSection extends ParentItem<HighlightBanner, Props> {

    getCollapsiblesTitle() {
        return "Banners"
    }
    getActiveHighlights() {
        return getActiveHighlightBanners(this.props.highlights);
    }
    getInactiveHighlights() {
        return getInactiveHighlightBanners(this.props.highlights);
    }
    renderHighlightsItem(highlight: HighlightBanner, key: string, active: boolean) {
        return (
            <HighlightBannerItem
                searchOptions={this.props.searchOptions}
                inactive={!active}
                item={highlight}
                refKey={this.props.refKey}
                key={key}
                isSameItem={(highlight1, highlight2)=>{
                    return highlight1.id===highlight2.id;
                }}
                moveItem={()=>{}}
                canDrop={()=>false}
                didCommitDrop={()=>{}} />
        )
    }

}