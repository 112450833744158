import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { Button, TextBox, Form, ValidationGroup, Validator, Toast } from 'devextreme-react'
import './Login.css';
import { API, LoginUserError, LoginUserErrorType } from "../../api/endpoints/login";
import logo from '../../img/dashboard_logo_color.svg'
import notify from "../../ui/notify";
import { Session } from "../../api/request/session";
import Footer from "../../components/footer/Footer";
import {Container} from "reactstrap";
import {Navigate} from "react-router-dom";

export default class Login extends React.Component<{}, { redirectToReferrer: boolean }> {



    private validateAndSubmitFunc = this.validateAndSubmit.bind(this);

    private emailField: TextBox
    private passwordField: TextBox
    private validationGroup: ValidationGroup

    constructor(props) {
        super(props);
        this.state = {
            redirectToReferrer: false
        }
    }

    public componentDidMount() {
        document.title = "DashBoard - Login";
    }
    componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{ redirectToReferrer: boolean }>, snapshot?: any) {
    }

    public render() {
        const { redirectToReferrer } = this.state
        if (redirectToReferrer) {
            return <Navigate replace to='/stations' />
        }
        return (
            <div>
                <section id="login">
                    <Container className="text-center">
                        <Row className="row1">
                            <Col className="col text-center">
                                <img alt="dashboard logo" src={logo} />
                                <div className="space" />
                            </Col>
                        </Row>
                        <Row className="row2">
                            <Col md={4} />
                            <Col md={4}>
                                <ValidationGroup ref={(ref) => this.validationGroup = ref!}>
                                    <div className="form-group">
                                        <TextBox ref={((ref) => this.emailField = ref!)} onEnterKey={this.validateAndSubmitFunc} elementAttr={{ class: 'form-control' }} mode="email" placeholder="E-mail" validationMessageMode="always">
                                            <Validator validationRules={[{ type: 'email', message: 'E-mail is invalid' }, { type: 'required', message: 'E-mail is required' }]} />
                                        </TextBox>
                                    </div>
                                    <br />
                                    <div className="form-group">
                                        <TextBox ref={((ref) => this.passwordField = ref!)} onEnterKey={this.validateAndSubmitFunc} elementAttr={{ class: 'form-control' }} mode="password" placeholder="password" validationMessageMode="always">
                                            <Validator validationRules={[{ type: 'required', message: 'Password is required' }]} />
                                        </TextBox>
                                    </div>
                                    <div className="space" />
                                    <Button onClick={this.validateAndSubmitFunc} elementAttr={{ class: 'button-logout' }} text="SIGN IN" />
                                </ValidationGroup>
                            </Col>
                            <Col md={4} />
                        </Row>
                    </Container>
                </section >
            </div>
        )
    }

    private validateAndSubmit(params) {
        let result = this.validationGroup.instance.validate();
        if (result.isValid) {
            this.login()
        }
    }

    private login() {

        API.login(this.emailField.instance.option('value')!, this.passwordField.instance.option('value')!).then((user) => {
            Session.setSession(user.session_token)
            this.setState(() => ({
                redirectToReferrer: true
            }))
        }).catch((error: LoginUserError) => {
            let text = 'An error occurred';
            switch (error.type) {
                case LoginUserErrorType.invalidLogin:
                    text = (error.underlyingError && error.underlyingError.message) ? error.underlyingError.message : 'The password you entered was invalid';
                    break;
                case LoginUserErrorType.userNotFound:
                    text = 'A user with this login could not be found';
                    break;
                default:
                    break;
            }
            notify(text, 'error', 5000)
        })
    }
}
