import * as React from "react";
import { LoadPanel } from "devextreme-react";
import loader from '../../img/loader.svg'
export default class Loader extends React.Component<{ title?: string }> {

    constructor(props) {
        super(props);
    }

    public render() {
        return (<LoadPanel message={this.props.title} showIndicator={true} visible={true} indicatorSrc={loader} />);
    }
}