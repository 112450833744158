import * as React from 'react';
import "../../../ui/styles.css";
import './CoverArtScheduler.css'
import { API } from "../../../api/endpoints/highlight_schedules";
import { HighlightSchedule } from "../../../api/models/highlight_schedule";
import ShowsScheduler from "./ShowsScheduler";
import { Station } from 'src/api/models/stations';

export class HighlightsScheduler extends ShowsScheduler<HighlightSchedule> {

    constructor(props) {
        super(props);
    }

    protected apiGetSchedule(station) {
        return API.getHighlightSchedules(station);
    }

    protected apiCreateSchedule(station, data, file) {
        return API.createHighlightSchedule(station, data, file);
    }

    protected apiUpdateSchedule(station, data, file) {
        return API.updateHighlightSchedule(station, data, file);
    }

    protected apiDeleteSchedule(station, id) {
        return API.deleteHighlightSchedule(station, id);
    }
}

export default class extends React.Component<{
    renderPopover?: (url, target) => JSX.Element | undefined,
    station: Station,
}> {
    render() {
        let { station, renderPopover } = this.props;
        return (
            <HighlightsScheduler
                title="Highlights Scheduler"
                description="highlight"
                applyFormData={(form, applyItem)=>{
                    applyItem("title", { label: { text: "Title" }, visible: true, validationRules: [{type: 'required', message: ''}] }, 0)
                    applyItem("subtitle", { label: { text: "Subtitle" }, visible: true, validationRules: [{type: 'required', message: ''}] }, 1)
                    applyItem("description", { label: { text: "Description" }, visible: true, editorType: 'dxTextArea', validationRules: [{type: 'required', message: ''}] }, 2)
                }}
                applyEditPopup={(item)=>{
                    let scheduleItem = item as HighlightSchedule;
                    return (
                        <>
                        <p><b>{scheduleItem.title}</b></p>
                        <p>{scheduleItem.subtitle}</p>
                        <p style={{paddingTop: 5, paddingBottom: 5, whiteSpace: 'normal'}}>{scheduleItem.description}</p>
                        </>
                    )
                }}
                applyItemTitle={(item)=>{
                    let scheduleItem = item as HighlightSchedule;
                    return (
                        <>
                        {scheduleItem.title}<br/>{scheduleItem.subtitle}
                        </>
                    )
                }}
                station={station}
                renderPopover={renderPopover} />
        )
    }
}
