import { IContestsListResponse, API } from "../../../api/endpoints/contests";
import { Contest, ContestSortDate } from "../../../api/models/contest";

export function getAllContests(stationId, callback: (error: any, response?: IContestsListResponse | undefined)=>void, limit?: number) {
    API.listContests(stationId).then(response => {
        if(limit && response && response.contests) {
            response = { contests: response.contests.slice(-limit)};
        }

        if(callback) {
            callback(undefined, response);
        }
    }).catch(err => {
        if(callback && err && err.message) {
            callback(err.message)
        }
    })
}

export function getContestById(stationId: number, contestId: number, callback: (error: any, response?: Contest | undefined)=>void) {
    API.getContestById(stationId, contestId).then(response => {
        if(callback) {
            callback(undefined, response.contest);
        }
    }).catch(err => {
        if(callback && err && err.message) {
            callback(err.message)
        }
    })
}

export function getActiveContests(contests?: Contest[]) {
    return contests && contests
            .filter(contest => {
                return isContestActive(contest);
            })
            .sort(ContestSortDate) || [];
}
export function getInactiveContests(contests?: Contest[]) {
    return contests && contests
            .filter(contest => {
                return !isContestActive(contest);
            })
            .sort(ContestSortDate) || [];
}
export function isContestActive(contest: Contest): boolean {
    return contest && contest.end_date && new Date(contest.end_date).valueOf()>=Date.now() || false;
}

function filterContest(contest: Contest, text: string): boolean {
    return Object.keys(contest).find(key => {
        return contest[key] && contest[key].toString().toLowerCase().indexOf(text) != -1 || false
    }) != undefined
}

export function filterContests(contests: IContestsListResponse | undefined, text: string): IContestsListResponse {
    if (text && text.length>0 && contests && contests.contests) {
        text = text.trim();
        if (text.length>0) {
            text = text.toLowerCase()
            return {
                contests: contests!.contests.filter(s => filterContest(s, text)).sort(ContestSortDate)
            }
        }
    }
    return contests!;
}