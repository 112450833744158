import { IHighlightsListResponse, API } from "../../api/endpoints/highlights";
import { Highlight, HighlightSort, HighlightSortDate } from "../../api/models/highlights";

export function getAllHighlights(callback: (error: any, response?: IHighlightsListResponse | undefined)=>void, limit?: number) {
    API.listHighlights().then(response => {
        if(limit && response && response.highlights) {
            response = { highlights: response.highlights.slice(-limit)};
        }
        prepareHighlights(response);

        if(callback) {
            callback(undefined, response);
        }
    }).catch(err => {
        if(callback && err && err.message) {
            callback(err.message)
        }
    })
}

export function getHighlightById(highlightId: number, callback: (error: any, response?: Highlight | undefined)=>void) {
    API.getHighlightById(highlightId).then(response => {
        if(callback) {
            callback(undefined, response.highlight);
        }
    }).catch(err => {
        if(callback && err && err.message) {
            callback(err.message)
        }
    })
}

export function getAllHighlightsFromStation(stationId: number, callback: (error: any, response?: IHighlightsListResponse | undefined)=>void, limit?: number) {
    API.getHighlightsFromStation(stationId).then(response => {
        if(limit && response && response.highlights) {
            response = { highlights: response.highlights.slice(-limit)};
        }
        prepareHighlights(response);

        if(callback) {
            callback(undefined, response);
        }
    }).catch(err => {
        if(callback && err && err.message) {
            callback(err.message)
        }
    })
}

export function getActiveHighlights(highlights?: Highlight[]) {
    return highlights && highlights
            .filter(highlight => {
                return isHighlightActive(highlight);
            })
            .sort(HighlightSortDate) || [];
}
export function getInactiveHighlights(highlights?: Highlight[]) {
    return highlights && highlights
            .filter(highlight => {
                return !isHighlightActive(highlight);
            })
            .sort(HighlightSortDate) || [];
}
export function isHighlightActive(highlight: Highlight): boolean {
    return highlight && highlight.end_date && new Date(highlight.end_date).valueOf()>=Date.now() || false;
}

function prepareHighlights(response: IHighlightsListResponse) {
    if(response && response.highlights) {
        response.highlights.sort(HighlightSort).reverse().forEach((highlight, index) => {
            if (highlight.temp_sort == undefined) {
                highlight.temp_sort = (index + 1) * 10
            }
        })
    }
}

function filterHighlight(highlight: Highlight, text: string): boolean {
    return Object.keys(highlight).find(key => {
        return highlight[key] && highlight[key].toString().toLowerCase().indexOf(text) != -1 || false
    }) != undefined
}

export function filterHighlights(highlights: IHighlightsListResponse | undefined, text: string): IHighlightsListResponse {
    if (text && text.length>0 && highlights && highlights.highlights) {
        text = text.trim();
        if (text.length>0) {
            text = text.toLowerCase()
            return {
                highlights: highlights!.highlights.filter(s => filterHighlight(s, text)).sort(HighlightSortDate)
            }
        }
    }
    return highlights!;
}