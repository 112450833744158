import * as React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import notify from "../../../ui/notify";
import Loader from "../../../components/loader/Loader";
import upload from '../../../img/upload_icon.svg'
import uploader from '../../../img/oval_loader.svg'
import { Popover } from "devextreme-react";
import PropertyContainer from "../../../components/property_container/PropertyContainer";
import "../../Stations/StationDetail.css";
import * as $ from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Station } from "../../../api/models/stations";
import { getAllStations } from "../../Stations/StationsHelper";
import { User } from "src/api/models";
import { API as UserApi } from "src/api/endpoints/user";
import { isAdmin } from "src/api/models/user";
import queryString from "query-string";
import { getUrlParamsFromSearchOptions, addUrlParamBase } from '../../../utils/urlUtils'
import {Navigate, NavigateFunction} from "react-router-dom";

const hideStyle: React.CSSProperties = {
    display: 'none'
}

const PopoverEvent = 'mouseenter'
const PopoverLeaverEvent = 'mouseleave'

export default abstract class HighlightDetailItemParent<T, ImageType> extends React.Component<
{   //props
    highlight? : T,
    router: {navigate: NavigateFunction},
    children?: React.ReactNode
},
{   //state
    imageLoadingStates: { [key: string]: boolean },
    imageUploadQueue: { [key: string]: {newImage: any, oldImage: any} },
    highlight?: T,
    isNewHighlight: boolean,
    isLoading: boolean,
    stations?: Station[],
    currentUser?: User,
    redirectToEditPage: boolean
}> {

    constructor(props) {
        super(props);
        var highlight: T = props.highlight || undefined;
        this.state = {
            imageLoadingStates: {},
            imageUploadQueue: {},
            highlight: highlight || this.createNewHighlightModel(),
            isNewHighlight: !this.isHighlighValid(highlight),
            isLoading: false,
            redirectToEditPage: false
        }
    }

    protected getProps(): any {
        return this.props as any;
    }

    protected getRefFromUrl(): string | undefined {
        let props = this.props as any;
        if(props && props.location && props.location.search) {
            let queryParams = queryString.parse(props.location.search);
            if(queryParams && queryParams.ref) {
                return queryParams.ref as string | undefined;
            }
        }
        return undefined;
    }
    protected getSearchOptionsFromUrl(): Object | undefined {
        let props = this.props as any;
        if(props && props.location && props.location.search) {
            let queryParams = queryString.parse(props.location.search);
            if(queryParams) {
                return queryParams;
            }
        }
        return undefined;
    }
    protected parseStationRef(ref: string | undefined): string | undefined {
        if(ref && ref.startsWith('station')) {
            return ref.substr('station'.length);
        }
        return undefined;
    }

    abstract createNewHighlightModel(): T;
    abstract apiUpdateImage(id, file, to?): Promise<T>;
    abstract apiUpdateHighlight(highlight: T): Promise<T>;
    abstract apiCreateHighlight(highlight: T): Promise<T>;
    abstract getPropertyList();
    abstract getEditPagePath(highlight: T | undefined): string | undefined;
    abstract getGoBackFallbackPath(): string | undefined;

    protected getScrollId(highlight: T): string | undefined {
        return undefined;
    }

    private isHighlighValid(highlight: any): boolean {
        return highlight && highlight.id>0 || false;
    }

    componentDidMount() {
        UserApi.getMe().then(user=>{
            this.setState({currentUser: user})
        }).catch(err=>{});

        getAllStations((error, response)=>{
            if(!error && response) {
                this.setState({stations: response.all})
            }
        })
    }

    private uploadFile(to: ImageType, fileProxy) {
        fileProxy.preventDefault();
        if(isAdmin(this.state.currentUser)) {
            let file = fileProxy.currentTarget.files || fileProxy.dataTransfer.files;
            if (file.length > 0) {
                file = file[0];
            }
            if (file) {
                var reader = new FileReader();
                const oldImage = this.state.highlight && (this.state.highlight! as any)[to];
                const self = this;
                reader.onload = function (e: any) {
                    (self.state.highlight! as any)[to] = e.target.result;
                    (self.state.imageUploadQueue as any)[to] = {newImage: file, oldImage: oldImage};
                    self.setState({
                        highlight: self.state.highlight!,
                        imageLoadingStates: self.state.imageLoadingStates,
                        imageUploadQueue: self.state.imageUploadQueue
                    })
                }
                reader.readAsDataURL(file)

                //image is not uploaded, just cached for uploading!
            }
        }
    }

    private processImageUploadQueue(callback?: ()=>void) {
        if(isAdmin(this.state.currentUser)) {
            let queue = this.state.imageUploadQueue;
            let keys = Object.keys(queue);
            if(keys && keys.length>0) {
                this.setState({isLoading: true});
                keys.forEach(to => {
                    let queueItem = queue[to];
                    
                    let file = queueItem && queueItem.newImage;
                    let oldImage = queueItem && queueItem.oldImage;
    
                    let finishOp = ()=>{
                        this.setState({isLoading: this.hasImagesToUpload()}, ()=>{
                            if(!this.state.isLoading) {
                                callback && callback()
                            }
                        });
                    }
                    let onSuccess = (self, highlight) => {
                        (self.state.imageLoadingStates as any)[to] = false;
                        delete (self.state.imageUploadQueue as any)[to];

                        this.setState({
                            highlight: highlight,
                            imageLoadingStates: self.state.imageLoadingStates,
                            imageUploadQueue: self.state.imageUploadQueue
                        }, finishOp)
                    }
                    let onError = (self)=>{
                        if(self.state.highlight) {
                            if(oldImage) {
                                (self.state.highlight! as any)[to] = oldImage;    
                            }
                            else delete (self.state.highlight! as any)[to];
                        }
                        (self.state.imageLoadingStates as any)[to] = false;
                        delete (self.state.imageUploadQueue as any)[to];
                        this.setState({
                            highlight: self.state.highlight!,
                            imageLoadingStates: self.state.imageLoadingStates,
                            imageUploadQueue: self.state.imageUploadQueue
                        }, finishOp)
                    }

                    let self = this;
                    if(file) {
                        (self.state.imageLoadingStates as any)[to] = true;
                        this.setState({
                            imageLoadingStates: self.state.imageLoadingStates       //set loading
                        })
    
                        this.apiUpdateImage((self.state.highlight! as any).id, file, to).then((highlight) => {
                            //handle success
                            onSuccess(self, highlight);
                        })
                        .catch(err => {
                            if(err && err.message) {
                                notify(err.message, 'error', 5000)
                            }
    
                            //handle error
                            onError(self);
                        })
                    }
                    else {
                        //handle error
                        onError(self);
                    }
                })
            }
            else callback && callback()
        }
    }

    private hasImagesToUpload(): boolean {
        let queue = this.state.imageUploadQueue;
        return queue && Object.keys(queue).length>0;
    }

    private updateHighlight() {
        if(this.isHighlighValid(this.state.highlight!)) {
            this.setState({
                isLoading: true
            })
            this.apiUpdateHighlight(this.state.highlight!).then((highlight) => {
                this.setState({
                    highlight: highlight,
                    isLoading: false
                }, ()=>{
                    this.processImageUploadQueue(); //upload image changes
                })
            }).catch(err => {
                if(err && err.message) {
                    notify(err.message, 'error', 5000)
                }
                this.setState({
                    isLoading: false
                })
            })
        }
        else this.createNewHighlight();
    }
    private createNewHighlight() {
        this.setState({
            isLoading: true
        })
        this.apiCreateHighlight(this.state.highlight!).then((highlight) => {
            this.setState({
                highlight: highlight,
                isLoading: false
            }, ()=>{    //upload image changes
                this.processImageUploadQueue(()=>{
                    this.setState({redirectToEditPage: true});
                });
            })
        }).catch(err => {
            if(err && err.message) {
                notify(err.message, 'error', 5000)
            }
            this.setState({
                isLoading: false
            })
        })
    }

    private goBack() {
        //go back
        //this.props.history.goBack();

        let ref = this.getRefFromUrl();
        if(ref && ref.startsWith('station')) {
            let fromStationId = ref.substr('station'.length);

            //get search options
            let urlParams = getUrlParamsFromSearchOptions(this.getSearchOptionsFromUrl());
            if(this.state.highlight && this.getScrollId(this.state.highlight)) {
                urlParams += addUrlParamBase(urlParams) + "scroll="+this.getScrollId(this.state.highlight);
            }

            //go back to station
            this.props.router.navigate('/stations/'+fromStationId + urlParams);
            return;
        } else {
            this.props.router.navigate(this.getGoBackFallbackPath() || '/highlights');
        }
    }

    protected getImageInfosArray(): Array<{ name: string, size: string; description: string, no_tint?: boolean, class?: string }> {
        return [
            this.getImageInfos()
        ]
    }
    protected getImageInfos() {
        return {
            name: 'image_url',
            size: '1024x',
            description: 'Highlight Logo'
        }
    }

    public render() {
        if(this.state.redirectToEditPage){
            let path = this.getEditPagePath(this.state.highlight);
            if(path) {
                return <Navigate to={path} />
            }
        }
        return (
            <Container className="station-detail dash-row">
                <Container>
                    <Row style={{ paddingTop: '18px', paddingBottom: '0px', backgroundColor: 'transparent' }}>
                        <Col>
                            <div className="back-btn" onClick={()=>{this.goBack()}}>
                                <FontAwesomeIcon icon="chevron-left" />
                                Back
                            </div>
                        </Col>
                        {
                            /*/TODO paging
                            this.getPagingPosition() && this.getPagingCount() &&        //show paging when ready
                            (
                                <Col xs={{order: 'right'}} className="title-container">
                                    <a href={"/stations/"+this.getPreviousPagingStationId()} className="page-btn">
                                        <FontAwesomeIcon icon="chevron-left" />
                                    </a>
                                    <span>{this.getPagingPosition()}/{this.getPagingCount()}</span>
                                    <a href={"/stations/"+this.getNextPagingStationId()} className="page-btn">
                                        <FontAwesomeIcon icon="chevron-right" />
                                    </a>
                                </Col>
                            )
                            //*/
                        }
                    </Row>
                </Container>
                {this.state.isLoading && <Loader title="Updating..." />}
                <Row className="image-row img_tint">
                    {this.getImageInfosArray().map((info, index) => {
                        const { name, size, description, no_tint } = info
                        return <Col key={index} className="col-upload">
                            <input
                                accept="image/*"
                                id={'in_' + (index + 1)}
                                type="file" style={hideStyle}
                                onChange={(e) => { this.uploadFile(name as any, e) }} />

                            <div
                                className="dotted-wrapper"
                                style={(!(this.state.highlight! as any)[name]) ? { backgroundColor: 'white' } : {}}
                                onDragOver={(e => { e.preventDefault() })}
                                onDrop={(e) => { this.uploadFile(name as any, e) }}>

                                <a href="#" onClick={(e) => {
                                    if(isAdmin(this.state.currentUser)) {
                                        $('#in_' + (index + 1)).click() 
                                    }
                                }}>
                                    <img id={'img_' + (index + 1)} 
                                        className={
                                            ((this.state.imageLoadingStates as any)[name] ? 'img_opacity ' : ' ') +
                                            ((this.state.highlight! as any)[name] && (!no_tint) ? ' img_tint ' : ' ') +
                                            (info.class ? info.class : '')
                                        }
                                        src={(this.state.highlight! as any)[name] ? (this.state.highlight! as any)[name] : upload}
                                        alt="Upload Icon" />
                                    {(this.state.imageLoadingStates as any)[name] && <img src={uploader} className="uploading_img" />}
                                </a>
                            </div>
                            <span>{size} Pixel</span>
                            <p>{description}</p>
                            <Popover target={'#img_' + (index + 1)}
                                showEvent={PopoverEvent}
                                hideEvent={PopoverLeaverEvent}
                                width={600}>
                                <img width="100%"
                                    className="station-detail img_tint"
                                    src={(this.state.highlight! as any)[name] ? (this.state.highlight! as any)[name] : upload}
                                    alt="Upload Icon" />
                            </Popover>
                        </Col>
                    })}
                </Row>
                <Row>
                    <div style={{ 'float': 'right', width: '100%', marginRight: '15px' }}>
                        <Button
                            onClick={(e) => { this.updateHighlight() }}
                            disabled={!isAdmin(this.state.currentUser)}
                            className='btn-save'>Save</Button>

                        {   //add children
                            this.props.children
                        }
                    </div>
                    <PropertyContainer
                        currentUser={this.state.currentUser}
                        stations={this.state.stations}
                        edit={this.getPropertyList()} object={this.state.highlight!} onChange={
                            (value, property) => {
                                this.state.highlight![property.name] = value;
                                this.setState({
                                    highlight: this.state.highlight
                                })
                            }
                        } />
                </Row >
            </Container >
        )
    }


    /*/PAGING

    private getPagingPosition() {
        if(this.state.allStations) {
            return 1 + this.state.allStations!.findIndex(station => {
                if(this.state.station) {
                    return station.id===this.state.station!.id;
                }
                return false;
            });
        }
        return undefined;
    }
    private getPagingCount() {
        if(this.state.allStations) {
            return this.state.allStations!.length;
        }
        return undefined;
    }

    private getCurrentStationIdFromUrl() {
        return this.props.match.params.stationId;
    }

    private getNextPagingStationId() {
        if(this.getPagingPosition() && this.getPagingCount()) {
            var nextPos = this.getPagingPosition()! % this.getPagingCount()!;   //paging position == index +1
            if(this.state.allStations && this.state.allStations.length>nextPos) {
                return this.state.allStations[nextPos].id;
            }
        }
        return this.getCurrentStationIdFromUrl();
    }
    private getPreviousPagingStationId() {
        if(this.getPagingPosition() && this.getPagingCount()) {
            var previousPos = this.getPagingPosition()! - 2;   //paging position == index +1
            if(previousPos<0) {
                previousPos = previousPos + this.getPagingCount()!;
            }
            if(previousPos>=0 && this.state.allStations && this.state.allStations.length>previousPos) {
                return this.state.allStations[previousPos].id;
            }
        }
        return this.getCurrentStationIdFromUrl();
    }

    //*/
}