import { Options, request } from "./native_request";
import { APIErrorType } from "../errors";
import { Session } from "./session";

export type RequestType<T> = Options;
export type APIError = {
    type: APIErrorType
    status: number
    message: string
    resource_type?: string;
    underlying?: Error
    missing_keys?: string[]
    invalid_keys?: string[]
}

export enum HTTPMethod {
    GET = "GET",
    PUT = "PUT",
    DELETE = "DELETE",
    POST = "POST",
}

export enum APIVersion {
    v1_0 = "v1"
}

export type APIResponse<T> = { data?: T | T[], meta?: { [key: string]: any }, pagination?: { [key: string]: any }, originalResponse: Response }

export interface Request<T> {
    url: string;
    path?: string;
    method: HTTPMethod;
    apiVersion: APIVersion;
    queryParameters?: { [key: string]: string };
    bodyParameters?: { [key: string]: any };
    serialize(): RequestType<T>
}
export class AnyRequest<T> implements Request<T> {
    url: string;
    path?: string;
    public method: HTTPMethod;
    apiVersion: APIVersion;
    queryParameters?: { [key: string]: string };
    bodyParameters?: { [key: string]: any };
    refresh_token?: string;
    json: boolean;

    middleware: ((data: any) => any)

    canTriggerUnauthorized = true

    constructor(url: string,
        path: string | undefined,
        method: HTTPMethod,
        apiVersion: APIVersion = APIVersion.v1_0,
        queryParameters?: { [key: string]: any },
        bodyParameters?: { [key: string]: any }, json: boolean = true) {
        this.serialize = this.serialize.bind(this);
        this.url = url;
        this.path = path;
        this.method = method;
        this.apiVersion = apiVersion;
        this.queryParameters = queryParameters;
        this.bodyParameters = bodyParameters;
        this.json = json
    }

    serialize(): RequestType<T> {
        let apiRequest: Options = {
            url: this.url + (this.path ? '/' + this.apiVersion + this.path : ''),
            json: this.json,
            method: this.method,
            headers: {}
        };
        if (this.refresh_token) {
            apiRequest.headers = Object.assign({}, apiRequest.headers, { 'Authorization': 'Bearer ' + this.refresh_token })
        }
        if (this.queryParameters) {
            apiRequest.qs = this.queryParameters
        }
        if (this.bodyParameters) {
            apiRequest.body = this.bodyParameters;
        }
        return apiRequest
    }
    public send(): Promise<APIResponse<any>> {
        let promise = new Promise((resolve, reject: (reason?: any) => void) => {
            request(this.serialize(), (error?: Error, response?: Response, body?: any) => {
                if (error) {
                    reject(error);
                } else if (response && response.status >= 200 && response.status <= 299) {
                    if (this.middleware) {
                        body = this.middleware(body);
                    }
                    if (body && body.data) {
                        return resolve({
                            data: body.data,
                            originalResponse: response
                        })
                    } else if (body && body.error) {
                        switch (body.error.type) {
                            case APIErrorType.SessionNotFoundError:
                            case APIErrorType.MissingAuthorizationError:
                                Session.unauthorizedHandler && this.canTriggerUnauthorized ? Session.unauthorizedHandler(body.error) : {};
                                break;
                            default: break;
                        }
                        reject(body.error)
                    } else {
                        reject({
                            type: APIErrorType.UnknownError,
                            status: 500,
                            message: 'The response could not be parsed'
                        })
                    }
                } else {
                    if (body && body.error) {
                        switch (body.error.type) {
                            case APIErrorType.SessionNotFoundError:
                            case APIErrorType.MissingAuthorizationError:
                                Session.unauthorizedHandler && this.canTriggerUnauthorized ? Session.unauthorizedHandler(body.error) : {};
                                break;
                            default: break;
                        }
                        reject(body.error)
                    } else {
                        reject({
                            type: APIErrorType.UnknownError,
                            status: 500,
                            message: 'The response could not be parsed'
                        })
                    }
                }
            })
        });
        return <Promise<APIResponse<T>>>promise.catch((error) => {
            if (error.type && error.message && error.status) {
                throw error
            } else {
                const error: any = new Error('message');
                error.type = APIErrorType.NetworkError;
                error.status = 999;
                error.message = 'An unknown error occurred';
                error.underlying = error;
                throw error
            }
        });
    }
}
export class BasicRequest<T> extends AnyRequest<T> {

    constructor(path: string,
        method: HTTPMethod,
        apiVersion: APIVersion = APIVersion.v1_0,
        queryParameters?: { [key: string]: any },
        bodyParameters?: { [key: string]: any }) {
        super(Session.getAPIURL(), path, method, apiVersion, queryParameters, bodyParameters);
    }
}

export class AuthenticatedRequest<T> extends BasicRequest<T> {

    private readonly authToken?: string;

    constructor(path: string,
        method: HTTPMethod,
        apiVersion: APIVersion = APIVersion.v1_0,
        queryParameters?: { [key: string]: any },
        bodyParameters?: { [key: string]: any }) {
        super(path, method, apiVersion, queryParameters, bodyParameters);
        this.authToken = Session.getSession();
    }

    serialize(): RequestType<T> {
        let options = super.serialize();
        if (this.authToken && !this.refresh_token) {
            options.headers = Object.assign({}, options.headers, { 'Authorization': 'Bearer ' + this.authToken });
        }
        return options
    }
}

export class AuthenticatedFileRequest<T> extends AuthenticatedRequest<T> {

    constructor(path: string,
        method: HTTPMethod,
        apiVersion: APIVersion = APIVersion.v1_0,
        queryParameters?: { [key: string]: string },
        bodyParameters?: { [key: string]: any }) {
        if (bodyParameters) {
            let body = new FormData();
            let data = bodyParameters;
            for (let key of Object.keys(data)) {
                if (data[key] instanceof File) {
                    body.append(key, data[key], 'image');
                } else {
                    body.append(key, JSON.stringify(data[key]));
                }
            }
            bodyParameters = body;
        }
        super(path, method, apiVersion, queryParameters, bodyParameters);
    }

    serialize(): RequestType<T> {
        let options = super.serialize();
        options.json = false;
        return options
    }
}
